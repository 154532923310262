import React from "react";
import BlogComponent from "../pages/landingPages/Blog";
import TutorialsVideoComponent from "../pages/landingPages/TutorialsVideo";
import TutorialComponent from "../pages/landingPages/Tutorials";
import Terms from "../pages/landingPages/Terms";
import PrivacyComponenet from "../pages/landingPages/Privacy";
import AboutUs from "../pages/landingPages/AboutUs";
import FaqComponent from "../pages/landingPages/FAQ";
import ContactUsComponent from "../pages/landingPages/contactUs";
import PageNotFound from "../error/PageNotFound";
import Home from "../pages/landingPages/Home";
import Dashboard from "../pages/innerPages/Dashboard";
import Solutions from "../pages/landingPages/Solutions";
import InfluencersForm from "../pages/landingPages/InfluencersForm";
import BrandsForm from "../pages/landingPages/BrandsForm";
import CaseStudies from "../pages/landingPages/CaseStudies";
import CaseStudiesInner from "../components/CaseStudiesInnercopy";
import CaseStudiesInnercopymomo from "../components/CaseStudiesInnercopymomo";
import DkavaCaseStudiesInner from "../components/DkavaCaseStudiesInner";
import ElemntCaseStudiesInner from "../components/ElemntCaseStudiesInner";
import SerekoCaseStudiesInner from "../components/SerekoCaseStudiesInner";
import SerekoCaseStudiesInnerPhase2 from "../components/SerekoCaseStudiesInnerPhase2";
import TotemCaseStudiesInner from "../components/TotemCaseStudiesInner";
import KreoCaseStudiesInner from "../components/KreoCaseStudiesInner";
import WellvartCaseStudiesInner from "../components/WellvartCaseStudiesInner";
import NuviblissCaseStudiesInner from "../components/NuviblissCaseStudiesInner";
import AuravedicCaseStudiesInner from "../components/AuravedicCaseStudiesInner";
import CareFreakCaseStudiesInner from "../components/CareFreakCaseStudiesInner";
import DeliziaCaseStudiesInner from "../components/DeliziaCaseStudiesInner";
import GxnCaseStudiesInner from "../components/GxnCaseStudiesInner";
import YellowBowlCaseStudiesInner from "../components/YellowBowlCaseStudiesInner";
import UnCaseStudiesInner from "../components/UnCaseStudiesInner";
import ZinkLondonCaseStudiesInner from "../components/ZinkLondonCaseStudiesInner";
import GreeeCaseStudiesInner from "../components/GreeeCaseStudiesInner";

import CareersPage from "../pages/landingPages/CareersPage";

import BrandsEnquiry from "../components/steper/BrandsEnquiry";
import BrandsFormLeads from "../pages/landingPages/BrandsFormLeads";



const configureRoutes = () => {
  const routes = [
    {
      element: <Home />,
      exact: true,
      path: "/",
      title: "Home page",
      type: "public",
    },
    {
      element: <Dashboard />,
      exact: true,
      path: "/dashboard",
      title: "Dashboard",
      type: "public",
    },{
      element: <BlogComponent />,
      exact: true,
      path: "/blog",
      title: "Blogs page",
      type: "public",
    },
    {
      element: <Solutions />,
      exact: true,
      path: "/solutions",
      title: "Solutions",
      type: "public",
    },
   
    {
      element: <InfluencersForm />,
      exact: true,
      path: "/influencers-onboarding",
      title: "InfluencersForm",
      type: "public",
    },
   
    {
      element: <BrandsForm />,
      exact: true,
      path: "/brands-enquiry",
      title: "BrandsForm",
      type: "public",
    },
    {
      element: <BrandsFormLeads />,
      exact: true,
      path: "/brand-leads",
      title: "BrandsForm",
      type: "public",
    },
    {
      element: <CaseStudies />,
      exact: true,
      path: "/case-studies",
      title: "CaseStudies",
      type: "public",
    },
    {
      element: <CareersPage />,
      exact: true,
      path: "/Careers",
      title: "CaseStudies",
      type: "public",
    },
    {
      element: <CaseStudiesInner />,
      exact: true,
      path: "/case-studies/gopappe/:id",
      title: "CaseStudiesInner",
      type: "public",
    },
    {
      element: <DkavaCaseStudiesInner />,
      exact: true,
      path: "/case-studies/dkava/:id",
      title: "CaseStudiesInner",
      type: "public",
    },
    
    {
      element: <ElemntCaseStudiesInner />,
      exact: true,
      path: "/case-studies/elemnt/:id",
      title: "CaseStudiesInner",
      type: "public",
    },
    
    {
      element: <CaseStudiesInnercopymomo />,
      exact: true,
      path: "/case-studies/momomia/:id",
      title: "CaseStudiesInnercopymomo",
      type: "public",
    },
    {
      element: <SerekoCaseStudiesInner />,
      exact: true,
      path: "/case-studies/sereko/:id",
      title: "SerekoCaseStudiesInner",
      type: "public",
    },
    {
      element: <SerekoCaseStudiesInnerPhase2 />,
      exact: true,
      path: "/case-studies/:id",
      title: "SerekoCaseStudiesInner",
      type: "public",
    },
    
    {
      element: <TotemCaseStudiesInner />,
      exact: true,
      path: "/case-studies/totem/:id",
      title: "SerekoCaseStudiesInner",
      type: "public",
    },
    
    {
      element: <KreoCaseStudiesInner />,
      exact: true,
      path: "/case-studies/kreo/:id",
      title: "KreoCaseStudiesInner",
      type: "public",
    },
   
    
    {
      element: <WellvartCaseStudiesInner />,
      exact: true,
      path: "/case-studies/wellvart/:id",
      title: "WellvartCaseStudiesInner",
      type: "public",
    },
    {
      element: <NuviblissCaseStudiesInner />,
      exact: true,
      path: "/case-studies/nuvibliss/:id",
      title: "NuviblissCaseStudiesInner",
      type: "public",
    },
    {
      element: <AuravedicCaseStudiesInner />,
      exact: true,
      path: "/case-studies/auravedic/:id",
      title: "AuravedicCaseStudiesInner",
      type: "public",
    },
    {
      element: <CareFreakCaseStudiesInner />,
      exact: true,
      path: "/case-studies/care-freak/:id",
      title: "CareFreakCaseStudiesInner",
      type: "public",
    },
   
    {
      element: <DeliziaCaseStudiesInner />,
      exact: true,
      path: "/case-studies/delizia/:id",
      title: "DeliziaCaseStudiesInner",
      type: "public",
    },
   
    {
      element: <GxnCaseStudiesInner />,
      exact: true,
      path: "/case-studies/greenex-nutrition/:id",
      title: "GxnCaseStudiesInner",
      type: "public",
    },
   
    {
      element: <YellowBowlCaseStudiesInner />,
      exact: true,
      path: "/case-studies/yellow-bowl/:id",
      title: "GxnCaseStudiesInner",
      type: "public",
    },
    {
      element: <UnCaseStudiesInner />,
      exact: true,
      path: "/case-studies/unspoiled-skincare/:id",
      title: "GxnCaseStudiesInner",
      type: "public",
    },
    {
      element: <ZinkLondonCaseStudiesInner />,
      exact: true,
      path: "/case-studies/zink-london/:id",
      title: "ZinkLondonCaseStudiesInner",
      type: "public",
    },
    {
      element: <GreeeCaseStudiesInner />,
      exact: true,
      path: "/case-studies/gree-air-conditioner/:id",
      title: "GreeeCaseStudiesInner",
      type: "public",
    },
   
  
    //   element: <TutorialComponent />,
    //   exact: true,
    //   path: "/Videos",
    //   title: "Videos",
    //   type: "public",
    // },

    // {
    //   element: <TutorialsVideoComponent />,
    //   exact: true,
    //   path: "/tutorials/video",
    //   title: "Tutorials video",
    //   type: "public",
    // },
    {
      element: <Terms />,
      exact: true,
      path: "/terms",
      title: "Terms",
      type: "public",
    },
    {
      element: <PrivacyComponenet />,
      exact: true,
      path: "/privacy",
      title: "Privacy",
      type: "public",
    },
    {
      element: <AboutUs />,
      exact: true,
      path: "/aboutus",
      title: "Aboutus",
      type: "public",
    },

    {
      element: <FaqComponent />,
      exact: true,
      path: "/faq",
      title: "Faq",
      type: "public",
    },
    {
      element: <ContactUsComponent />,
      exact: true,
      path: "/contactus",
      title: "Contact us",
      type: "public",
    },
    {
      element: <PageNotFound />,
      exact: true,
      path: "*",
      title: "404 Not Found",
      type: "error",
    },
  ];

  return routes;
};

export default configureRoutes;
