import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  Aani,
  Aashi,
  Akshitarora,
  CAseimages,
  Caseimages,
  Caseresult,
  Chalwahankhaatehai,
  Coverelemnt,
  Covervideoimage,
  Foodieboy,
  Foodjunction,
  Foodsearch,
  Foodtravls,
  Gunjan,
  Hanshika,
  Hazel,
  Innsightimagedkava,
  Innsightimageelemnt,
  Ishita,
  Jessmeet,
  Littleflower,
  Mayank,
  Mnyanka,
  Muskan,
  Playbutton,
  Pr003,
  Prachi,
  Sanyavig,
  Serekocase,
  Shuriti,
  Tastingglitch,
  Tinyshree,
  Vegpic,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const SerekoCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d66",
    title: "Driving Shoppers at Sereko’s Amazon Product Listing Page  ",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "E-COMMERCE",
    content: [
      {
        heading: "Campaign Overview",
        subcontent:
          "Sereko, known for its highly effective skincare products, delivers impressive results over time. The brand operates under the slogan, ‘Beauty Without Compromises’. Sereko has gained a reputation for manufacturing products that offer effective solutions. Utilising the science of psychodermatology, their proprietary formulas are designed to destress minds, leaving skin care buffs with a radiant and healthy glow.",
          content:
          "Sereko focused on implementing e-commerce marketing tactics to enhance its brand awareness. The aim was to reach women who experienced skincare concerns. We developed and executed a full-fledged marketing plan for them. This plan featured diverse objectives and yielded significant results.",
    
          // submorecontent:
          // "The client was looking for a professional organisation who could understand his e-commerce business and help him in strengthening Elemnt’s awareness and sales conversion.",
      },
      {
        heading: "Challenges",
        list1:
        "To build awareness and gain market traction for Sereko.",
      list2:
        "To figure out the preferences of Sereko’s target audience and build trust among them.",
      list3:
        "To improve Sereko’s Amazon Search rankings.",
      },
      {
        heading: "Solution",
        subcontent:
          "A skincare brand reversing the effects of stress, aimed to build its awareness through right messaging, brand positioning and strategies that would appeal to Sereko’s customer base.",
        content:
          "Since Sereko contacted the team at Marqeting, we focused on addressing common skincare issues faced by women. Additionally, we made concerted efforts to reach out to the target audience and earn their trust.",
      },
      {
        heading: "Winning through Product Testing",
        subcontent:
          "During the action plan, we asked attendees at trade shows and exhibitions to test and evaluate Sereko’s products. We encouraged testers, influencers and other contributors to provide genuine and positive feedback about their experiences with Sereko. Such proactive communication increased the percentage and number of customers leaving reviews, thereby improving Sereko’s Amazon search ranking and product ratings.",
          content:
          "Sereko’s products were moved up on Amazon in the relevant search results. Consequently, the brand’s image was heavily lifted, fueled by genuine reviews from satisfied buyers. This calculative move led users to recommend Sereko’s products to their friends and relatives, further building the brand’s hype.",
          content3:
          "It’s official! Sereko is now a cool brand. Sitting beside the likes of its competitors, Sereko is now up on Amazon’s search results. We couldn't be happier! With their dermatologically tested skin products in high demand, we take pride in being a part of Sereko’s marketing success story.  ",
      
      },
      {
        heading: "Output",
        subcontent:
          "The remarkable increase in site visitors by up to 40%, coupled with an impressive 80% of customers leaving reviews, and a 100% improvement in product ratings was achieved.",
          content:
          "These results are a proof of what an impressive brand engagement and visibility we bought on the table for Sereko. Onwards and Upwards from here!",
        
      
      },
      // {
      //   heading: "Results",
      //   content:
      //     "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },
    
      
    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Serekocase}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}
                      

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && section.list3 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                          <li className="list_disc_ul">  {section.list3 && <p>{section.list3}</p>}</li>
                        </ul>
                        )}
                        {section.submorecontent && <p>{section.submorecontent}</p>}

                      </div>
                    ))}
                    
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SerekoCaseStudiesInner;
