import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  Aani,
  Aashi,
  CAseimages,
  Caseimages,
  Caseresult,
  Chalwahankhaatehai,
  Covervideoimage,
  Foodieboy,
  Foodjunction,
  Foodsearch,
  Foodtravls,
  Gunjan,
  Hanshika,
  Hazel,
  Ishita,
  Jessmeet,
  Mayank,
  Muskan,
  Playbutton,
  Pr003,
  Prachi,
  Sanyavig,
  Shuriti,
  Tinyshree,
  Vegpic,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const CaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d63",
    title: "How GoPappe Amped Up Its Social Growth Via Influencer Campaigns",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Campaign Objective",
        content:
          "To create hype and brand awareness about GoPappe’s food restaurant, emphasising their affordable mouth-watering delicacies and exclusive food offers.",
      },
      {
        heading: "About Brand ",
        subHeading:
          "Punjabi food, Dhabe di feel, taste and ambience bring a hearty appeal!.",
        subHeadingnewe: "That’s the drooling vibe of Go Pappe.",
        content:
          "Go Pappe is a fine-dining restaurant that serves tasty and spicy cuisine with an Indian touch. The highlight of Go Pappe is that they are open till 3 a.m., making it the most visited cafe to satisfy those late-night cravings. Their pocket-friendly food and Instagrammable interior are totally worth the hype! Go Pappe's affordable lip-smacking dishes and their top-notch food presentation appeal to the senses of Delhiites.",
      },
      {
        heading: "Campaign Strategy",
        subcontent:
          "We went ahead with honing the power of Instagram influencers for the good of their cafe. After carefully examining the audience and engagement rate of influencers, we onboarded 20 influencers within the food blogging niche to run their campaigns on Instagram.",
        content:
          "The influencers worked on creating engaging reels and video stories featuring their expressions about GoPappe’s delectable food and the cafe’s ambience. To ensure the highest quality in the content, we encouraged each influencer to bring their unique spin to the brand’s campaign guidelines. The team implemented a strategy for influencers to tag and mention GoPappe, maximising the outlet’s social media visibility and reach. This collaboration was all about letting influencers spill the tea (and maybe a bit of gravy) on what makes Go Pappe the best cafe in West Delhi.",
      },
      {
        heading: "Campaign Performance",
        content:
          "In less than a month, Go Pappe's campaign cooked up some spectacular results. The influencers appealed, and enthusiastically shared their respective videos among their followers. The ripple effect was felt across social media. ",
        image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:
          "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      },
    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {
        url: "https://www.instagram.com/p/C0_6kPYvN7c/",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Muskan,
      },
      {
        url: "https://www.instagram.com/reel/C0_Lws7J-Yn/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Gunjan,
      },
      {
        url: "https://www.instagram.com/reel/C1PK6PjP2JN/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Hanshika,
      },
      {
        url: "https://www.instagram.com/reel/C1Y8dInpOtx/?igsh=c3J6NDgxcTYydjdv",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Shuriti,
      },
      {
        url: "https://www.instagram.com/reel/C1Con4tBUwL/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Foodieboy
      },
      {
        url: "https://www.instagram.com/reel/C1Ccffbv7qU/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Foodsearch
      },
      {
        url: "https://www.instagram.com/reel/C1Esag6iTBZ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Mayank
      },
      {
        url: "https://www.instagram.com/reel/C1FWTt4PQKV/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Hazel
      },
      {
        url: "https://www.instagram.com/reel/C1PQgu7JSNR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Tinyshree
      },
      {
        url: "https://www.instagram.com/reel/C1KI36tJFG7/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Ishita
      },
      {
        url: "https://www.instagram.com/reel/C1L6OXCSHKz/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Jessmeet
      },
      {
        url: "https://www.instagram.com/reel/C1ji1eTvb1V/?igsh=MW9qbDdvY2pqeXhmZw%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Foodjunction
      },
      {
        url: "https://www.instagram.com/reel/C1PlU3nybpt/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Pr003
      },
      {
        url: "https://www.instagram.com/reel/C1Pf2NoI_Vk/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Chalwahankhaatehai
      },
      {
        url: "https://www.instagram.com/reel/C1UePffPHPM/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Foodtravls
      },
      {
        url: "https://www.instagram.com/reel/C1b1BO1PlN-/?igsh=MWYzd2Eza3IwcjJpeg%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Prachi
      },
      {
        url: "https://www.instagram.com/reel/C1h5otrSAPt/?igsh=YjRtMnViY2VydGRp",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Aashi
      },
      {
        url: "https://www.instagram.com/_.its.anii/reel/C1b9RsAoXeD/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Aani
      },
      {
        url: "https://www.instagram.com/reel/C1hBja6veo7/?igsh=OW5iZDEyeDE3bWln",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Vegpic
      },
      
      {
        url: "https://www.instagram.com/reel/C04Yzgmy_1p/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:Sanyavig
      },
      
    ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={CAseimages}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Caseresult}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesInner;
