import React, { useState } from "react";
import MyStepperInfluncers from "../../components/steper/SteperInfluncers";
import MyStepperBrands from "../../components/steper/SteperBrands";
import BrandsEnquiry from "../../components/steper/BrandsEnquiry";
import { Helmet } from "react-helmet-async";

const BrandsFormLeads = () => {
  return (
    <>
  <Helmet>
  <title>Marqeting - Brand Leads</title>

  <meta name="robots" content="noindex,nofollow" />
  <meta name="googlebot" content="noindex" />
</Helmet>

      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container">
            <BrandsEnquiry />
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandsFormLeads;
