import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
const CheckboxComponent = ({
  label,
  value,
  name,
  onChange,
  checked,
  inputType = "checkbox",
  error,
  disabled,
  id,
}) => {
  return (
    <Grid>
      <Checkbox
        sx={{ p: 0, m: 0, color: "#000000" }}
        id={id}
        type={inputType}
        value={value}
        checked={checked}
        onChange={onChange}
        name={name}
        disabled={disabled}
        size="small"
      />
      <label
        style={{ marginLeft: "5px", fontSize: "14px", fontWeight: "400" }}
        htmlFor={id}
      >
        {label}
      </label>
    </Grid>
  );
};

export default CheckboxComponent;
