import styled from "@emotion/styled";
import {
  Select,
  Accordion,
  MenuItem,
  AccordionSummary,
  Typography,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const CustomTypography = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #163e6e;
  font-family: "Outfit", sans-serif;
`;

export const CustomAddIcon = styled(AddIcon)`
  width: 28px;
  height: 28px;
  color: #163e6e;
`;

export const CustomRemoveIcon = styled(RemoveIcon)`
  width: 28px;
  height: 28px;
  color: #163e6e;
`;
export const CustomAccordion = styled(Accordion)`
  border: none;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 12px;
  &.MuiAccordion-root:before {
    content: none;
  }
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
  background: #ffffff;
  border-radius: 5px;
  height: 92px;
`;
export const CustomMenuItem = styled(MenuItem)`
  color: #163e6e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const CustomSelect = styled(Select)`
color: #163e6e;
font-family: "Poppins", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;
