import React, { useState, useEffect, useRef } from "react";

import {
  IncomingCall,
  LandingFrame,
  LandingGmail,
  LandingLoction,
} from "../../components/IconsImport";
import { TextField, Grid, FormControl } from "@mui/material";
import CustomInputLabel from "../../components/styledCcomponents/styledInput";
import { DescriptionInput } from "../../components/styledCcomponents/StyledMenu";
import { Helmet } from "react-helmet-async";
import { validateInput } from "../../common/utils/validationUtils";
import { PATTERNS } from "../../common/utils/constant";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { postJsonData } from "../../Services/AxoisInterceptorr";
import MarqetingEndpoint from "../../Services/marqetingEndpoint";
import { toast } from "react-toastify";

const ContactUsComponent = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [capVal, setCapVal] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: {
      value: "",
      isValid: true,
    },

    email: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
    message: {
      value: "",
      isValid: true,
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [dropdown, setDropdown] = useState({
    value: "",
    isValid: true,
  });
  const form = useRef();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    // const isValid = regEx ? validateInput(value, regEx) : true;
    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const handleSendData = () => {
    const jsonData = {
      fullName: formData.name.value,
      email: formData.email.value,
      contact: formData.phone.value,
      interestedIn: dropdown.value,
      message: formData.message.value,
    };

    postJsonData(
      MarqetingEndpoint.CONTACTUS,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setCapVal(null);
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );
  };
  const handleLogin = (e) => {
    e.preventDefault();

    // Check if all fields are valid and not empty
    const isFormValid =
      formData.name.isValid &&
      formData.email.isValid &&
      formData.phone.isValid &&
      formData.message.isValid &&
      formData.name.value.trim() !== "" &&
      formData.email.value.trim() !== "" &&
      formData.phone.value.trim() !== "" &&
      dropdown.value.trim() !== "" &&
      formData.message.value.trim() !== "";

    setFormSubmitted(true);
    if (isFormValid) {
      handleSendData();
      emailjs
        .sendForm(
          "service_euxdkva",
          "template_n765oco",
          form.current,
          "rLxp_diqBYoSXW0oO"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      setIsSubmitted(true);
      setCapVal(null);
      // Clear the form fields directly
      form.current.reset();
      // Hide the message after 3 seconds
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
    } else {
      console.log("Form data is not valid.");
    }

    // Always clear the form fields after submission
    setFormData({
      name: {
        value: "",
        isValid: true,
      },
      email: {
        value: "",
        isValid: true,
      },
      phone: {
        value: "",
        isValid: true,
      },
      message: {
        value: "",
        isValid: true,
      },
    });
    setDropdown({
      value: "",
      isValid: true,
    });
    setFormSubmitted(false);
  };

  return (
    <>
      <Helmet>
        <title>Marqeting - Contact Us</title>

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="robots" content="index,follow" />

        <meta
          name="og:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="og:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta name="og:url" content="https://www.marqeting.in/contactus" />

        <meta name="og:site_name" content="Marqeting" />

        <meta
          name="og:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="og:keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="twitter:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="twitter:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="description"
          content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
        />
      </Helmet>
      {/* <div className="container_lfluid"> */}
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="contactus_container_innar">
            <div className="contactus_container_right">
              <div className="contact_container">
                <h1 className="contact_typograpy_heading">Contact Us</h1>
                <h3 className="contact_disc">
                  Have any queries? Share your query via form or send us an
                  email at support@marqeting.in or call us at +91-9355174440
                </h3>

                <div className="contact_innercontainer">
                  <div className="contact_left">
                    <ul>
                      <li>
                        <img src={IncomingCall} />{" "}
                        +91-9355174440&nbsp;,&nbsp;011-35448755
                      </li>
                      <li>
                        <img src={LandingGmail} /> support@marqeting.in
                      </li>
                      <li>
                        <img src={LandingLoction} /> B1/628, Lower Ground Floor,
                        opposite Metro Pillar Number 570, Janakpuri, New
                        Delhi-110058
                      </li>
                    </ul>
                  </div>

                  <div className="contact_right">
                    <form ref={form} onSubmit={handleLogin}>
                      <Grid item xs={6} sm={6} sx={{ paddingBottom: "15px" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomInputLabel>Full Name*</CustomInputLabel>

                          <DescriptionInput
                            required
                            name="name"
                            type="text"
                            placeholder="Enter First Name"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              setValues(e, "name", PATTERNS.PERSONNEW_NAME);
                            }}
                          />
                          {formData.name.isValid || (
                            <p className="input_text_error">
                              *Please enter your full name.
                            </p>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6} sx={{ paddingBottom: "15px" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomInputLabel>Email Address*</CustomInputLabel>

                          <DescriptionInput
                            required
                            name="email"
                            type="text"
                            placeholder="Enter Email Address"
                            id="outlined-required"
                            label="Email"
                            helper
                            size="small"
                            onChange={(e) => {
                              setValues(e, "email", PATTERNS.EMAIL);
                            }}
                          />
                          {formData.email.isValid || (
                            <p className="input_text_error">
                              *Please enter valid email address.
                            </p>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6} sx={{ paddingBottom: "15px" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomInputLabel>Contact*</CustomInputLabel>

                          <DescriptionInput
                            required
                            name="phone"
                            type="text"
                            placeholder="Enter Contact Name"
                            id="outlined-required"
                            label="Phone"
                            helper
                            size="small"
                            onChange={(e) => {
                              setValues(e, "phone", PATTERNS.MOBILE);
                            }}
                          />
                          {formData.phone.isValid || (
                            <p className="input_text_error">
                              *Please enter valid phone number.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} sx={{ paddingBottom: "15px" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomInputLabel>Interested In*</CustomInputLabel>

                          <select
                            className="inputstyle_select"
                            id="dropdown"
                            name="option"
                            value={dropdown.value}
                            onChange={(e) =>
                              setDropdown({
                                value: e.target.value,
                                isValid: true,
                              })
                            }
                          >
                            <option value="">I'm interested in</option>
                            <option value="Influencer">Influencer</option>
                            <option value="Brand">Brand</option>
                            <option value="Day Pass">Other</option>
                          </select>

                          {formSubmitted && !dropdown.value && (
                            <p className="input_text_error">
                              *Please select an option.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} sx={{ paddingBottom: "15px" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <CustomInputLabel>Message*</CustomInputLabel>

                          <DescriptionInput
                            required
                            name="message"
                            type="text"
                            multiline
                            rows={3}
                            placeholder="Enter Message"
                            id="outlined-required"
                            label="Message"
                            helper
                            size="small"
                            onChange={(e) => {
                              setValues(e, "message", PATTERNS.MSG);
                            }}
                          />
                          {formData.message.isValid || (
                            <p className="input_text_error">
                              *Kindly ensure that your message contains at least
                              15 characters.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      <ReCAPTCHA
                        sitekey="6LcTQy0pAAAAAJwipNtK-1Cyfscd0DOsb-YUhqgx"
                        // sitekey="6LfMJR8pAAAAANOgSmosSeJEHnyoi7Gmu2EiS2Q1"
                        onChange={(val) => setCapVal(val)}
                      />
                      <div className="submit_container">
                        <div className="form_button">
                          {capVal &&
                          formData.name.value &&
                          formData?.email.value &&
                          formData.phone.value &&
                          formData.message.value &&
                          dropdown?.value ? (
                            <button type="submit" className="contact_submit">
                              Send message
                            </button>
                          ) : (
                            <button
                              disabled
                              className="contact_submit_disabled"
                            >
                              Send message
                            </button>
                          )}
                        </div>
                      </div>

                      {isSubmitted && (
                        <div className="thank_you">
                          {" "}
                          <p>Thank You, your query has been submitted.</p>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              <div className="Landing_frame">
                <img src={LandingFrame} alt="contact_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ContactUsComponent;
