import React from "react";
import "./style.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import MainRoutes from "./Routes";
// import AboutUs from "./pages/landingPages/AboutUs";

//admin section end

const App = () => {
  return (
    <>
      <MainRoutes />
      <ToastContainer theme="colored"/>

    </>
  );
  
};

export default App;
