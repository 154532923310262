import { toast } from "react-toastify";

import axios from "axios";
// import LRU from "lru-cache";
import useAxios, { configure } from "axios-hooks";
import { BASE_URL } from "./marqetingEndpoint";

// Initialize the cache
// const cache = new LRU({ max: 10 });

let axiosInstance;

// Create a new Axios instance without interceptors
export const getAxios = () => {
  axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 1500000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  configure({ axios: axiosInstance });

  return axiosInstance;
};

export const useAx = (endpoint) => {
  return useAxios(endpoint);
};

let isTimerActive = false;

const onInValidAuth = (error, next) => {
  if (isTimerActive) {
    return;
  }
  if (error.response && error.response.status === 401) {
    isTimerActive = true;
    localStorage.clear();

    // console.log("Unauthorized Access!");

    setTimeout(() => {
      const location = window.location;
      let baseUrl = location.protocol + "//" + location.host;
      // window.open(baseUrl, "_self");
      isTimerActive = false;
    }, 10000);
  }
  if (next) {
    next(error);
  } else {
    // console.log(error);
    // apiErrorToast(error);
  }
};

export const postFormData = (
  endpoint,
  formData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};

export const postJsonData = (
  endpoint,
  jsonData,
  setIsProgress,
  onSuccess,
  onError
) => {
  if (setIsProgress) setIsProgress(true);
  const axiosInstance = getAxios()

    .post(endpoint, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};

export const getJsonData = (endpoint, setIsProgress, onSuccess, onError) => {
  if (setIsProgress) setIsProgress(true);
  axiosInstance
    .get(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      const data = response;
      onSuccess(data);
      if (setIsProgress) setIsProgress(false);
    })
    .catch((error) => {
      if (setIsProgress) setIsProgress(false);
      onInValidAuth(error, onError);
    });
};
