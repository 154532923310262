import React, { useState } from "react";
import MyStepperInfluncers from "../../components/steper/SteperInfluncers";
import MyStepperBrands from "../../components/steper/SteperBrands";

const BrandsForm = () => {
  
  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
        <div className="we_have_solution_container">

       <MyStepperBrands/>
        </div>
      </div>
      </div>
    </>
  );
};

export default BrandsForm;
