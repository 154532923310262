import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {

  Asshiaaanandtyb,Dati8ngfoodtyb,Foodialhocityb,Himanityb,ItssellofficalTYB,Jasmeettyb,Manyatyb,Myplattertyb,Pr003tyb,Thebirdtyb,
  Playbutton,
  Poonam,
  TYybphase1result,
  Yellowbowl,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const YellowBowlCaseStudiesInner = () => {
  const data =  {
    _id: "65aa4badd2fe5c4992081d76",
    title: "How ‘The Yellow Bowl’ Kicked Up its Restaurant ROI Through Influencer Marketing",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    // description:"Greenex Nutrition sought YouTube influencer expertise, tasking the team with maximizing their brand recognition and engagement all over social media",
    caseType: "BEAUTY & PERSONAL CARE",
    
    content: [
      {
        heading: "Setback",
        subcontent:
          "With fast food now mainstream and the restaurant industry breaking records by expanding its offerings, we needed to conceive a campaign idea to position The Yellow Bowl as the top contender in its competitive space.",
        content:
          "Through our data-driven influencer marketing campaigns, The Yellow Bowl reinforced its image as the ‘head to’ restaurant for tasty and convenient dining options.",
        content2:
          "‘Donning the hat of India’s best Indo-Chinese food’- the tagline of The Yellow Bowl was the starting point of all things extraordinary. We aimed to cement this idea in the minds of the brand’s target customer base.",
  
     
        },
        
      {
        heading: "Solution",
    
        subcontent:
        "Partnering with a crew of food influencers to tap into the new market segment for The Yellow Bowl, we generated a tonne of innovative and original content with highly targeted reach, and garnered promising results from The Yellow Bowl’s campaigns.",
     
  
      },
      {
        heading: "Tapping into the Food Influencers",
    
        subcontent:
        "Marqeting’s highly professional in-house execution team screened the top performing profiles of Instagram creators, all within the food vlogging niche, with an overall reach of 9,00,000. By shortlisting creators from the food industry instead of choosing non-niche influencers, we were able to target a new market segment for our client. This made The Yellow Bowl the top choice for those who love to eat like there’s no tomorrow.",
      content:
     "As per the content guidelines shared by our team, the creators worked on making innovative Instagram stories and reel videos. The influencer generated content had a huge impact in boosting hype for the brand, leading to a high viewership rate all over Instagram. The profound partnerships with influencers meant they all became a part of The Yellow Bowl’s success story in the most authentic way, rather than just being hired guns.",
  
      },
   
      {
        heading: "Results that Speak Volumes",
    

        subcontent:
        "Central to the campaign were these awe-inspiring insights that left us with a chest held high!",
          image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          imageUrl:
            "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          
      },

    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {

        url: "https://www.instagram.com/reel/C3Pyz8VPqUj/?igsh=ZzFkZDVvdGk4Y3My",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Manyatyb,
      },
      {
    
        url: "https://www.instagram.com/reel/C3QW9HNP_vO/?igsh=eGVyMm85OW9idzUw",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: ItssellofficalTYB,
      },
      
      {
      
        url: "https://www.instagram.com/reel/C3k5FSPyy7R/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Jasmeettyb,
      },
      {
 
        url: "https://www.instagram.com/reel/C3pSBW7SEdZ/?igsh=NTc4MTIwNjQ2YQ%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Pr003tyb,
      },
      {

        url: "https://www.instagram.com/p/C3py1HsvD4d/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Foodialhocityb,
      },
     
      {
    
        url: "https://www.instagram.com/reel/C3vk7v2SpPW/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Asshiaaanandtyb,
      },
     
      {
        url: "https://www.instagram.com/p/C3u-cv1S-SC/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Myplattertyb,
      },
     
      {
    
        url: "https://www.instagram.com/p/C3wrCN_p8CJ/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Himanityb,
      },
      {
   
        url: "https://www.instagram.com/reel/C3u9AXKPfta/?igsh=OTR1bmdxMW9pY2V3",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Dati8ngfoodtyb,
      },
      {
     
        url: "https://www.instagram.com/p/C3zz1L1vMyp/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Thebirdtyb,
      },
     
    ]
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Yellowbowl}
                  alt={data.title}
                />
                   {data.description && <h1>{data.description}</h1>}
         {/* <p>{data.description}</p> */}
                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                      {section.headingContent && (
                          <h1
                            style={{ marginTop: "10px" }}
                          >
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}

                        { section.subHeadingnewe && (
                      
                            <p className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </p>
                        )}
                  
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={TYybphase1result}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                        </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YellowBowlCaseStudiesInner;
