import React from "react";
import LandingNavbar from "../components/sidebar/LandingNavbar";
import { Navigate, useLocation } from "react-router-dom";
import Footer from "../pages/landingPages/footer";

const PublicRoute = ({ ...rest }) => {
    // const token = localStorage.getItem("token");
    const location = useLocation();

  

    // if (!token) {
        return (
            <>
                 <LandingNavbar />
                {rest.element}
                <Footer />
                 {/* <Footer /> */}
            </>
        );
    } 
// };

export default PublicRoute;
