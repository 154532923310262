import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicLayout from "../Routes/public-layout";

// import ScrollToTop from "../components/ScrollToTop";
import configureRoutes from "./routes";
import ErrorLayout from "./ErrorLayout";

const MainRoutes = () => {
  const routes = configureRoutes();

  return (
    <>
      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <Routes>
          {routes.map((route) => {
          
            if (route.type === "public") {
              return (
                <Route
                  path={route.path}
                  element={<PublicLayout  {...route} />}
                />
              );
            } 
              if (route.type === "error") {
              return (
                <Route path={route.path} element={<ErrorLayout  {...route} />} />
              );
            }
          })}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;
