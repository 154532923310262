import { Button } from 'bootstrap';
import React, { useEffect } from 'react';
import {Link } from "react-router-dom";

export default function PageNotFound() {
    useEffect(() => {
        const spans = document.querySelectorAll('.word span');
        spans.forEach((span, idx) => {
            span.addEventListener('click', (e) => {
                e.target.classList.add('active_page');
            });
            span.addEventListener('animationend', (e) => {
                e.target.classList.remove('active_page');
            });

            // Initial animation
            setTimeout(() => {
                span.classList.add('active_page');
            }, 750 * (idx + 1))
        });
    }, [])

    return (
        <>
            <div className="pagenotfound">
                <div class="word">
                    <span>!</span>
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                    <span>!</span>
                </div>
                <div className="goback">
                    <div>!Error Page Not Found </div>
                    <Link to="/">Go to home page</Link>
                </div>


            </div>
        </>
    )
}