import React from "react";
import { AuravedicCover, CAseimages, Carefrea, Coverdkava, Coverelemnt, Delzia, Greecover, Innsightimagedkava, Innsightimageelemnt, Kreocase, Momocase, Momocover, Nuviblisscase, Serekocase, Totemcase, Unspoild, Wellvartcase, Yellowbowl, Zinklondon2, Zxn } from "../../components/IconsImport";
import { useNavigate } from "react-router-dom";
import { useCaseStudy } from "../../components/customHooks/customHook";

const CaseStudiesCards = () => {
  const navigate = useNavigate();
  // const handleCardClick = (title) => {
  //   navigate(`/case-studies-inner/${title}`);
  // };
  const handleCardClick = (title, id) => {
    let slug;
    if (title === "How GoPappe Amped Up Its Social Growth Via Influencer Campaigns") {
      slug = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') 
        .replace(/(^-|-$)+/g, '');  
      window.location.href = `/case-studies/gopappe/${slug}`;
    } else if (title === "Tapping Into Lifestyle Influencers To Drive Jaw-Dropping Impact For DKava Decor") {
      slug = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') 
        .replace(/(^-|-$)+/g, '');  
      window.location.href = `/case-studies/dkava/${slug}`;
    }
     else if (title === "How Elemnt Increased Its Brand Visibility By 120% On E-Commerce Marketplaces") {
      slug = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') 
        .replace(/(^-|-$)+/g, '');  
      window.location.href = `/case-studies/elemnt/${slug}`;
    }
     else if (title === "How Influencer Campaigns Lit Up Momomia’s Hype By 2X") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/momomia/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Driving Shoppers at Sereko’s Amazon Product Listing Page") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/sereko/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "How Totem Increased Its Business Growth through Planned E-commerce Solutions") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/totem/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Kreo X Marqeting The Growth Story of a Consumer Electronics Brand- Kreo") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/kreo/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Driving Dental Consumers’ Loyalty and Brand Engagement for Wellvart") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/wellvart/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Amazon Marketing Campaign Skyrockets Brand Visibility for Nuvibliss") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/nuvibliss/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "How Auravedic Deployed a Laser-Focused Instagram Marketing Strategy with Marqeting") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/auravedic/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Inspiring Locked-Down Skin Lovers to Shop from Care Freak Online") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/care-freak/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Achieving Benchmark-Beating Impressions for Delizia Fragrances") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/delizia/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Utilizing YouTube Creators to Solidify GXN’s Position as the Leading Fitness Nutrition Brand") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/greenex-nutrition/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "How ‘The Yellow Bowl’ Kicked Up its Restaurant ROI Through Influencer Marketing") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/yellow-bowl/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "How Marqeting Drove Incredible Campaign Results for Unspoiled Skincare") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/unspoiled-skincare/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Using Creators to Produce UGC (User-Generated Content) At Scale for Zink London") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/zink-london/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
     else if (title === "Cool is in the Air: A Thoughtful Influencer Marketing Campaign for GreeAircon") {
      slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') 
      .replace(/(^-|-$)+/g, '');  
    window.location.href = `/case-studies/gree-air-conditioner/${slug}`;
      // navigate(`/case-studies/${id}`);
    }
    //  else if (title === "Phase 2: Sereko’s E-Commerce Growth Through Multi-Product Integration") {
    //   slug = title
    //   .toLowerCase() 
    //   .replace(/[^a-z0-9]+/g, '-') 
    //   .replace(/(^-|-$)+/g, '');  
    // window.location.href = `/case-studies/${slug}`;
    //   // navigate(`/case-studies/${id}`);
    // }
  }
  
  const caseStudiesData = [
    {
      _id: "65aa4badd2fe5c4992081d63",
      title: "How GoPappe Amped Up Its Social Growth Via Influencer Campaigns",
      coverImage: {
        image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
        imageUrl:CAseimages
          ,
      },
      caseType: "FOOD & LIFESTYLE",
    },
    {
      _id: "65aa4badd2fe5c4992081d64",
      title: "How Influencer Campaigns Lit Up Momomia’s Hype By 2X",
      coverImage: {
        image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Momocover,
      },
      caseType: "FOOD & LIFESTYLE",
    },
    {
      _id: "65aa4badd2fe5c4992081d65",
      title: "Tapping Into Lifestyle Influencers To Drive Jaw-Dropping Impact For DKava Decor",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Coverdkava,
      },
      caseType: "DECOR & GIFTS",
    },
    {
      _id: "65aa4badd2fe5c4992081d65",
      title: "How Elemnt Increased Its Brand Visibility By 120% On E-Commerce Marketplaces",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Coverelemnt,
      },
      caseType: "SKINCARE",
    },
    {
      _id: "65aa4badd2fe5c4992081d66",
      title: "Driving Shoppers at Sereko’s Amazon Product Listing Page",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Serekocase,
      },
      caseType: "SKINCARE",
    },
    {
      _id: "65aa4badd2fe5c4992081d68",
      title: "How Totem Increased Its Business Growth through Planned E-commerce Solutions",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Totemcase,
      },
      caseType: "GADGETS & TECH",
    },
    {
      _id: "65aa4badd2fe5c4992081d69",
      title: "Kreo X Marqeting The Growth Story of a Consumer Electronics Brand- Kreo",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Kreocase,
      },
      caseType: "GAMING & TECH",
    },
    {
      _id: "65aa4badd2fe5c4992081d70",
      title: "Driving Dental Consumers’ Loyalty and Brand Engagement for Wellvart",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Wellvartcase,
      },
      caseType: "PERSONAL CARE",
    },
    {
      _id: "65aa4badd2fe5c4992081d71",
      title: "Amazon Marketing Campaign Skyrockets Brand Visibility for Nuvibliss",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Nuviblisscase,
      },
      caseType: "HEALTHCARE",
    },
    {
      _id: "65aa4badd2fe5c4992081d72",
      title: "How Auravedic Deployed a Laser-Focused Instagram Marketing Strategy with Marqeting",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:AuravedicCover,
      },
      caseType: "COSMETICS",
    },
    {
      _id: "65aa4badd2fe5c4992081d73",
      title: "Inspiring Locked-Down Skin Lovers to Shop from Care Freak Online",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Carefrea,
      },
      caseType: "SKINCARE",
    },
    {
      _id: "65aa4badd2fe5c4992081d74",
      title: "Achieving Benchmark-Beating Impressions for Delizia Fragrances",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Delzia,
      },
      caseType: "COSMETICS",
    },
    {
      _id: "65aa4badd2fe5c4992081d75",
      title: "Utilizing YouTube Creators to Solidify GXN’s Position as the Leading Fitness Nutrition Brand",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Zxn,
      },
      caseType: "FITNESS",
    },
    {
      _id: "65aa4badd2fe5c4992081d76",
      title: "How ‘The Yellow Bowl’ Kicked Up its Restaurant ROI Through Influencer Marketing",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Yellowbowl,
      },
      caseType: "CAFE AND RESTAURANTS",
    },
    {
      _id: "65aa4badd2fe5c4992081d77",
      title: "How Marqeting Drove Incredible Campaign Results for Unspoiled Skincare",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Unspoild,
      },
      caseType: "SKINCARE",
    },
    {
      _id: "65aa4badd2fe5c4992081d78",
      title: "Using Creators to Produce UGC (User-Generated Content) At Scale for Zink London",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Zinklondon2,
      },
      caseType: "FASHION & LIFESTYLE",
    },
    {
      _id: "65aa4badd2fe5c4992081d79",
      title: "Cool is in the Air: A Thoughtful Influencer Marketing Campaign for GreeAircon",
      coverImage: {
        image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
        imageUrl:Greecover,
      },
      caseType: "ELECTRONICS",
    },

    // {
    //   _id: "65aa4badd2fe5c4992081d67",
    //   title: "Phase 2: Sereko’s E-Commerce Growth Through Multi-Product Integration",
    //   coverImage: {
    //     image: "1705659309229-268845077-dkava.dc9fa0a2e1bc30b0c353.png",
    //     imageUrl:Coverelemnt,
    //   },
    //   caseType: "E-COMMERCE",
    // },

  ];
  return (
    <>
      {caseStudiesData.map((data, index) => (
        <div
          className="case_card_conatiner"
          key={index}
          onClick={() => handleCardClick(data.title, data._id)}
        >
          <div className="case_images">
            <img src={data.coverImage.imageUrl} alt="case images" />
          </div>
          <div className="case_sub_container">
            <ul>
              <li>
                <p className="case_category">{data.caseType}</p>
              </li>
              <li>
                <h1 className="case_title">{data.title}</h1>
              </li>
              {/* <li><h4 className="case_by">By {data.author}</h4></li>
              <li><h6 className="case_by_date">{data.date}</h6></li> */}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default CaseStudiesCards;
