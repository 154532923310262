import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Caseimages,
  Caseresult,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const CaseStudiesInnercopymomo = () => {
  const data =  {
    _id: "65aa4badd2fe5c4992081d64",
    title: "How Influencer Campaigns Lit Up Momomia’s Hype By 2X",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "Momomia, a fast food restaurant, counted on Marqeting to build its brand awareness and encourage customers to visit their restaurant through influencer promotions.",
    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Campaign Objective",
        content:
          "With a team of experienced professionals, Marqeting knew Momomia needed to-",
        list1:
          "Promote their wide menu choices",
        list2:
          "Build trust and curiosity in Gen-Z’s and Gen-Y’s",
        list3:
          "Convert first-time visitors into repeated ones",
      },
      {
        heading: "About Brand",
        
        subHeadingnewe: "Who doesn’t love to eat momos, especially in Dilli Ki Sardi? The joy gets doubled when your group finds the right outlet to huddle around a steaming plate of momos, chatting and enjoying the cosy ambience.",
        content:
          "Momomia has diverse dishes with its broad menu featuring over 100 varieties of momo and tempting side dishes such as pizzas, rolls, and Manchurian.The high quality, hygiene and different flavours of momos give a competitive advantage to Momomia over the street vendors.",
      },
      {
        heading: "The Action Plan",
        subcontent:
          "To reach and connect with the youth, Momomia focused on its social media presence with Marqeting shortlisting top 8 influencers in the food blogging niche to run their Instagram campaigns. The content revolved around Influencers creating engaging reels and video stories, influencing their fanbase as they snapped the outlet’s tasty and affordable dishes. Higher the creativity, higher the engagement!",
        content:
          "The campaigns weren’t just about showcasing their food or services, influencers also highlighted Momomia’s ongoing offer- anyone ordering above Rs. 250 could get a 10% discount by creating a dine-in Instagram reel, tagging the brand. The campaigns aimed to entice food addicts to visit Momomia’s outlet and enjoy amazing offers on their special dishes.",
      },
      {
        heading: "Campaign Performance",
        content:
          "In less than two months, Marqeting helped Momomia gather significant results. To get the ball rolling (to spike followers’ interest about Momomia’s diverse food menu), influencers shared their videos on their respective IG handles. Within a short time frame, the Instagram campaigns had a positive impact on Momomia’s performance",
        image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:
          "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      },
    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {
        url: "https://www.instagram.com/reel/C1RLQkWvE46/",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Disthi,
      },
      {
        url: "https://www.instagram.com/p/C1PAiOrLnav/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Expertview,
      },
      {
        url: "https://www.instagram.com/reel/C1RD5K8vpix/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Kavita,
      },
      {
        url: "https://www.instagram.com/reel/C1CgMi8PTOK/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Mahi,
      },
      {
        url: "https://www.instagram.com/reel/C1CO2R6JRt2/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Poonam,
      },
      {
        url: "https://www.instagram.com/p/C03WbYzJULQ/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Gourav,
      },
      {
        url: "https://www.instagram.com/reel/C08vlIKL8Yr/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Mybutter,
      },
      {
        url: "https://www.instagram.com/reel/C1HufEqxsmk/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Foodntales,
      },
    ]
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Momocover}
                  alt={data.title}
                />
                <p>{data.description}</p>

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}

                        { section.subHeadingnewe && (
                      
                            <p className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </p>
                        )}
                  
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Momocase}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.list1 && section.list2 && section.list3 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                          <li className="list_disc_ul">  {section.list3 && <p>{section.list3}</p>}</li>
                        </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudiesInnercopymomo;
