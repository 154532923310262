import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Caseaanknsha,
  Casebuttrybros,
  Casedarsthi,
  Casedishii,
  Caseimages,
  Casekniska,
  Caseleena,
  Casenikita,
  Caseresult,
  Caseshardha,
  Casesoniasah,
  Cashpurvising,
  Covervideoimage,
  Deliziaphase1result,
  Delzia,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const DeliziaCaseStudiesInner = () => {
  const data =  {
    _id: "65aa4badd2fe5c4992081d74",
    title: "Achieving Benchmark-Beating Impressions for Delizia Fragrances",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },

    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Campaign Overview",
        subcontent:
          "The beauty industry is taking the world by storm and making waves left, right, and center. However, for brands dealing in less visually appealing beauty verticals like skincare and fragrances, the task to identify influencers and maintain long-term brand advocacy with the latter might be daunting. Particularly in the fragrance vertical, which depends heavily on personal consumer experience, leveraging social content and creating hype online is an uphill pressure.",
        content:
          "Delizia Fragrances, a brand dealing in a collection of exquisite perfumes and colognes approached Marqeting to carry out creative influencer campaigns. The idea was to find brand advocates and leverage their compelling storytelling skills. Because in the world of beauty, especially when it comes to fragrances, true influencer partnerships are about striking emotional connections with the viewers and encouraging purchase action.",
     
        },
      {
        heading: "Campaign Objectives",
    
        subcontent:
        "Identify and onboard influential content creators who can advocate for Delizia Fragrances' and their product offerings.",
      content:
        "Drive brand awareness, recognition, and purchase actions among the target audience via content produced by the partnered influencers. ",
  
      },
      {
        heading: "Execution",
        
        subHeadingnewe: "Rather than partnering with a wide pool of Instagram influencers, our strategy was to handpick influencers who were performing like roses in the fragrance sector. An influencer-strategy that plays into Delizia’s strength of creating sensory-rich fragrances revolved around leveraging visually appealing and share worthy content.",
        content:
          "By letting the shortlisted influencers talk about the USPs (Unique Selling Points) of Delizia’s fragrances, was one approach that Delizia got creative with their influencer community building. The action plan was focused around creating emotional connections with the viewers through the sense of smell.",
        content2:
          "This was because smell has strong ties to emotions, evoking profound reactions in the audience. The influencers took this advantage and shared their personal preferences of Delizia’s perfumes. Their relatable and trustworthy content performed well, inviting followers to explore those perfumes.",
      },
      {
        heading: "Results",
    

          image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          imageUrl:
            "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          
      },

    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {
        url: "https://www.instagram.com/p/C5IosILN0L6/",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Casebuttrybros,
      },
      {
        url: "https://www.instagram.com/p/C5nutNOJJw8/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Casekniska,
      },
      {
        url: "https://www.instagram.com/p/C5nv-lLJKTj/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Caseshardha,
      },
      {
        url: "https://www.instagram.com/p/C5AeD31PyyR/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Casenikita,
      },
      {
        url: "https://www.instagram.com/p/C4-Rrxwhfz4/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Caseleena,
      },
   
    ]
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Delzia}
                  alt={data.title}
                />

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}

                        { section.subHeadingnewe && (
                      
                            <p className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </p>
                        )}
                  
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Deliziaphase1result}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                        </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliziaCaseStudiesInner;
