import React, { useState } from 'react'
import InfluencerCards from '../../common/Campaigncards/InfluencerCards'
import { useNavigate } from 'react-router-dom';

const InfluencersSection = () => {
  const [isHovered, setIsHovered] = useState(false);
const navigate= useNavigate();
  return (
    <div className="our_top_influencers_container">
    <div className="hcontainer">
      <div>
        {" "}
        <h1 className="top_influencer_heading">Some Of Our Influencers</h1>
        {/* <div className="top_influencers_container_flex"> */}
        <InfluencerCards />
        <div className="coonect_button" style={{textAlign:"center",marginTop:"50px"}}>
        <button
                 onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={isHovered ? "brandbutton hover" : "brandbutton"}

                  onClick={() => navigate("/influencers-onboarding")}
                >
                  Sign up as an Influencer
                </button>
        </div>
        {/* </div> */}
      </div>
    </div>
  </div>
  )
}

export default InfluencersSection;
