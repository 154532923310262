import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  OutlineFacebook,
  OutlineInstagram,
  OutlineLinkedIn,
  OutlineTwitter,
  OutlineYoutube,
  Whatsappnew,
} from "../../components/IconsImport";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const year = new Date().getFullYear();

  const form = useRef();
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail) {
      // Perform the form submission only if the email is valid
      emailjs
        .sendForm(
          "service_euxdkva",
          "template_5yuj8ln",
          form.current,
          "rLxp_diqBYoSXW0oO"
        )
        .then(
          (result) => {
            console.log(result.text);
            setSubmitted(true);

            // Reset the form after submission if needed
            setEmail("");
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      // Handle invalid email case (display error, prevent form submission, etc.)
      console.log("Invalid email address");
    }
  };
  useEffect(() => {
    let timeout;
    if (submitted) {
      timeout = setTimeout(() => {
        setSubmitted(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [submitted]);

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      if (
        pathname === "/terms" ||
        pathname === "/about" ||
        pathname === "/privacy" ||
        pathname === "/faq" ||
        pathname === "/Careers" ||
        pathname === "/case-studies" 

      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, [pathname]);

    return null;
  }

  return (
    <footer>
      <div className="container_footerfluid">
        <div className="hcontainer">
          <div className="newfootercontent">
            <div className="newfooter_left">
              <div className="footer_error_flex">
                <div className="newfooter_innerleft">
                  <form
                    ref={form}
                    className="form_footer"
                    onSubmit={handleSubmit}
                  >
                    <input
                      name="email"
                      type="text"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      className={`footer_input ${
                        !isValidEmail ? "invalid" : ""
                      }`}
                    />

                    {/* <p>Email to our email</p> */}
                    <div className="sbscribe_buuton">
                      <button type="submit">Subscribe</button>
                    </div>
                  </form>
                </div>
                {!isValidEmail && (
                  <p className="input_text_error_footer">
                    {" "}
                    *Please enter your valid email address.
                  </p>
                )}
                {submitted && <p className="footer_thankyou">Thank you for subscribing!</p>}
              </div>
              <div className="newfooter_innerimages">
                <ul>
                  <li style={{display: "flex",
    justifyContent: "center",
    alignItems: "center"}}>
                    <a
                      href="https://chat.whatsapp.com/CB48qb8tadhC8rCH1KuHYq"
                      target="_blank"
                    >
                      <img style={{width:"20px",height:"20px",marginTop:"10px"}} src={Whatsappnew} alt="whatsapp icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/marqetingin/"
                      target="_blank"
                    >
                      <img src={OutlineFacebook} alt="facebook icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/marqetingin/"
                      target="_blank"
                    >
                      <img src={OutlineInstagram} alt="instagram icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/marqetingin/"
                      target="_blank"
                    >
                      <img src={OutlineTwitter} alt="x icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/marqetingin/"
                      target="_blank"
                    >
                      <img src={OutlineLinkedIn} alt="linkedin icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@marqetingin/"
                      target="_blank"
                    >
                      <img src={OutlineYoutube} alt="youtube icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="newfooter_middle">
              <div className="newfooter_contentmiddle">
                <div className="newfooter_innermiddle">
                  <h6>For general inquires</h6>
                  <p>support@marqeting.in</p>
                </div>
                <div className="newfooter_middlenav">
                  <ul>
                    <li>
                      <Link to="/terms">Terms</Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/case-studies">Case Studies</Link>
                    </li>
                    <li>
                      <Link to="/Careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                  <ScrollToTop />
                </div>
                {/* <div className="newfooter_middlenav">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/marqetingin/">Terms</a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/marqetingin/">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/marqetingin/">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/marqetingin/">FAQ</a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="newfooter_right">
              <div className="newfooter_right_content">
                <div className="newfooter_right_inner">
                  <h6>For sales inquires</h6>
                  <p> sales@marqeting.in</p>
                </div>
                <div className="newfooter_right_copy">
                  <p>Marqeting © Copyright {year}.All right reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footerContainer">
        <div className="footerinner">
            <h4 style={{    alignSelf: "center"}}>Marqeting © Copyright 2023</h4>
            <div className='footerinner_icon'>
            
                <a href="https://www.facebook.com/marqetingin/" className="socil-media"><img src={OutlineFacebook}/></a>

                <a href="https://www.instagram.com/marqetingin/" className="socil-media"><img src={OutlineInstagram}/></a>

                <a href="https://www.twitter.com/marqetingin/" className="socil-media"><img src={OutlineTwitter}/></a>

                <a href="https://www.linkedin.com/marqetingin/" className="socil-media"><img src={OutlineLinkedIn}/></a>

                <a href="https://www.youtube.com/@marqetingin/" className="socil-media"><img src={OutlineYoutube}/></a>

            
            </div>
      
            </div>
          </div> */}
    </footer>
  );
};

export default Footer;
