import { Box } from "@mui/material";
import Button from "@mui/material/Button";

export const SmallButton = ({
  onClick,
  label,
  color = "#ffffff",
  background = "#163e6e",
  type,
  width
}) => {
  return (
    <Box sx={{display:"flex",justifyContent:"center"}}>
    <Button
      size="small"
      sx={{
        width:{width},
        borderRadius: "20px",
        padding: "5px 20px",
        color: color,
        fontFamily: '"Outfit", sans-serif',
        background: background,
        fontSize: "16px",
        fontWeight: 500,
        "&:hover": {
          background: background,
          color: color,
        },
        textTransform: "none",
      }}
      type={type}
      onClick={onClick}
    >
      {label}
    </Button>
    </Box>
  );
};

