import { styled, alpha } from "@mui/material/styles";
import { InputBase, Menu, Tab } from "@mui/material";

export const StyledMenuHeader = styled(({ theme, ...props }) => (
  <Menu
    dense
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(3),
    minWidth: 136,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    borderRadius: "0px 0px 12px 12px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow:
      "0px 4px 10px rgba(0, 0, 0, 0.05), 5px 10px 15px rgba(0, 0, 0, 0.04)",
    "& .MuiMenu-list": {
      padding: "4px 0",
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#393939",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const StyledMenuFilter = styled(({ theme, ...props }) => (
  <Menu
    dense
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(2),
    minWidth: 136,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    borderRadius: "0px 0px 12px 12px",
    boxShadow:
      "0px 4px 10px rgba(0, 0, 0, 0.05), 5px 10px 15px rgba(0, 0, 0, 0.04)",
    "& .MuiMenu-list": {
      padding: "4px 0",
      fontFamily: "'Poppins', sans-serif",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#393939",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

// styled input

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f0f0f0",
    border: "1px solid #f0f0f0",
    color: "#000000",
    fontWeight: 500,
    fontSize: 14,
    padding: "6px 26px 6px 12px",
    // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Montserrat, sans-serif",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#DDD",
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  },
}));
export const BrandInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FAFAFA",
    border: "1px solid #F2F8FD",
    color: "#828894",
    fontWeight: 400,
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Poppins, sans-serif",
    "&:focus": {
      borderRadius: 4,
      borderColor: " 1px solid #DDDDDD",
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  },
}));
export const DescriptionInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f0f0f0",
    border: "1px solid #f0f0f0",
    // backgroundColor: "#FAFAFA",
    // border: "1px solid #F2F8FD",
    color: "#000000",
    fontWeight: 500,
    fontSize: 14,
    padding: "6px 26px 6px 12px",
    // padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Montserrat, sans-serif",
    "&:focus": {
      borderRadius: 4,
      borderColor: " 1px solid #DDDDDD",
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  
  },
}));
