import React,{useState} from "react";
import companyNameServices from "../../common/constant/NotifictionData";

import AccordionItem from "../../common/Accordion/AccordionItems/AccordionItem";
import LandingSideStatic from "../../components/sidebar/landingSideStatic";
import { Helmet } from "react-helmet-async";

const FaqComponent = () => {

  const [openAccordion, setOpenAccordion] = useState(null);

  const AccordionData = [
    {
      heading: "Can I get a good ROI after investing in an influencer marketing platform?",
      answer:
        "Yes, definitely you can. Our platform employs tailored data-driven strategies and analytics to ensure your brand receives a favourable ROI through campaign execution.",
    },
    {
      heading: "How do you make certain that influencers follow the rules and regulations set up by the industry?",
      answer:
        "Our team informs influencers about the legalities and the need for content pre-approval. Open communication is maintained throughout the process of campaign execution.",
    },
    {
      heading: "How can your platform guarantee increased brand visibility?",
      answer:
        "Our platform matches your brand with influencers whose viewers align with your target audience, ensuring maximised visibility and engagement for your products or services.",
    },
    {
      heading: "How do you measure the success of influencer marketing campaigns?",
      answer:
        "To measure the success, we track engagement metrics such as likes, comments, shares and clicks. We provide regular analytical reports to evaluate the success and refine future campaigns for maximum results.",
    },
    {
      heading: "How Marqeting stays ahead of evolving market trends in influencer marketing?",
      answer:
        "Our platform remains proactive in adapting to changing trends, consistently  monitoring and analysing our competitors to ensure our strategies align with the variable market shifts.",
    },
  ];

  return (
    <>
     <Helmet>
<title>Marqeting - FAQ</title>

<meta
  name="description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="keywords"
  content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
/>

<meta name="robots" content="index,follow" />

<meta
  name="og:title"
  content="Marqeting - Connecting Brands with Trusted Influencers"
/>

<meta
  name="og:description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta name="og:url" content="https://www.marqeting.in/faq" />

<meta name="og:site_name" content="Marqeting" />

<meta
  name="og:image"
  content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
/>

<meta
  name="og:keywords"
  content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
/>

<meta name="twitter:card" content="summary_large_image" />

<meta
  name="twitter:title"
  content="Marqeting - Connecting Brands with Trusted Influencers"
/>

<meta
  name="twitter:description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="twitter:image"
  content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
/>

<meta
  name="description"
  content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
/>

<meta
  name="description"
  content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
/>

<meta
  name="description"
  content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="description"
  content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
/>
</Helmet>
      {/* <div className="container_lfluid"> */}
        <div className="aboutus_container">
        <div className="hcontainer">
          <div className="faq_container_innar">
          {/* <LandingSideStatic/> */}

            <div className="faq_container_right">
              <div className="terms_container">
                <h1 className="faq_section_heading">
                You Have Questions, We Have Answers
                </h1>
                {AccordionData.map((item, index) => (
        <AccordionItem
          key={index}
          heading={item.heading}
          // Pass a prop to indicate if this accordion is open
          isOpen={openAccordion === index}
          // Pass a function to change the open accordion when this one is clicked
          onClick={() => setOpenAccordion(openAccordion === index ? null : index)}
        >
          <p className="Information_accordian">{item.answer}</p>
        </AccordionItem>
      ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default FaqComponent;
