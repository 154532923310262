import React from "react";
import {
  Auravedic,
  Client1,
  Client2,
  Client3,
  Client4,
  Dkava,
  Dkavanew,
  Elemntt,
  Gopappe,
  Greefeedback,
  Instagram,
  Kreo,
  Kreonew,
  Momomia,
  Nuvibliss,
  Riya,
  Serekoonew,
  Sushma,
} from "../../components/IconsImport";
import Slider from "react-slick";
import { Avatar } from "@mui/material";

const OurClientsThink = () => {
  const clientsData = [
    {
      message:
        "Big gracias to @Marqeting. We had a quick discussion about the objectives and their team came up with a detailed execution plan with suggestions to improve our visibility on e-commerce marketplaces. Really appreciate their interest in comprehending the problem before selling services.",
      image: Client1,
      name: "Elemnt",
      position: "Mandar Marathe",
    },
    {
      message:
        "From the day I met their in-house team, I knew they were the right choice for my brand. We initially partnered for a brief amount of time. First impression and phase 1 results were highly impressive and we strategized for further phases as well. Our search ranking is highly improved, giving us an edge over profitability.",
      image: Client3,
      name: "Sereko",
      position: "Saksham Kulshrestha",
    },
    {
      message:
        "Marqeting helped us run our campaign during winter festivities. The execution team hooked us up with the right set of creators, and it made a huge difference in how many people saw our brand on social media. Would surely recommend Marqeting to other business partners as well.",
      image: Client2,
      name: "GoPappe",
      position: "Garvit Malik",
    },

    {
      message:
        "True influence is about authenticity. Happy to have worked with a team that knows the art of storytelling and employs it with the help of trusted influencers. They delivered what they had promised! I highly recommend running marketing campaigns through Marqeting- you will see great results in no time.",
      image: Dkavanew,
      name: "DKava Decor",
      position: "Priti Sinha",
    },
    {
      message:
        "As a business-focused electronic brand, managing influencer promotions was challenging. Selecting authentic and high-performing profiles was difficult. Marqeting provided the right creators with the skills aligned with our goals. Their services have never exposed us to the headaches of behind-the-scenes.",
      image: Greefeedback,
      name: "Gree",
      position: "Rahul Bansal",
    },
    // {
    //   message:
    //     "Our team here at Auravedic first contacted Marqeting roughly a few months back for the strategies to connect and collaborate with the top performing lifestyle influencers. The content distribution proved very effective with a collective focus on our products from all the creators.",
    //   image: Auravedic,
    //   name: "Auravedic",
    //   position: "Amrita",
    // },

    {
      message:
        "The team was available 24/7, addressing our queries and keeping us updated. Marqeting proved to be an excellent partner, assisting with everything from reaching target customers to managing orders. For brands looking to boost e-commerce sales through promotions, Marqeting is an ideal partner.",
      image: Kreonew,
      name: "Kreo",
      position: "Smrati Trivedi",
    },

    {
      message:
        "When we saw Marqeting had the potential to run A+ influencer campaigns in our budget plan, we had our eyes fixated on their services.We didn't have to hire all creators separately. It was a package deal with Marqeting and each content creator was hand selected.",
      image: Client4,
      name: "Momomia",
      position: "Nitin Gehlawat",
    },
    // {
    //   message:
    //     "Since implementing the e-commerce campaign through Marqeting, our revenue has increased. Their promotional efforts doubled our sales over the timeline of the project period. We look forward to our renewed collaboration with them. Typically, these projects can be time and effort sucking, but their team made everything easy and smooth.",
    //   image: Nuvibliss,
    //   name: "Nuvibliss",
    //   position: "Gitika",
    // },

    // Add more client data here...
  ];
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 5000,
    speed: 500,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...sliderSettings}>
      {clientsData.map((client, index) => (
        <div className="our_client_card" key={index}>
          <div className="our_client_card_inner">
            {/* <div className="client_sub_inner">
          <img alt="image"  src={client.image}/>

         </div> */}

            <div className="client_sub_inner">
     
              <img
 src={client.image} alt="image" />
              <div className="client_sub_inner_sub">
                <div>
                  <h2>{client.name}</h2>
                  <p style={{ fontWeight: "600" }}>{client.position}</p>
                </div>
                <p style={{ textAlign: "justify" }}>{client.message}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default OurClientsThink;
