


// import  sidebar icons
import dashboard from "../assets/images/sidebaricons/dashboard.svg";
import invoices from "../assets/images/sidebaricons/invoices.svg";
import marqetinglogosidebar from "../assets/images/sidebaricons/marqetinglogosidebar.svg";
import marqetingsmalllogo from "../assets/images/sidebaricons/marqetingsmalllogo.svg";
import newcampaigns from "../assets/images/sidebaricons/newcampaigns.svg";
import notification from "../assets/images/sidebaricons/notification.svg";
import profile from "../assets/images/sidebaricons/profile.svg";
import userlogout from "../assets/images/sidebaricons/userlogout.svg";
import viewcampaigns from "../assets/images/sidebaricons/viewcampaigns.svg";
import sidebarmenu from "../assets/images/sidebaricons/sidebarmenu.svg";
import marqetinglogo from "../assets/images/marqetinglogo.svg";
import notifictionnavbar from "../assets/images/notifictionnavbar.svg";
import filtericon from "../assets/images/filtericon.svg";
import cloudupload from "../assets/images/cloudupload.svg";
import insertlinkicon from "../assets/images/insertlinkicon.svg";
import tabicon from "../assets/images/tabicon.svg";
import addmoreicon from "../assets/images/addmoreicon.svg";
import mmymoney from "../assets/images/sidebaricons/mmymoney.svg";
import mmywithdrwal from "../assets/images/sidebaricons/mmywithdrwal.svg";
import mmycampaign from "../assets/images/sidebaricons/mmycampaign.svg";
import socialfacebook from "../assets/images/socialfacebook.svg";
import profileheader from "../assets/images/profileheader.svg";
import whiteviewcampaignicon from "../assets/images/sidebaricons/whiteviewcampaignicon.svg";
import whitenotifiction from "../assets/images/sidebaricons/whitenotifiction.svg";
import profileyoutube from "../assets/images/profileyoutube.svg";
import profilelinkedin from "../assets/images/profilelinkedin.svg";
import alert_circle from "../assets/images/alert_circle.svg";
import paiicon from "../assets/images/paiicon.svg";
import verifycontact from "../assets/images/verifycontact.png";
import emailverify from "../assets/images/emailverify.png";
import empty_table from "../assets/images/empty_table.png";
import profileuser from "../assets/images/profileuser.png";
import sliderimages from "../assets/images/sliderimages.svg";
import letsbrimwhitetest from "../assets/images/letsbrimwhitetest.png";
import blogcard from "../assets/images/blogcard.png";
import marquetinginovoice from "../assets/images/marquetinginovoice.png";
import Hero from "../assets/images/Hero.png";
import casefirst from "../assets/images/caseStudies/casefirst.png";
import playbutton from "../assets/images/caseStudies/playbutton.png";
import momocase from "../assets/images/caseStudies/momocase.jpg";
import momocover from "../assets/images/caseStudies/momocover.jpg";
import innsightimagedkava from "../assets/images/caseStudies/innsightimagedkava.jpg";
import coverdkava from "../assets/images/caseStudies/coverdkava.jpg";
import coverelemnt from "../assets/images/caseStudies/coverelemnt.jpg";
import innsightimageelemnt from "../assets/images/caseStudies/innsightimageelemnt.jpg";
import covervideoimage from "../assets/images/caseStudies/covervideoimage.jpg";
import homearrow from "../assets/images/home/homearrow.png";
import marquetingfooter from "../assets/images/marquetingfooter.png";
import caseimages from "../assets/images/caseimages.jpg";
import caseresult from "../assets/images/caseresult.jpg";

import serekocase from "../assets/images/caseStudies/serekocase.jpg";
import nuviblisscase from "../assets/images/caseStudies/nuviblisscase.jpg";
import kreocase from "../assets/images/caseStudies/kreocase.jpg";
import wellvartcase from "../assets/images/caseStudies/wellvartcase.jpg";
import totemcase from "../assets/images/caseStudies/totemcase.jpg";

// sidebar click icon
import bluedashbord from "../assets/images/sidebarclickicon/bluedashbord.svg";
import bluenewcampaign from "../assets/images/sidebarclickicon/bluenewcampaign.svg";
import blueinvoices from "../assets/images/sidebarclickicon/blueinvoices.svg";
import bluelogout from "../assets/images/sidebarclickicon/bluelogout.svg";
import bluenotifiction from "../assets/images/sidebarclickicon/bluenotifiction.svg";
import blueviewcampaign from "../assets/images/sidebarclickicon/blueviewcampaign.svg";
import blueprofile from "../assets/images/sidebarclickicon/blueprofile.svg";
import bluemoney from "../assets/images/sidebarclickicon/bluemoney.svg";
import mywithdrawal from "../assets/images/sidebarclickicon/mywithdrawal.svg";
import bluemycampaign from "../assets/images/sidebarclickicon/bluemycampaign.svg";

// Requested Impressions icon
import outlinefacebook from "../assets/images/requestedicon/outlinefacebook.svg";
import outlineinstagram from "../assets/images/requestedicon/outlineinstagram.svg";
import outlinelinkedin from "../assets/images/requestedicon/outlinelinkedin.svg";
import outlinexx from "../assets/images/twitter/outlinexx.png";
import outlineyoutube from "../assets/images/requestedicon/outlineyoutube.svg";

// form icon
import mobile from "../assets/images/formicons/mobile.svg";
import studio from "../assets/images/formicons/studio.svg";
import google from "../assets/images/formicons/google.svg";
import facebook from "../assets/images/formicons/facebook.svg";

// make payment icon
import instagram from "../assets/images/makepaymenticon/instagram.svg";
import squerx from "../assets/images/twitter/squerx.png";
import pfacebook from "../assets/images/makepaymenticon/pfacebook.svg";
import linkedin from "../assets/images/makepaymenticon/linkedin.svg";
import youtube from "../assets/images/makepaymenticon/youtube.svg";
import tophalf from "../assets/images/makepaymenticon/tophalf.svg";
import righthalf from "../assets/images/makepaymenticon/righthalf.svg";

// campaigncard icon
import reciept from "../assets/images/campaignCardicon/Reciept.svg";
import analyticsgraph from "../assets/images/campaignCardicon/analyticsgraph.svg";
import bellnotifications from "../assets/images/campaignCardicon/bellnotifications.svg";

// Impresions by Social Accounts icons

import Iimpresions_youtube from "../assets/images/impresionssocialaccount/Iimpresions_youtube.svg";
import impresion_instagram from "../assets/images/impresionssocialaccount/impresion_instagram.svg";
import impresions_facebook from "../assets/images/impresionssocialaccount/impresions_facebook.svg";
import impresions_linkedin from "../assets/images/impresionssocialaccount/impresions_linkedin.svg";
import impreson_twitter from "../assets/images/impresionssocialaccount/impreson_twitter.svg";

// withdrawaldetails  icon

import completed from "../assets/images/withdrawaldetailsicon/completed.svg";
import pending from "../assets/images/withdrawaldetailsicon/pending.svg";
import failed from "../assets/images/withdrawaldetailsicon/failed.svg";
import initiated from "../assets/images/withdrawaldetailsicon/initiated.svg";
import inprogress from "../assets/images/withdrawaldetailsicon/inprogress.svg";

// marqeting viewcampaigns icon

import mfacebookicon from "../assets/images/mviewcampaignsicon/mfacebookicon.svg";
import minstagramicon from "../assets/images/mviewcampaignsicon/minstagramicon.svg";
import mlinkedinicon from "../assets/images/mviewcampaignsicon/mlinkedinicon.svg";
import Mx from "../assets/images/twitter/Mx.png";
import myoutubeicon from "../assets/images/mviewcampaignsicon/myoutubeicon.svg";

// circle viewcampign icon

import circlefacebookicon from "../assets/images/circleicon/circlefacebookicon.svg";
import circleinstagramicon from "../assets/images/circleicon/circleinstagramicon.svg";
import circlelinkedinicon from "../assets/images/circleicon/circlelinkedinicon.svg";
import outlinex from "../assets/images/twitter/outlinex.png";
import circleyoutubeicon from "../assets/images/circleicon/circleyoutubeicon.svg";
import mothericon from "../assets/images/circleicon/mothericon.svg";
import whatsappnew from "../assets/images/circleicon/whatsappnew.png";

// profile edit icon
import editicon from "../assets/images/profilecardicon/editicon.svg";
import email from "../assets/images/profilecardicon/email.svg";
import location from "../assets/images/profilecardicon/location.svg";
import phonecall from "../assets/images/profilecardicon/phonecall.svg";
import profilephoto from "../assets/images/profilecardicon/profilephoto.svg";
import profileverify from "../assets/images/profilecardicon/profileverify.svg";

//Marqeter dashboard icons
import geteblemoney from "../assets/images/marqeting_dashoard/geteblemoney.svg";
import earnedmoney from "../assets/images/marqeting_dashoard/earnedmoney.svg";
import campaignopen from "../assets/images/marqeting_dashoard/campaignopen.svg";
import campaignapplid from "../assets/images/marqeting_dashoard/campaignapplid.svg";
import camapigninprogress from "../assets/images/marqeting_dashoard/camapigninprogress.svg";
import camapigncompleted from "../assets/images/marqeting_dashoard/camapigncompleted.svg";
import brandaboutus from "../assets/images/marqeting_dashoard/brandaboutus.jpg";
import marqeterabout from "../assets/images/marqeting_dashoard/marqeterabout.jpg";
import ourimages from "../assets/images/marqeting_dashoard/ourimages.jpg";
import withdrawimg from "../assets/images/marqeting_dashoard/withdrawimg.png";
import rightlogoadvertiser from "../assets/images/marqeting_dashoard/rightlogoadvertiser.png";
import rightlogomarqeter from "../assets/images/marqeting_dashoard/rightlogomarqeter.png";


// dashboard icons
import abanner from "../assets/images/advertiser_dashboard/abanner.svg";
import audiencereach from "../assets/images/advertiser_dashboard/audiencereach.svg";
import campaignscompleted from "../assets/images/advertiser_dashboard/campaignscompleted.svg";
import campaignscreated from "../assets/images/advertiser_dashboard/campaignscreated.svg";
import campaignsinprogress from "../assets/images/advertiser_dashboard/campaignsinprogress.svg";
import completedimpressions from "../assets/images/advertiser_dashboard/completedimpressions.svg";
import inprogressimpressions from "../assets/images/advertiser_dashboard/inprogressimpressions.svg";
import marqetersreach from "../assets/images/advertiser_dashboard/marqetersreach.svg";
import requestedimpressions from "../assets/images/advertiser_dashboard/requestedimpressions.svg";

// landing pages
import incoming_call_icon from "../assets/images/landingpages/incoming_call_icon.svg";
import landing_gmail from "../assets/images/landingpages/landing_gmail.svg";
import landing_location from "../assets/images/landingpages/landing_location.svg";
import landing_frame from "../assets/images/landingpages/landing_frame.png";
import section1 from "../assets/images/home/section1.jpg";
import section2 from "../assets/images/home/section2.png";
import section21 from "../assets/images/home/section21.png";
import sectionwork from "../assets/images/home/sectionwork.png";
import payal from "../assets/images/home/payal.jpeg";
import riya from "../assets/images/influencers/riya.webp";
import sushma from "../assets/images/influencers/sushma.webp";
import ashianand from "../assets/images/influencers/ashianand.webp";
import ayushsmita from "../assets/images/influencers/ayushsmita.webp";
import harsh from "../assets/images/influencers/harsh.webp";
import muskangupta from "../assets/images/influencers/muskangupta.webp";
import nilakshi from "../assets/images/influencers/nilakshi.webp";
import piyanshi from "../assets/images/influencers/piyanshi.webp";
import rajrupsaha from "../assets/images/influencers/rajrupsaha.webp";
import shristi from "../assets/images/influencers/shristi.webp";
import sreyabiswas from "../assets/images/influencers/sreyabiswas.webp";
import shrutisharma from "../assets/images/influencers/shrutisharma.webp";
import tamzina_khan from "../assets/images/influencers/tamzina_khan.webp";
import samarthahirrao from "../assets/images/influencers/samarthahirrao.webp";
import kajal from "../assets/images/influencers/kajal.webp";
import charu from "../assets/images/influencers/charu.webp";
import nehanirwal from "../assets/images/influencers/nehanirwal.webp";
import anita from "../assets/images/influencers/anita.webp";
import naveen from "../assets/images/influencers/naveen.webp";
import spana from "../assets/images/influencers/spana.webp";
import mrmrsdeb from "../assets/images/influencers/mrmrsdeb.webp";
import mira from "../assets/images/influencers/mira.webp";
import debanshimadhurima from "../assets/images/influencers/debanshimadhurima.webp";
import mhimakavi from "../assets/images/influencers/mhimakavi.webp";
import Sunitee from "../assets/images/influencers/Sunitee.webp";
import ishabansall from "../assets/images/influencers/ishabansall.webp";
import muskanghambir from "../assets/images/influencers/muskanghambir.webp";
import fagunimadhiani from "../assets/images/influencers/fagunimadhiani.webp";

import Akshat from "../assets/images/influencers/Akshat.jpg";
import Arjun from "../assets/images/influencers/Arjun.jpg";
import Hemant from "../assets/images/influencers/Hemant.jpg";
import Divya from "../assets/images/influencers/Divya.jpg";
import Florence from "../assets/images/influencers/Florence.jpg";
import Javed from "../assets/images/influencers/Javed.jpg";
import Pranjali from "../assets/images/influencers/Pranjali.jpg";
import cheshta from "../assets/images/influencers/cheshta.jpg";
import KajalI from "../assets/images/influencers/Kajal.jpg";
import Vaishali from "../assets/images/influencers/Vaishali.jpg";
import Shivali from "../assets/images/influencers/Shivali.jpg";
import saba from "../assets/images/influencers/saba.webp";
import Satarupal from "../assets/images/influencers/Satarupal.webp";
import Priyanka from "../assets/images/influencers/Priyanka.jpg";
import harshI from "../assets/images/influencers/harshsingh.webp";
import laviyadav from "../assets/images/influencers/laviyadav.heic";

// why to work----

import proven from "../assets/images/whytowork/proven.png";
import tailored from "../assets/images/whytowork/tailored.png";
import quality from "../assets/images/whytowork/quality.png";
import data from "../assets/images/whytowork/data.png";
import cost from "../assets/images/whytowork/cost.png";
import client from "../assets/images/whytowork/client.png";
import campaign from "../assets/images/whytowork/campaign.png";
import innovative from "../assets/images/whytowork/innovative.png";
import niche from "../assets/images/whytowork/niche.png";
import enhanced from "../assets/images/whytowork/enhanced.png";
import whytowork from "../assets/images/whytowork/whytowork.webp";
import whytoworkj from "../assets/images/whytowork/whytoworkj.jpg";
import client1 from "../assets/images/whytowork/client1.png";
import client2 from "../assets/images/whytowork/client2.png";
import client3 from "../assets/images/whytowork/client3.png";
import client4 from "../assets/images/whytowork/client4.png";



import solution1 from "../assets/images/solutions/solution1.png";
import solution2 from "../assets/images/solutions/solution2.png";
import solution3 from "../assets/images/solutions/solution3.png";
import solution4 from "../assets/images/solutions/solution4.png";
import solution5 from "../assets/images/solutions/solution5.png";
import solution6 from "../assets/images/solutions/solution6.png";
import solution7 from "../assets/images/solutions/solution7.png";
import solution8 from "../assets/images/solutions/solution8.png";
import solution9 from "../assets/images/solutions/solution9.png";


// blog imges

import blog1 from "../assets/images/blogimages/blog1.jpg";
import blog2 from "../assets/images/blogimages/blog2.jpg";
import blog3 from "../assets/images/blogimages/blog3.jpg";
import blog4 from "../assets/images/blogimages/blog4.jpg";
import blog5 from "../assets/images/blogimages/blog5.jpg";
import blog6 from "../assets/images/blogimages/blog6.jpg";
import blog7 from "../assets/images/blogimages/blog7.jpg";
import blog8 from "../assets/images/blogimages/blog8.jpg";
import blog9 from "../assets/images/blogimages/blog9.jpg";
import blog10 from "../assets/images/blogimages/blog10.jpg";
import blog11 from "../assets/images/blogimages/blog11.jpg";
import blog12 from "../assets/images/blogimages/blog12.jpg";
import blog13 from "../assets/images/blogimages/blog13.jpg";
import blog14 from "../assets/images/blogimages/blog14.jpeg";
import blog15 from "../assets/images/blogimages/blog15.jpg";
import blog16 from "../assets/images/blogimages/blog16.jpg";
import blog17 from "../assets/images/blogimages/blog17.jpg";
import blog18 from "../assets/images/blogimages/blog18.jpg";
import blog19 from "../assets/images/blogimages/blog19.jpg";
import blog20 from "../assets/images/blogimages/blog20.jpg";
import blog21 from "../assets/images/blogimages/blog21.jpg";
import blog22 from "../assets/images/blogimages/blog22.jpg";
import blog23 from "../assets/images/blogimages/blog23.jpg";
import blog24 from "../assets/images/blogimages/blog24.jpg";


// brands icons

import brand1 from "../assets/images/brands/brand1.png";
import brand2 from "../assets/images/brands/brand2.png";
import brand3 from "../assets/images/brands/brand3.png";
import brand4 from "../assets/images/brands/brand4.png";
import brand5 from "../assets/images/brands/brand5.png";
import brand6 from "../assets/images/brands/brand6.png";
  
// career

import achievement from "../assets/images/career/medal.png";
import holiday from "../assets/images/career/calendar.png";
import link from "../assets/images/career/people.png";
import wallet from "../assets/images/career/wallet.png";
import workingTime from "../assets/images/career/clock.png";



// brands logo icons

import dkava from "../assets/images/brandLogo/dkava.png";
import gopappe from "../assets/images/brandLogo/gopappe.png";
import elemnt from "../assets/images/brandLogo/elemnt.png";
import gns from "../assets/images/brandLogo/gns.png";
import kreo from "../assets/images/brandLogo/kreo.png";
import sheikchang from "../assets/images/brandLogo/sheikchang.png";
import sereko from "../assets/images/brandLogo/sereko.png";
import momomia from "../assets/images/brandLogo/momomia.png";
import mallufarms from "../assets/images/brandLogo/mallufarm.png";
import yellowball from "../assets/images/brandLogo/yellowball.png";
import nuvibliss from "../assets/images/brandLogo/nuvibliss.png";
import gxn from "../assets/images/brandLogo/gxn.jpg";
import delizia from "../assets/images/brandLogo/delizia.jpg";
import carefreak from "../assets/images/brandLogo/carefreak.jpg";
import brandfuzz from "../assets/images/brandLogo/brandfuzz.png";
import auravedic from "../assets/images/brandLogo/auravedic.png";
import plantbro from "../assets/images/brandLogo/plantbro.png";
import choks from "../assets/images/brandLogo/choks.jpg";
import cupji from "../assets/images/brandLogo/cupji.jpg";
import unspoiled from "../assets/images/brandLogo/unspoiled.jpg";
import totem from "../assets/images/brandLogo/totem.jpg";
import wellvart from "../assets/images/brandLogo/wellvart.jpg";
import zinklondon from "../assets/images/brandLogo/zinklondon.jpg";
import gree from "../assets/images/brandLogo/gree.png";
import dkavanew from "../assets/images/whytowork/dkavanew.png";
import greefeedback from "../assets/images/whytowork/greefeedback.png";
import kreonew from "../assets/images/whytowork/kreonew.png";

import amp from "../assets/images/brandLogo/amp.png";
import denver from "../assets/images/brandLogo/denver.jpg";
import fytika from "../assets/images/brandLogo/fytika.jpg";
import kewah from "../assets/images/brandLogo/kewah.png";
import NATURENECTAR from "../assets/images/brandLogo/NATURENECTAR.jpg";
import NIYAMAYA from "../assets/images/brandLogo/NIYAMAYA.png";
import prezerve from "../assets/images/brandLogo/prezerve.jpg";
import setu from "../assets/images/brandLogo/setu.jpg";
import TOUCHE from "../assets/images/brandLogo/TOUCHE.png";
import VAIDRISHI from "../assets/images/brandLogo/VAIDRISHI.jpg";

import bioderma from "../assets/images/brandLogo/bioderma.png";
import otrix from "../assets/images/brandLogo/otrix.png";
import healther from "../assets/images/brandLogo/healther.png";
import Inarabrand from "../assets/images/brandLogo/inaranewbrand.png";
import N4N from "../assets/images/brandLogo/N4N.avif";
import tajhhair from "../assets/images/brandLogo/tajhhair.png";
import dennison from "../assets/images/brandLogo/dennison.png";
import dailyscoop from "../assets/images/brandLogo/dailyscoop.png";
import fiore from "../assets/images/brandLogo/fiore.png";
import foreverlivingbrand from "../assets/images/brandLogo/foreverlivingbrand.png";
// import happy_cultures_logo_2 from "../assets/images/brandLogo/happy_cultures_logo_2.png";
import lakme from "../assets/images/brandLogo/lakme.png";
import mars from "../assets/images/brandLogo/mars.png";
import cambaytiger from "../assets/images/brandLogo/cambaytiger.png";
import indeekoss from "../assets/images/brandLogo/indeekoss.png";
import ember from "../assets/images/brandLogo/ember.png";
import yplayz from "../assets/images/brandLogo/yplayz.png";
import beauty from "../assets/images/brandLogo/beauty.png";
import kapiva from "../assets/images/brandLogo/kapiva.png";
import karrot from "../assets/images/brandLogo/karrot.png";
import awearbeauty from "../assets/images/brandLogo/awearbeauty.png";
import bmore from "../assets/images/brandLogo/bmore.png";
import boletobdapav from "../assets/images/brandLogo/boletobdapav.png";
import dvija from "../assets/images/brandLogo/dvija.png";
import estelar from "../assets/images/brandLogo/estelar.png";
import feelmetown from "../assets/images/brandLogo/feelmetown.png";
import fixderma from "../assets/images/brandLogo/fixderma.png";
import globlenow from "../assets/images/brandLogo/globlenow.png";
import glowrico from "../assets/images/brandLogo/glowrico.png";
import leaf from "../assets/images/brandLogo/leaf.png";
import superbottom from "../assets/images/brandLogo/superbottom.png";
import tacbrand from "../assets/images/brandLogo/tacbrand.png";


// influencers offer icons

import influencer1 from "../assets/images/infuencersoffer/influencer1.png";
import influencer2 from "../assets/images/infuencersoffer/influencer2.png";
import influencer3 from "../assets/images/infuencersoffer/influencer3.png";
import influencer4 from "../assets/images/infuencersoffer/influencer4.png";
import influencer5 from "../assets/images/infuencersoffer/influencer5.png";
import influencer6 from "../assets/images/infuencersoffer/influencer6.png";

// gopape images
import muskan from "../assets/images/gopapepic/muskan.jpg";
import gunjan from "../assets/images/gopapepic/gunjan.jpg";
import hanshika from "../assets/images/gopapepic/hanshika.jpg";
import shuriti from "../assets/images/gopapepic/shuriti.jpg";
import aani from "../assets/images/gopapepic/aani.jpg";
import aashi from "../assets/images/gopapepic/aashi.jpg";
import chalwahankhaatehai from "../assets/images/gopapepic/chalwahankhaatehai.jpg";
import foodieboy from "../assets/images/gopapepic/foodieboy.jpg";
import foodsearch from "../assets/images/gopapepic/foodsearch.jpg";
import foodtravls from "../assets/images/gopapepic/foodtravls.jpg";
import hazel from "../assets/images/gopapepic/hazel.jpg";
import ishita from "../assets/images/gopapepic/ishita.jpg";
import jessmeet from "../assets/images/gopapepic/jessmeet.jpg";
import mayank from "../assets/images/gopapepic/mayank.jpg";
import vegpic from "../assets/images/gopapepic/vegpic.jpg";
import tinyshree from "../assets/images/gopapepic/tinyshree.jpg";
import pr003 from "../assets/images/gopapepic/pr003.jpg";
import prachi from "../assets/images/gopapepic/prachi.jpg";
import foodjunction from "../assets/images/gopapepic/foodjunction.jpg";
import sanyavig from "../assets/images/gopapepic/sanyavig.jpg";

import disthi from "../assets/images/momomia/disthi.jpg";
import expertview from "../assets/images/momomia/expertview.jpg";
import foodntales from "../assets/images/momomia/foodntales.jpg";
import gourav from "../assets/images/momomia/gourav.jpg";
import kavita from "../assets/images/momomia/kavita.jpg";
import mahi from "../assets/images/momomia/mahi.jpg";
import mybutter from "../assets/images/momomia/mybutter.jpg";
import poonam from "../assets/images/momomia/poonam.jpg";

// carefreak images
import caseaanknsha from "../assets/images/carefreak/caseaanknsha.webp";
import casedarsthi from "../assets/images/carefreak/casedarsthi.webp";
import casedishii from "../assets/images/carefreak/casedishii.webp";
import casesoniasah from "../assets/images/carefreak/casesoniasah.webp";
import cashpurvising from "../assets/images/carefreak/cashpurvising.webp";

// delizia images
import casebuttrybros from "../assets/images/delizia/casebuttrybros.webp";
import casekniska from "../assets/images/delizia/casekniska.webp";
import caseleena from "../assets/images/delizia/caseleena.webp";
import casenikita from "../assets/images/delizia/casenikita.webp";
import caseshardha from "../assets/images/delizia/caseshardha.webp";

// yellow bowl images
import casedilsefoodi from "../assets/images/yellowbowl/casedilsefoodi.webp";
import casefoodifoolz from "../assets/images/yellowbowl/casefoodifoolz.webp";
import casemanya from "../assets/images/yellowbowl/casemanya.webp";
import casepiku from "../assets/images/yellowbowl/casepiku.webp";
import caseyummypara from "../assets/images/yellowbowl/caseyummypara.webp";

// dkava images
import akshitarora from "../assets/images/dkava/akshitarora.jpg";
import littleflower from "../assets/images/dkava/littleflower.jpg";
import mnyanka from "../assets/images/dkava/mnyanka.jpg";
import tastingglitch from "../assets/images/dkava/tastingglitch.jpg";
// auravedic images
import caseishabansal from "../assets/images/auravedic/caseishabansal.webp";
import casemusskyy from "../assets/images/auravedic/casemusskyy.jpg";

import delzia from "../assets/images/caseStudies/delzia.jpg";
import zinklondon2 from "../assets/images/caseStudies/zinklondon2.jpg";
import auravediccover from "../assets/images/caseStudies/auravedic.jpg";
import carefrea from "../assets/images/caseStudies/carefrea.jpg";
import yellowbowl from "../assets/images/caseStudies/yellowbowl.jpg";
import zxn from "../assets/images/caseStudies/zxn.jpg";
import unspoild from "../assets/images/caseStudies/unspoild.jpg";
import greecover from "../assets/images/caseStudies/greecover.jpg";



import auravedicphase1result from "../assets/images/results/auravedicphase1result.jpg";
import deliziaphase1result from "../assets/images/results/deliziaphase1result.jpg";
import gxnphase1 from "../assets/images/results/gxnphase1.jpg";
import tybphase1result from "../assets/images/results/tybphase1result.jpg";
import zinklondon1result from "../assets/images/results/zinklondon1result.jpg";
import carefreakresults from "../assets/images/results/carefreakresults.jpg";



import asshiaaanandtyb from "../assets/images/tybphase2/asshiaaanandtyb.jpg";
import dati8ngfoodtyb from "../assets/images/tybphase2/dati8ngfoodtyb.jpg";
import foodialhocityb from "../assets/images/tybphase2/foodialhocityb.jpg";
import himanityb from "../assets/images/tybphase2/himanityb.jpg";
import itssellofficalTYB from "../assets/images/tybphase2/itssellofficalTYB.jpg";
import jasmeettyb from "../assets/images/tybphase2/jasmeettyb.jpg";
import manyatyb from "../assets/images/tybphase2/manyatyb.jpg";
import myplattertyb from "../assets/images/tybphase2/myplattertyb.jpg";
import pr003tyb from "../assets/images/tybphase2/pr003tyb.jpg";
import thebirdtyb from "../assets/images/tybphase2/thebirdtyb.jpg";


export const Asshiaaanandtyb = asshiaaanandtyb;
export const Dati8ngfoodtyb = dati8ngfoodtyb;
export const Foodialhocityb = foodialhocityb;
export const Himanityb = himanityb;
export const ItssellofficalTYB = itssellofficalTYB;
export const Jasmeettyb = jasmeettyb;
export const Manyatyb = manyatyb;
export const Myplattertyb = myplattertyb;
export const Pr003tyb = pr003tyb;
export const Thebirdtyb = thebirdtyb;
export const Greecover = greecover;


export const AkshatI = Akshat;
export const ArjunI = Arjun;
export const HemantI = Hemant;
export const DivyaI = Divya;
export const FlorenceI = Florence;
export const JavedI = Javed;
export const PranjaliI = Pranjali;
export const Cheshta = cheshta;
export const Kajal_I = KajalI;
export const VaishaliI = Vaishali;
export const ShivaliI = Shivali;
export const Saba = saba;
export const SatarupalI = Satarupal;
export const PriyankaI = Priyanka;
export const Harsh_I = harshI;
export const Laviyadav = laviyadav;


export const InaraBrand = Inarabrand;
export const N4NBrand = N4N;
export const Tajhhair = tajhhair;
export const Dennison = dennison;
export const Dailyscoop = dailyscoop;
export const Fiore = fiore;
export const Foreverlivingbrand = foreverlivingbrand;
// export const Happy = happy_cultures_logo_2;
export const Lakme = lakme;
export const Mars = mars;
export const Cambaytiger = cambaytiger;
export const Indeekoss = indeekoss;
export const Ember = ember;
export const Yplayz = yplayz;
export const Beauty = beauty;

export const Kapiva = kapiva;
export const Karrot = karrot;
export const Awearbeauty = awearbeauty;
export const Bmore = bmore;
export const Boletobdapav = boletobdapav;
export const Dvija = dvija;
export const Estelar = estelar;
export const Feelmetown = feelmetown;
export const Fixderma = fixderma;
export const Globlenow = globlenow;
export const Glowrico = glowrico;
export const Leaf = leaf;
export const Superbottom = superbottom;
export const Tacbrand = tacbrand;



export const Auravedicphase1result = auravedicphase1result;
export const Deliziaphase1result = deliziaphase1result;
export const Gxnphase1 = gxnphase1;
export const TYybphase1result = tybphase1result;
export const Zinklondon1result = zinklondon1result;
export const Carefreakresults = carefreakresults;

export const Achievement = achievement;
export const Holiday = holiday;
export const Link = link;
export const Wallet = wallet;
export const WorkingTime = workingTime;

export const Delzia = delzia;
export const Zinklondon2 = zinklondon2;
export const AuravedicCover = auravediccover;
export const Carefrea = carefrea;
export const Yellowbowl = yellowbowl;
export const Zxn = zxn;
export const Unspoild = unspoild;



export const Casemusskyy = casemusskyy;
export const Caseishabansal = caseishabansal;
export const Casedilsefoodi = casedilsefoodi;
export const Casefoodifoolz = casefoodifoolz;
export const Casemanya = casemanya;
export const Casepiku = casepiku;
export const Caseyummypara = caseyummypara;


export const Casebuttrybros = casebuttrybros;
export const Casekniska = casekniska;
export const Caseleena = caseleena;
export const Casenikita = casenikita;
export const Caseshardha = caseshardha;

export const Caseaanknsha = caseaanknsha;
export const Casedarsthi = casedarsthi;
export const Casedishii = casedishii;
export const Casesoniasah = casesoniasah;
export const Cashpurvising = cashpurvising;

export const Akshitarora = akshitarora;
export const Littleflower = littleflower;
export const Mnyanka = mnyanka;
export const Tastingglitch = tastingglitch;
export const Disthi = disthi;
export const Expertview = expertview;
export const Foodntales = foodntales;
export const Gourav = gourav;
export const Kavita = kavita;
export const Mahi = mahi;
export const Mybutter = mybutter;
export const Poonam = poonam;
export const Fagunimadhiani = fagunimadhiani;
export const Muskanghambir = muskanghambir;
export const Ishabansall = ishabansall;
export const SuniTee = Sunitee;
export const Mhimakavi = mhimakavi;
export const Nuviblisscase = nuviblisscase;
export const Totemcase = totemcase;
export const Wellvartcase = wellvartcase;
export const Kreocase = kreocase;
export const Serekocase = serekocase;

export const Coverelemnt = coverelemnt;
export const Coverdkava = coverdkava;
export const Dkava = dkava;
export const Gopappe = gopappe;
export const Elemntt = elemnt;
export const Gujaralandsons = gns;
export const Kreo = kreo;
export const Kreonew = kreonew;
export const Sheikchang = sheikchang;
export const Serekoonew = sereko;
export const Momomia = momomia;
export const Mallufarms = mallufarms;
export const Yellowball = yellowball;
export const Nuvibliss = nuvibliss;
export const Choks = choks;
export const Cupji = cupji;
export const Unspoiled = unspoiled;
export const Totem = totem;
export const Wellvart = wellvart;
export const Zinklondon = zinklondon;
export const Plantbro = plantbro;
export const Auravedic = auravedic;
export const Brandfuzz = brandfuzz;
export const Carefreak = carefreak;
export const Delizia = delizia;
export const Gxn = gxn;


export const Amp = amp;
export const Fytika = fytika;
export const Denver = denver;
export const Kewah = kewah;
export const nATURENECTAR = NATURENECTAR;
export const Niyama = NIYAMAYA;
export const Prezerve = prezerve;
export const Setu = setu;
export const Touche = TOUCHE;
export const Vaidrishi = VAIDRISHI;
export const Healther = healther;
export const Otrix = otrix;
export const Bioderma = bioderma;


export const Muskan = muskan;
export const Foodjunction = foodjunction;
export const Sanyavig = sanyavig;
export const Gunjan = gunjan;
export const Hanshika = hanshika;
export const Shuriti = shuriti;
export const Aani = aani;
export const Aashi = aashi;
export const Chalwahankhaatehai = chalwahankhaatehai;
export const Foodieboy = foodieboy;
export const Foodsearch = foodsearch;
export const Foodtravls = foodtravls;
export const Hazel = hazel;
export const Ishita = ishita;
export const Jessmeet = jessmeet;
export const Mayank = mayank;
export const Vegpic = vegpic;
export const Tinyshree = tinyshree;
export const Pr003 = pr003;
export const Prachi = prachi;





export const Brand1 = brand1;
export const Brand2 = brand2;
export const Brand3 = brand3;
export const Brand4 = brand4;
export const Brand5 = brand5;
export const Brand6 = brand6;
export const CAseimages = caseimages;
export const Caseresult = caseresult;
export const Momocase = momocase;
export const Momocover = momocover;
export const Innsightimageelemnt = innsightimageelemnt;
export const Innsightimagedkava = innsightimagedkava;

export const Greefeedback = greefeedback;
export const Gree = gree;
export const Dkavanew = dkavanew;
export const Client1 = client1;
export const Client2 = client2;
export const Client3 = client3;
export const Client4 = client4;
export const Homearrow = homearrow;


export const Influencer1 = influencer1;
export const Influencer2 = influencer2;
export const Influencer3 = influencer3;
export const Influencer4 = influencer4;
export const Influencer5 = influencer5;
export const Influencer6 = influencer6;

export const Blog1 = blog1;
export const Blog2 = blog2;
export const Blog3 = blog3;
export const Blog4 = blog4;
export const Blog5 = blog5;
export const Blog6 = blog6;
export const Blog7 = blog7;
export const Blog8 = blog8;
export const Blog9 = blog9;
export const Blog10 = blog10;
export const Blog11 = blog11;
export const Blog12 = blog12;
export const Blog13 = blog13;
export const Blog14 = blog14;
export const Blog15 = blog15;
export const Blog16 = blog16;
export const Blog17 = blog17;
export const Blog18 = blog18;
export const Blog19 = blog19;
export const Blog20 = blog20;
export const Blog21 = blog21;
export const Blog22 = blog22;
export const Blog23 = blog23;
export const Blog24 = blog24;
export const Caseimages = casefirst;
export const Playbutton = playbutton;
export const Covervideoimage = covervideoimage;

export const Solution1 = solution1;
export const Solution2 = solution2;
export const Solution3 = solution3;
export const Solution4 = solution4;
export const Solution5 = solution5;
export const Solution6 = solution6;
export const Solution7 = solution7;
export const Solution8 = solution8;
export const Solution9 = solution9;

// why to work----
export const Proven = proven;
export const Tailored = tailored;
export const Quality = quality;
export const Data = data;
export const Cost = cost;
export const Client = client;
export const Campaign = campaign;
export const Innovative = innovative;
export const Niche = niche;
export const Enhanced = enhanced;
export const Whytowork = whytowork;
export const Whytoworkj = whytoworkj;

// influencers images
export const Debanshimadhurima = debanshimadhurima;
export const Mira = mira;
export const Mrmrsdeb = mrmrsdeb;
export const Spana = spana;
export const Naveen = naveen;
export const Anita = anita;
export const Nehanirwal = nehanirwal;
export const Charu = charu;
export const Riya = riya;
export const Sushma = sushma;
export const Ashianand = ashianand;
export const Ayushsmita = ayushsmita;
export const Harsh = harsh;
export const Muskangupta = muskangupta;
export const Nilakshi = nilakshi;
export const Piyanshi = piyanshi;
export const Rajrupsaha = rajrupsaha;
export const Shristi = shristi;
export const Sreyabiswas = sreyabiswas;
export const Shrutisharma = shrutisharma;
export const Tamzinakhan = tamzina_khan;
export const Samarthahirrao = samarthahirrao;
export const Kajal = kajal;






export const Payal = payal;
export const Sectionwork = sectionwork;
export const Section21 = section21;
export const Section2 = section2;
export const Section1 = section1;
export const Letsbrimwhitetest = letsbrimwhitetest;
export const IncomingCall = incoming_call_icon;
export const LandingGmail = landing_gmail;
export const LandingLoction = landing_location;
export const LandingFrame = landing_frame;
export const LandingBanner = Hero;
export const MarquetingInovoice = marquetinginovoice;
export const MarquetingFooter = marquetingfooter;
export const AboutusStatic = brandaboutus;
export const MarqeterAbout = marqeterabout;
export const AdvertiserAbout = ourimages;
export const WithdrawImg = withdrawimg;
export const RightlogoMarqeter = rightlogomarqeter;
export const RightlogoaAvertiser = rightlogoadvertiser;
// dashboard icons

export const Abanner = abanner;
export const AudienceReach = audiencereach;
export const CampaignCompleted = campaignscompleted;
export const CampaignCreated = campaignscreated;
export const CampaignInProgress = campaignsinprogress;
export const CompletedImpression = completedimpressions;
export const InProgressImpression = inprogressimpressions;
export const MarqueterReach = marqetersreach;
export const RequestedImpression = requestedimpressions;
export const ProfileHeader = profileheader;
export const emptytable = empty_table;
export const UserProfile = profileuser;
export const SliderImages = sliderimages;

export const GetebleMoney = geteblemoney;
export const EarnedMoney = earnedmoney;
export const CampaignOpen = campaignopen;
export const CampaignApplid = campaignapplid;
export const CamapignInprogress = camapigninprogress;
export const CamapignCompleted = camapigncompleted;

// Impresions by Social Accounts icons

export const ImpresionsYoutube = Iimpresions_youtube;
export const ImpresionInstagram = impresion_instagram;
export const ImpresionsFacebook = impresions_facebook;
export const ImpresionsLinkedin = impresions_linkedin;
export const ImpresonTwitter = impreson_twitter;
export const alert_Icon = alert_circle;
export const PaiIcon = paiicon;
export const VerifyContact = verifycontact;
export const EmailVerify = emailverify;
export const BlogCard = blogcard;

// sidebar click icon
export const BlueDashboard = bluedashbord;
export const BlueNewCampaign = bluenewcampaign;
export const BlueInvoices = blueinvoices;
export const BlueLogout = bluelogout;
export const BlueNotification = bluenotifiction;
export const BlueViewCampaign = blueviewcampaign;
export const BlueProfile = blueprofile;
export const BlueMyWithdrawal = mywithdrawal;
export const BlueMoney = bluemoney;
export const BlueMyCampaign = bluemycampaign;

// profile edit icon
export const ProfileEditSmallIcon = editicon;
export const ProfileMessageIcon = email;
export const Location = location;
export const ProfileCallIcon = phonecall;
export const ProfileEditIcon = profilephoto;
export const ProfileVerifyicon = profileverify;
export const Mmywithdrwal = mmywithdrwal;
export const Mmymoney = mmymoney;
export const Mmycampaign = mmycampaign;
export const SocialFacebook = socialfacebook;
export const ProfileLinkedin = profilelinkedin;
export const ProfileYoutube = profileyoutube;

// circle viewcampign icon

export const CircleYoutube = circleyoutubeicon;
export const CircleLinkedin = circlelinkedinicon;
export const CircleTwitter = outlinex;
export const CircleInstagram = circleinstagramicon;
export const CircleFacebook = circlefacebookicon;
export const MotherIcon = mothericon;
export const FilterIcon = filtericon;
export const CloudUpload = cloudupload;
export const InsertLink = insertlinkicon;
export const TabIcon = tabicon;
export const AddmoreIcon = addmoreicon;

// withdrawaldetails  icon

export const Whatsappnew = whatsappnew;
export const Completed = completed;
export const Pending = pending;
export const Failed = failed;
export const Initiated = initiated;
export const InProgress = inprogress;
export const MarqetingLogo = marqetinglogo;

// Requested Impressions icon
export const OutlineFacebook = outlinefacebook;
export const OutlineInstagram = outlineinstagram;
export const OutlineLinkedIn = outlinelinkedin;
export const OutlineTwitter = outlinexx;
export const OutlineYoutube = outlineyoutube;

// export sidebar icons
export const Dashboard = dashboard;
export const Invoices = invoices;
export const MarqetingLogoSidebar = marqetinglogosidebar;
export const MarqetingSmallLogo = marqetingsmalllogo;
export const Newcampaigns = newcampaigns;
export const Notification = notification;
export const Profile = profile;
export const UserLogout = userlogout;
export const SidebarMenu = sidebarmenu;
export const ViewCampaigns = viewcampaigns;
export const NotifictionNavbar = notifictionnavbar;
export const WhiteViewcampaignIcon = whiteviewcampaignicon;
export const WhiteNotifiction = whitenotifiction;

// form icon

export const Mobile = mobile;
export const Studio = studio;
export const Google = google;
export const Facebook = facebook;

// make payment icon

export const Instagram = instagram;
export const Youtube = youtube;
export const Linkedin = linkedin;
export const Twitter = squerx;
export const Pfacebook = pfacebook;
export const Righthalf = tophalf;
export const Tophalf = righthalf;

// campaigncard icon
export const Reciept = reciept;
export const AnalyticsGraph = analyticsgraph;
export const BellNotifications = bellnotifications;

// marqeting viewcampaigns icon

export const MfacebookIcon = mfacebookicon;
export const MinstagramIcon = minstagramicon;
export const MlinkedinIcon = mlinkedinicon;
export const MtwitterIcon = Mx;
export const MyoutubeIcon = myoutubeicon;
