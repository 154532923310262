import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Caseimages,
  Caseresult,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
  Zinklondon1result,
  Zinklondon2,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const ZinkLondonCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d78",
    title:
      "Using Creators to Produce UGC (User-Generated Content) At Scale for Zink London",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:"Here’s how Marqeting managed to help Zink London in getting maximum reach and engagement for their influencer campaigns.",
    caseType: "BEAUTY & PERSONAL CARE",

    content: [
      {
        heading: "The Challenge",
        subcontent:
          "Zink London is a leading fashion retailer brand who has an incredible eye for what's next in fashion. They serve customers at 450 stores across 130+ cities in India.",
        content:
          "As traditional advertising channels struggle to get hold of people’s attention, the client wanted to pursue influencer marketing to drive maximum awareness and recognition.",
        content2:
          "The Met Gala and red-carpet reviews are prime examples of visibility and influence that fashion events and celebrities hold in the industry. In such a crowded market Zink London decided to tap influencer marketing to catch hold of increased brand visibility and product coverage.",
      },

      {
        heading: "The Solution and Execution",

        subcontent:
          "Zink London partnered with Marqeting to screen 50 user-generated content (UGC) creators who would create high-quality, brand-focused content ft. their apparel collection. The creators produced high-quality UGC videos showcasing the brand's trendiest collection.",
        content:
          "They had shot themselves getting dressed/ready while wearing Zink London’s dresses. From styling tips to fashion hauls, the videos had authentic and relatable perspectives on the brand's products.",
   

        content2:
          "By investing in Instagram creators, Zink London had a lot to gain. For one, they now had a ton of exciting and engaging content which they could re-purpose for performance marketing and running ads. After all, it’s all UGC!",
    
        content3:
          "Just as important, Zink London took creators under its wing, believed in them, and helped them succeed. The results of their partnership with Marqeting had left a positive impact on the client’s UGC approach one way or another.",
      },
      {
        heading: "The Results",

        subcontent:
          "Rather than coming across as blatant advertising, the UGC felt like honest recommendations and styling inspiration from trusted sources. Audiences appreciated viewing the dresses styled in realistic, everyday settings by people they could relate to.",
        content:
          "The campaign resulted in over diverse high-quality reels and video stories. Zink London used this unfiltered UGC content to provide personalized advertising experiences across social media and saw a higher engagement on Instagram.",

          image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          imageUrl:
            "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",

      },

    
    ],
   
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Zinklondon2}
                  alt={data.title}
                />
                {data.description && <p>{data.description}</p>}
                {/* <p>{data.description}</p> */}
                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.headingContent && (
                          <h1 style={{ marginTop: "10px" }}>
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}

                        {section.subHeadingnewe && (
                          <p className="subHeadingnewe">
                            {section.subHeadingnewe}
                          </p>
                        )}

                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Zinklondon1result}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && <p>{section.list1}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                          </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZinkLondonCaseStudiesInner;
