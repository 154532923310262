import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  Aani,
  Aashi,
  Akshitarora,
  CAseimages,
  Caseimages,
  Caseresult,
  Chalwahankhaatehai,
  Coverelemnt,
  Covervideoimage,
  Foodieboy,
  Foodjunction,
  Foodsearch,
  Foodtravls,
  Gunjan,
  Hanshika,
  Hazel,
  Innsightimagedkava,
  Innsightimageelemnt,
  Ishita,
  Jessmeet,
  Littleflower,
  Mayank,
  Mnyanka,
  Muskan,
  Playbutton,
  Pr003,
  Prachi,
  Sanyavig,
  Shuriti,
  Tastingglitch,
  Tinyshree,
  Vegpic,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const ElemntCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d63",
    title: "How Elemnt Increased Its Brand Visibility By 120% On E-Commerce Marketplaces",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Campaign Overview",
        subcontent:
          "Elemnt Sports Science is World’s 1st Sports Skincare brand that caters to athletes & outdoor enthusiasts who want to perform at their peak without compromising their skin health.",
          content:
          "They deal in innovative skin care products for cricketers, footballers, runners, cyclists, badminton & tennis players and all outdoor enthusiasts including trekkers & those who love to swim",
    
          submorecontent:
          "The client was looking for a professional organisation who could understand his e-commerce business and help him in strengthening Elemnt’s awareness and sales conversion.",
      },
      {
        heading: "The Setback ",
        list1:
        "Monitoring and managing Elemnt’s outreach to its target market.",
      list2:
        "To strengthen the brand’s awareness among its target customers- sports enthusiasts and athletes.",
      list3:
        "Increasing the visibility of Elemnt’s products on the largest e-commerce marketplaces- Amazon and Flipkart.",
      },
      {
        heading: "The Action Plan",
        subcontent:
          "We strove to offer Elemnt a comprehensive and personalised solution keeping in mind their campaign goals. With the help of our experienced team, adept in identifying and resolving client problems, we delivered A+ results with high efficiency, much to our client’s appreciation.",
        content:
          "Our main aim was to increase Elemnt’s wide range of product visibility on Amazon and Flipkart. The execution team carried out a thorough research to understand the likings/preferences of Elemnt’s target audience and what problems were taking them aback. Based on these findings, a promotional plan was created to enhance the brand’s awareness which contributed to building their overall sales as well.",
      },
      {
        heading: "Harnessed Customer Reviews and Testimonials",
        subcontent:
          "As a strategy, customers were encouraged to share their honest experiences after using Elemnt’s products. Our goal was to create a community of happy and satisfied customers who would naturally spread the word about Elemnt through genuine reviews and recommendations.",
      
      },
      {
        heading: "Results",
        content:
          "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
        image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:
          "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },
    
      
    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Coverelemnt}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.list1 && section.list2 && section.list3 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                          <li className="list_disc_ul">  {section.list3 && <p>{section.list3}</p>}</li>
                        </ul>
                        )}
                        {section.submorecontent && <p>{section.submorecontent}</p>}

                      </div>
                    ))}
                    
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ElemntCaseStudiesInner;
