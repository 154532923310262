import React from "react";

const ErrorLayout = ({ ...rest }) => {
  return (
    <>
      {rest.element}
    </>
  );
};
export default ErrorLayout;
