import { useState, useEffect } from "react";
import { getAxios, getJsonData } from "../../Services/AxoisInterceptorr";
import { toast } from 'react-toastify';
import MarqetingEndpoint from "../../Services/marqetingEndpoint";



  

export function useCampaignGoals() {
  const [casestudy, setCasestudy] = useState([]);
  const fetchuseCampaignGoals = () => {

    const endpoint = MarqetingEndpoint.CAMPAIGN_GOALS;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCasestudy(response.data.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseCampaignGoals();
  }, []);

  return casestudy;
}
export function useCaseStudy() {
  const [casestudy, setCasestudy] = useState([]);
  const fetchuseCaseStudy = () => {

    const endpoint = MarqetingEndpoint.CASE_STUDY;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCasestudy(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseCaseStudy();
  }, []);

  return casestudy;
}

export function useCaseStudyId(id) {
  const [casestudyId, setCasestudyId] = useState();
  const fetchuseCaseStudyId = () => {
    const endpoint = `${MarqetingEndpoint.CASE_STUDY_ID}/${id}`;
    const axiosInstance = getAxios();
  
    axiosInstance
      .get(endpoint)
      .then((response) => {
       
        setCasestudyId(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchuseCaseStudyId();
  }, [id]); 

  return casestudyId;
}

export function useBrandCategary() {
  const [brandCategory, setBrandCategory] = useState([]);

  const fetchusebrandCategary = () => {
    const endpoint = MarqetingEndpoint.BRAND_CATEGORY;
// const axiosInstance = getAxios();

    getJsonData(
      endpoint,
      null,
      (response) => {
        console.log(response)
        if (response.data.success) {
          setBrandCategory(response.data.data.data);
      }
      },
      (error) => {
        // toast.error(response.data.message);

      }
    );
  };

  useEffect(() => {
    fetchusebrandCategary();
  }, []);

  return brandCategory;
}
export function useInfluncersCategary() {
  const [newAdvertiserCategory, setAdvertiserCategory] = useState([]);

  const fetchuseCategary = () => {
    const endpoint = MarqetingEndpoint.INFLUNCER_CATEGORY;

    getJsonData(
      endpoint,
      null,
      (response) => {
        if (response.data.success) {
          setAdvertiserCategory(response.data.data.data);
        } 
      },
      (error) => {
        // toast.error(response.data.message);

      }
    );
  };

  useEffect(() => {
    fetchuseCategary();
  }, []);

  return newAdvertiserCategory;
}

export function useCountries() {
  const [countries, setCountries] = useState([]);

  const fetchCountries = () => {
    const endpoint = MarqetingEndpoint.COUNTRY_NAME;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching countries');
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries;
}

export function useStates(iso2) {
  const [states, setStates] = useState([]);

  const fetchStates = () => {
    const endpoint = MarqetingEndpoint.STATE_NAME + `/${iso2}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching states');
      });
  };

  useEffect(() => {
    if (iso2) {
      fetchStates();
    }
  }, [iso2]);

  return states;
}

export function useCities(state_code) {
  const [cities, setCities] = useState([]);

  const fetchCities = () => {
    const endpoint = MarqetingEndpoint.CITY_NAME + `/${state_code}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching cities');
      });
  };

  useEffect(() => {
    if (state_code) {
      fetchCities();
    }
  }, [state_code]);

  return cities;
}
 