import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Grid,
  Typography,
  StepConnector,
  FormHelperText,
  RadioGroup,
  Radio,
  Divider,
  Button,
  Modal,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { validateInput } from "../../common/utils/validationUtils";
import { PATTERNS } from "../../common/utils/constant";
import {
  DescriptionInput,
  BootstrapInput,
} from "../styledCcomponents/StyledMenu";
import CustomInputLabel from "../styledCcomponents/styledInput";
import OtpInput from "react-otp-input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  goalOptions,
  durationOptions,
} from "../../common/constant/NotifictionData";
import { useCountries, useBrandCategary } from "../customHooks/customHook";
import {
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MarqetingEndpoint from "../../Services/marqetingEndpoint";
import { postFormData, postJsonData } from "../../Services/AxoisInterceptorr";
import { toast } from "react-toastify";
import Animations from "../../common/Skeleton/Skeleton";
import CheckboxComponent from "../checkbox";
import RegisterSuccessPopup from "../RegisterPopup";
import CloseIcon from "@mui/icons-material/Close";
import ReCAPTCHA from "react-google-recaptcha";

export default function BrandsEnquiry() {
  const [collaborationBudget, setCollaborationBudget] = useState("");

  const [duration, setDuration] = useState("");

  const [submitClicked, setSubmitClicked] = useState(false);
  const [isOtherCity, setIsOtherCity] = useState(false);
  const [reviewScreenshotFile, setReviewScreenshotFile] = useState([]);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [formData, setFormData] = useState({
    firstname: {
      value: "",
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
    brandname: {
      value: "",
      isValid: true,
    },
    location: {
      value: "",
      isValid: true,
    },
    adminnote: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [categories, setCategories] = useState({});
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedOrderImages, setSelectedOrderImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [capVal, setCapVal] = useState(null);

  const countries = useCountries();
  const categorie = useBrandCategary();
  const filterCategories = [
    "Fitness",
    "Food & Beverage",
    "Gadgets & Tech",
    "Gaming",
    "Fashion & Lifestyle",
    "Entertainment",
    "Marketing",
    "Men's Grooming",
    "Real Estate",
    "Retail",
    "Sports",
    "Travel",
    "Skincare",
    "Personal Care",
    "Cosmetics",
    "Cafe and Restaurants",
    "Home Decor",
  ];
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };


  const navigate = useNavigate();
 
  const navigateToHome = () => {
    navigate("/");
  };

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const selectedCategories = Object.keys(categories).filter(
    (category) => categories?.[category]
  );
  const handleLogin = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    setIsLoading(true);
    // selectedOrderImages?.length > 0 &&
    const isFormValid =
      formData.phone.isValid &&
      formData.email.isValid &&
      Object.values(categories).some((category) => category);
    if (isFormValid) {
      const data = {
        fullName: formData.firstname.value,
        email: formData.email.value,
        mobileNumber: formData.phone.value,
        brandName: formData.brandname.value,
        location: formData.location.value,
        note: formData.adminnote.value,
      };
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      if (selectedOrderImages) {
        selectedOrderImages.forEach((file, index) => {
          newData.append("visitingCard", file.file);
        });
      }
      selectedCategories.forEach((category, index) => {
        newData.append(`brandCategory[${index}]`, category);
      });

      postFormData(
        MarqetingEndpoint.BRAND_LEADS,
        newData,
        () => {},
        (res) => {
          if (res && res.status === 201) {
            toast.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          setIsLoading(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsLoading(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setShowError(false);
  };

  const handleBudgetChange = (event) => {
    setCollaborationBudget(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menuProps = {
    MenuProps: {
      PaperProps: {
        style: {
          width: "400px",
          marginTop: "20px",
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
      },
    },
  };
  const handleClick = () => {
    setSubmitClicked(true);
  };
  const handleReviewScreenShot = (e) => {
    setSelectedOrderImages([
      ...selectedOrderImages,
      ...Array.from(e.target.files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      })),
    ]);
  };

  const removeOrderImage = (indexToRemove) => {
    setSelectedOrderImages(
      selectedOrderImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };

  return (
    <>
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <Box sx={{ width: "100%" }}>
        <Box>
          <h1 className="brandinfluncers_forms">Brand Enquiry</h1>

          <>
            {" "}
            <form onSubmit={handleLogin}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Full Name*</CustomInputLabel>
                    <DescriptionInput
                      required
                      name="first_name"
                      type="text"
                      placeholder="Enter Full Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "firstname", null);
                      }}
                    />
                    {/* {formData.firstname.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Email Address(Optional)</CustomInputLabel>
                    <DescriptionInput
                      name="first_name"
                      type="text"
                      placeholder="Enter Email Address"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "email", PATTERNS.EMAIL);
                      }}
                    />
                    {formData.email.isValid || (
                      <p className="input_text_error">
                        *Please enter your valid email address.
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <div className="mobile_function">
                      <CustomInputLabel>Phone Number*</CustomInputLabel>
                    </div>

                    <DescriptionInput
                      required
                      name="Contact"
                      type="Number"
                      placeholder="Enter Phone Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "phone", PATTERNS.MOBILE);
                      }}
                    />
                    { formData.phone.isValid || (
                      <p className="input_text_error">
                        *Please enter valid phone number.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Store Name*</CustomInputLabel>
                    <DescriptionInput
                      required
                      name="brandname"
                      type="text"
                      placeholder="Enter Store Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "brandname", null);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Store Location*</CustomInputLabel>
                    <DescriptionInput
                      required
                      name="location"
                      type="text"
                      placeholder="Enter location"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "location", null);
                      }}
                    />
                    {/* {formData.location.isValid || (
                      <p className="input_text_error">
                        *Please enter store location.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <FormControl component="fieldset">
                <CustomInputLabel>Store Category*</CustomInputLabel>

                <FormGroup>
                  <Grid container spacing={3}>
                    {categorie.map((category, index) => {
                      if (filterCategories.includes(category.brandCategory)) {
                        const checked = categories[category._id];
                        return (
                          <Grid item xs={12} sm={2.4} key={index}>
                            <div
                              className={`checkbox_upper_back ${
                                checked ? "checked_background" : ""
                              }`}
                              onClick={() => handleCategoryChange(category._id)}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked}
                                    onChange={() =>
                                      handleCategoryChange(category._id)
                                    }
                                    style={{
                                      color: checked ? "#FFFFFF" : "",
                                      display: "none",
                                    }}
                                  />
                                }
                                label={
                                  <span
                                    style={{
                                      color: checked ? "#ffffff" : "#000000",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      fontFamily: '"Montserrat", sans-serif',
                                    }}
                                  >
                                    {category.brandCategory}
                                  </span>
                                }
                              />
                            </div>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>

                  {formSubmitted &&
                    !Object.values(categories).some((category) => category) && (
                      <p className="input_text_error">
                        *Please select at least one category.
                      </p>
                    )}
                </FormGroup>

                <Divider sx={{ marginY: 1, borderBottom: "none" }} />
              </FormControl>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <CustomInputLabel>Requirement</CustomInputLabel>

                <FormControl sx={{ width: "100%" }}>
                  <DescriptionInput
                    name="adminnote"
                    type="text"
                    placeholder="Enter requirement Here"
                    id="outlined-required"
                    label="First Name"
                    multiline
                    rows={4}
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "adminnote", null);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <CustomInputLabel>Visiting Card</CustomInputLabel>

                <FormControl sx={{ width: "100%", paddingTop: "8px" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleReviewScreenShot}
                    multiple
                    style={{
                      border: "1px solid #f0f0f0",
                      padding: "7px 10px",
                      borderRadius: "8px",
                      background: "#f0f0f0",
                      color: "transparent",
                    }}
                  />
                  {/* {!selectedOrderImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the order screenshot.
                        </p>
                      )} */}
                  {selectedOrderImages.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #f0f0f0",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1px",
                        }}
                      >
                        <img
                          src={image.url}
                          alt={`Selected ${index}`}
                          style={{
                            width: "50px",
                            height: "50px",
                            border: "1px solid #f0f0f0",
                            alignSelf: "center",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenZoomModal(image.url)}
                        />
                        <p
                          style={{ alignSelf: "center" }}
                          className="leads_text"
                        >
                          {image.file.name}
                        </p>
                      </div>
                      <CloseIcon
                        onClick={() => removeOrderImage(index)}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          marginLeft: "2px",
                        }}
                      />
                    </div>
                  ))}
                </FormControl>
              </Grid>

              {/* <div className="signup_checkbox">
                <CheckboxComponent
                  label=""
                  value="termsPrivacy"
                  name="termsPrivacy"
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                  id="termsPrivacyCheckbox"
                />
                <p>I agree to the </p>
                <span>
                  <a target="_blank" href="https://www.marqeting.in/terms">
                    Terms & Conditions
                  </a>
                </span>
              </div> */}
              {/* {formSubmitted &&
                (
                 
             
                  !Object.values(categories).some((category) => category)) && (
                  <p
                    className="input_text_error"
                    style={{ textAlign: "center" }}
                  >
                    There are some errors, please check!
                  </p>
                )} */}

              {/* {showError && (
                <p className="input_text_error" style={{ textAlign: "center" }}>
                  *Please agree to the Terms & Privacy before signing up.
                </p>
              )} */}
              <ReCAPTCHA
                        sitekey="6LcTQy0pAAAAAJwipNtK-1Cyfscd0DOsb-YUhqgx"
                        // sitekey="6LfMJR8pAAAAANOgSmosSeJEHnyoi7Gmu2EiS2Q1"
                        onChange={(val) => setCapVal(val)}
                      />
              <div className="submit_container">
              {capVal ? (
                <button
               type={isLoading ? "button" : "submit"}
                  className="contact_submit"
                  disabled={isLoading}
                  // onClick={handleClick}
                >
                  {/* <button type="submit" className="contact_submit"> */}
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              ) : (
                            <button
                              disabled
                              className="contact_submit_disabled"
                            >
                              Send message
                            </button>
                          )}
              </div>
            </form>
          </>
        </Box>
      </Box>
    </>
  );
}
