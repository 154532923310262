import React from 'react'
import CaseStudiesCards from '../../common/Campaigncards/caseStudiesCardscopy'
import { Helmet } from "react-helmet-async";

const CaseStudies = () => {
  return (
    <>
    <Helmet>
<title>Marqeting - Case Studies</title>

<meta
  name="description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="keywords"
  content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
/>

<meta name="robots" content="index,follow" />

<meta
  name="og:title"
  content="Marqeting - Connecting Brands with Trusted Influencers"
/>

<meta
  name="og:description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta name="og:url" content="https://www.marqeting.in/case-studies" />

<meta name="og:site_name" content="Marqeting" />

<meta
  name="og:image"
  content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
/>

<meta
  name="og:keywords"
  content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
/>

<meta name="twitter:card" content="summary_large_image" />

<meta
  name="twitter:title"
  content="Marqeting - Connecting Brands with Trusted Influencers"
/>

<meta
  name="twitter:description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="twitter:image"
  content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
/>

<meta
  name="description"
  content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
/>

<meta
  name="description"
  content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
/>

<meta
  name="description"
  content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="description"
  content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
/>

<meta
  name="description"
  content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
/>
</Helmet>
     <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container">
            <h1 className="have_solution_heading">Case Studies</h1>

            <div className="we_case_inner">
              {/* <CaseStudiesCards /> */}
              <CaseStudiesCards />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CaseStudies
