import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Caseimages,
  Caseresult,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
  Unspoild,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const UnCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d77",
    title:
      "How Marqeting Drove Incredible Campaign Results for Unspoiled Skincare",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    // description:"Greenex Nutrition sought YouTube influencer expertise, tasking the team with maximizing their brand recognition and engagement all over social media",
    caseType: "BEAUTY & PERSONAL CARE",

    content: [
      {
        heading: "Client Brief",
        subcontent:
          "Unspoiled is a well-known and trusted skincare brand that provides premium quality skincare products. The brand’s product portfolio includes sheet masks, gels, skin pads, and skin serums. Their product range is unified by scientifically backed ingredients that solve multiple skin issues with its precise formulations.",
        content:
          "The client’s approach is all about making skincare easy and accessible, without overwhelming consumers with heavy choices. They draw inspiration from medical insights to come up with effective skincare solutions. Thousands of repeated and new consumers trust Unspoiled for several good reasons.",
        content2:
          "Customers are primarily assured of getting premium quality products powered by clinically proven ingredients, historical research and gratifying results.",
      },

      {
        heading: "The Challenge",

        subcontent:
          "Unspoiled decided to run an influencer marketing campaign on Instagram and needed to find skincare influencer profiles for participation. They also wanted to reach a larger audience, so planned to run campaigns with paid influencers every month while continuing to run month-on-month campaigns with existing barter influencers.",
        content:
          "The challenge was to find a right mix of paid and barter creator profiles who could effectively talk about the brand and engage their audience. We needed to make sure that these creators had a real connection with their followers, high engagement rates and content that matched Unspoiled’s mission and values.",
      },
      {
        heading: "Strategy and Execution",

        subcontent:
          "We partnered with beauty and skincare influencers known for their expertise and engagement in the skincare niche. Influencers interested in skincare routines, product reviews, and natural beauty were particularly drawn to the campaign. These influencers created content highlighting the unique selling points (USPs) and benefits of Unspoiled’s products, such as clinically proven ingredients, and skin health improvements.",
        content:
          "We evaluated influencers based on their content quality, follower engagement, and alignment with the brand’s values. Contracts were made with both paid and barter influencers, outlining campaign goals, content guidelines, and posting schedules.",
        content2:
          "By collaborating with influencers who had a strong presence on social media and significant organic followers, we reached a wide audience and potentially garnered a lot of hype around their brand. This strategy helped us in improving their scalability and driving interest in their product range among new and regular customers.",
      },

      // {
      //   heading: "Results",

    
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Some of the content created by our Instagram creators for Unspoiled Skincare",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C1RLQkWvE46/",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Disthi,
    //   },
    //   {
    //     url: "https://www.instagram.com/p/C1PAiOrLnav/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Expertview,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1RD5K8vpix/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Kavita,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1CgMi8PTOK/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mahi,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1CO2R6JRt2/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Poonam,
    //   },
    //   {
    //     url: "https://www.instagram.com/p/C03WbYzJULQ/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Gourav,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C08vlIKL8Yr/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mybutter,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1HufEqxsmk/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Foodntales,
    //   },
    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Unspoild}
                  alt={data.title}
                />
                {data.description && <h1>{data.description}</h1>}
                {/* <p>{data.description}</p> */}
                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.headingContent && (
                          <h1 style={{ marginTop: "10px" }}>
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}

                        {section.subHeadingnewe && (
                          <p className="subHeadingnewe">
                            {section.subHeadingnewe}
                          </p>
                        )}

                        {section.subcontent && <p>{section.subcontent}</p>}

                        {/* {section.image && (
                          <img
                            className="case_inner_image"
                            src={Momocase}
                            alt="Caseimages"
                          />
                        )} */}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && <p>{section.list1}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                          </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnCaseStudiesInner;
