import React, { useRef } from "react";
import companyNameServices from "../../common/constant/NotifictionData";
import LandingSideStatic from "../../components/sidebar/landingSideStatic";
import { Helmet } from "react-helmet-async";

const PrivacyComponenet = () => {

  

 
  const privacyPolicyData = {
    scope: {
      title: "How We Use Personal Information?",
      subTitle:
        "Marqeting may use a User’s personal data for the following purposes:",
      content: [
        {
          text: "To run marketing campaigns: To run marketing campaigns, contest, survey or other Website features. To send You information that You have agreed to receive about topics that may be of interest to You.",
        },
        {
          text: "To improve customer service: Information You provide helps Us respond to your customer service requests and support needs more efficiently.",
        },
        {
          text: "To personalize user experience: We may use personal data to understand how our Users use the services and resources provided on our website.",
        },
        {
          text: "To improve our Platform: We may use the feedback You provide to improve our products and services",
        },
        {
          text: "To send periodic communications: We may use contact details to respond to your inquiries, questions, and/or other requests. If User joins our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.",
        },
        {
          text: "For legitimate Interest: Where it is necessary for our legitimate interests (or those of a third party).",
        },
        {
          text: "Under regulatory obligations: To comply with a legal or regulatory obligation",
        },
      ],
    },
    informationCollection: {
      title: "Information We Collect ",
      subTexttitle:
        "We collect and receive the following types of Information:",
      InformationProvide: {
        subTitle: "Information You Provide to Us:",
        content: [
          {
            heading: "Account Information:",
            subcontent:
              ". Sit amet consectetur. Sit nisi vel faucibus nibh vitae gravida vitae sit urna. Ut fusce amet enim dignissim dignissim nec nulla duis. Quam elementum sapien aliquet augue quam tempor. Velit sit amet eu amet dignissim. Semper tellus orci in at facilisi posuere orci. Lorem ipsum dolor sit amet consectetur. Sit nisi vel faucibus nibh vitae gravida vitae sit urna.",
          },

          {
            heading: "Customer Data:",
            subcontent:
              ". Sit amet consectetur. Sit nisi vel faucibus nibh vitae gravida vitae sit urna. Ut fusce amet enim dignissim dignissim nec nulla duis. Quam elementum sapien aliquet augue quam tempor. Velit sit amet eu amet dignissim..",
          },
        ],
      },
      InformationYouReceiveFromUs: {
        subTitle: "Information We Collect Automatically:",
        content: [
          {
            heading: "Account Information:",
            subcontent:
              ". Sit amet consectetur. Sit nisi vel faucibus nibh vitae gravida vitae sit urna. Ut fusce amet enim dignissim dignissim nec nulla duis. Quam elementum sapien aliquet augue quam tempor. Velit sit amet eu amet dignissim. Semper tellus orci in at facilisi posuere orci. Lorem ipsum dolor sit amet consectetur. Sit nisi vel faucibus nibh vitae gravida vitae sit urna.",
          },

          {
            heading: "Customer Data:",
            subcontent:
              ". Sit amet consectetur. Sit nisi vel faucibus nibh vitae gravida vitae sit urna. Ut fusce amet enim dignissim dignissim nec nulla duis. Quam elementum sapien aliquet augue quam tempor. Velit sit amet eu amet dignissim..",
          },
        ],
      },
    },
    howWeUse: {
      title: "How We Use Your Information",
      content: [
        "We use your Information in the following ways:",
        "Information You Provide to Us",
        "Information You Provide to Us",
      ],
    },
  };


  return (
    <>
   <Helmet>
   <title>Marqeting - Privacy Policy</title>

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="robots" content="index,follow" />

        <meta
          name="og:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="og:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta name="og:url" content="https://www.marqeting.in/privacy" />

        <meta name="og:site_name" content="Marqeting" />

        <meta
          name="og:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="og:keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="twitter:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="twitter:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="description"
          content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
        />
      </Helmet>
      <div className="aboutus_container">
      <div className="hcontainer">
        <div className="faq_container_innar">
          <div className="faq_container_right">
            <div className="terms_container">
            <h1 className="privacy_typograpy_text_heading">Privacy Policy</h1>
              <span className="terms-Of_service_heading">
                Effective from : December 1, 2023
              </span>
              <p className="landing_typograpy_text">
                This Privacy Policy ("Privacy Policy") governs the manner in
                which AFFL MARKETING PVT. LTD ("marqeting" or "Us", “we” or
                "We") collects, uses, and discloses information collected from
                users (each, "User" or "You") via the website
                https://www.marqeting.in ("Website") or Mobile Application
                collectively referred to as Platform (“Platform”). This Privacy
                Policy applies to the Platform and the services (“Services”)
                offered by marqeting on its website and mobile Application.
              </p>
              <p className="landing_typograpy_text">
                Please read the following terms of the Privacy Policy carefully
                to understand our practices regarding your personal data and how
                we will treat it. This Privacy Policy is intended to help Users
                of marqeting to understand what information marqeting collects,
                how we handle it, and what rights User have on their information
                when sharing it with marqeting.
              </p>
              <p className="landing_typograpy_text">
                We will handle personal data in accordance with the terms of
                this Privacy Policy. If any intended use of personal data goes
                beyond the purposes envisioned during collection, we will notify
                you of such new purpose(s) to use your personal data.
              </p>

              <h1 className="terms_typograpy_text">
                The Data We Collect About You
              </h1>

              <p className="landing_typograpy_text">
                • For the purposes of rendering our Services to You, we may
                collect, use, store and transfer different kinds of personal
                data about You, including but not limited to identity data,
                contact data, transaction data/fees, device data, content data,
                profile data and usage data.
              </p>
              <p className="landing_typograpy_text">
                • We also collect, use and share aggregated data such as
                statistical or demographic data for any purpose. However, if We
                combine or connect aggregated data with your personal data so
                that it can directly or indirectly identify You, we will treat
                the combined data as personal data and it will be used in
                accordance with this Privacy Policy.
              </p>

              <h1 className="terms_typograpy_text">
                How Is Your Personal Data Collected?
              </h1>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">
                  Information You give Us
                </h5>
                This is the information, including identity, contact, or any
                other attitudinal or demographic information from You, submitted
                through the Platform. Furthermore, it includes information You
                provide when You register to use the Website/Mobile Application,
                avail our Services, enter a campaign, participate in a promotion
                or survey, or other activities commonly carried out in
                connection with the Platform.
              </p>
              <h1 className="terms_typograpy_text">
                Information We collect about You and your device
              </h1>
              <p className="landing_typograpy_text">
                Each time You visit our website or Platform, we may
                automatically collect information including device, content and
                usage data. We collect this data using cookies and other similar
                technologies..
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Registration</h5>
                When you register your user account on the Website, we collect
                your name, email, mobile, address, and your country. We use such
                information to register and maintain your user account, enable
                your access to the Services, provide you with the requested
                services, contact you, if necessary, and maintain our business
                records. The legal bases on which we rely are “performing a
                contract with you” and” pursuing our legitimate business
                interests” (i.e., operate, analyse, grow, and administer the
                Services). We keep your personal data until you delete your user
                account.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">
                  Your activity on the Website
                </h5>
                When you use the Website, we collect your device ID, device type
                and location data based on your IP address. We use such
                information to ensure security of the Website, analyse your use
                of the Website, identify your location, and recommend optimal
                location. The legal basis on which we rely is “pursuing our
                legitimate business interests (i.e., analysing and protecting
                the Website). We keep your personal data as long as it is
                necessary for analytics purposes.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Inquiries</h5>
                When you contact us, we collect your name, email address, and
                any information that you decide to include in your message. We
                use such data to respond to your inquiries. The legal bases on
                which we rely are “pursuing our legitimate business interests”
                (i.e., to grow and promote our business) and “your consent” (for
                optional personal data). We keep your personal data until you
                stop communicating with us.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Payments</h5>
                When you make a payment, you will be asked to provide your
                payment details that depend on the chosen payment provider (like
                your name, credit card number, expiration date, security code,
                and billing address). Please note that we use third-party
                payment processors. Your payment data is used to process your
                payments and maintain our business records. The legal bases on
                which we rely are “performing a contract with you, pursuing our
                legitimate business interests” (i.e., administer our business),
                and “complying with our legal obligations”. We keep your
                personal data for as long as required by law.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">IP address</h5>
                When you use the Website, we or our third-party analytics
                service providers (as explained below) collect your IP address.
                We use your IP address to analyse the technical aspects of your
                use of the Website, prevent fraud and abuse of the Website,
                ensure the security of the Website, and tailor the Website for
                your location. The legal basis on which we rely when processing
                your IP address is “pursuing our legitimate business interests”
                (i.e., to analyse and protect the Website). We keep your
                personal data as long as it is necessary for analytics purposes.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Cookies</h5>
                When you browse the Website, we use cookies and/or other
                tracking technologies to distinguish You from other users of the
                Platform and to remember your preferences.
              </p>
              <p className="landing_typograpy_text">
                We collect data by way of “cookies”. Cookies are small bits of
                information that are sent to your browser from the Platform and
                are stored on your computer or device (hard drive). Cookies
                remember information about activities on the Platform. If a User
                has chosen to disable cookies on his or her browser, some of the
                functionality of the Platform may be lost. Certain services
                provided require that cookies be enabled in a user’s browser and
                cannot be used when cookies are disabled..
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Third Party Links</h5>
                The Platform may include links to third-party websites, plug-ins
                and applications. Clicking on those links or enabling those
                connections may allow third parties to collect or share data
                about You. We do not control these third-party websites and are
                not responsible for their privacy policies or statements. When
                You leave our Platform, we encourage You to read the privacy
                policy of every website You visit.
              </p>
             
              <h1 className="terms_typograpy_text">
                We may collect certain information stored in your social media
                account such as:
              </h1>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Facebook</h5>
                marqeting will access certain information from Facebook
                regarding your account. We may collect user profile image,
                display name, username / page ID or profile ID, access tokens,
                sent posts. This includes the content of your post and
                engagement data (such as click rates, likes, re-shares,
                impressions, as well as general engagement counts), to the
                extent permitted by applicable law. This data will only be used
                by marqeting to provide you with the Service you expect and will
                not be shared with any third parties.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Twitter</h5>
                marqeting will access certain information from Twitter regarding
                your account. We may collect profile image, display name,
                username / profile ID, access tokens, and sent posts. This
                includes the content of your post and engagement data (such as
                click rates, likes, retweets, re-shares, impressions, as well as
                general engagement counts), to the extent permitted by
                applicable law. This data will only be used by marqeting to
                provide you with the Service you expect and will not be shared
                with any third parties.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Instagram</h5>
                marqeting will access certain information from Instagram
                regarding your account. We may collect profile image, display
                name, username / profile ID, access tokens, and sent posts. This
                includes the content of your post and engagement data (such as
                click rates, likes, re-shares, impressions, as well as general
                engagement counts), to the extent permitted by applicable law.
                This data will only be used by marqeting to provide you with the
                Service you expect and will not be shared with any third
                parties.
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">LinkedIn</h5>
                marqeting will access certain information from LinkedIn
                regarding your account. We may collect profile image, display
                name, username / profile ID, access tokens, and sent posts. This
                includes the content of your post and engagement data (such as
                click rates, likes, re-shares, impressions, as well as general
                engagement counts), to the extent permitted by applicable law.
                This data will only be used by marqeting to provide you with the
                Service you expect and will not be shared with any third
                parties..
              </p>
              <p className="landing_typograpy_text">
                <h5 className="terms_typograpy_text_sub">Youtube</h5>
                marqeting will access certain information from YouTube regarding
                your account. We may collect profile image, display name,
                username / profile ID, access tokens, and sent posts. This
                includes the content of your post and engagement data (such as
                click rates, likes, re-shares, impressions, as well as general
                engagement counts), to the extent permitted by applicable law.
                This data will only be used by marqeting to provide you with the
                Service you expect and will not be shared with any third
                parties.
              </p>
              <h1 className="terms_of_use_heading">
                {privacyPolicyData.scope.title}
              </h1>

              <span className="terms_Of_service_heading_li">
                {privacyPolicyData.scope.subTitle}
              </span>
              {privacyPolicyData.scope.content.map((item, index) => (
                <li className="Information_We_Collect" key={index}>
                  {item.text}
                </li>
              ))}

              <h1 className="terms_typograpy_text">
                How We Keep Your Personal Data Secure
              </h1>
              <p className="landing_typograpy_text">
                To help prevent unauthorized access, maintain data accuracy, and
                ensure the appropriate use of information, we have put in place
                appropriate technical, organizational, physical, electronic, and
                managerial procedures designed to protect the personal data we
                collect (“Security Measures”). The measures we use are designed
                to provide a level of security appropriate to the types and
                nature of the personal information we process. Our security
                measures include secured networks, encryption, access
                limitation, anonymisation, and carefully selected data
                processors. We have processes for testing, assessing and
                evaluating the effectiveness of our Security Measures, including
                training and communication to employees, for ensuring the
                security of our processing
              </p>
              <p className="landing_typograpy_text">
                Please understand, however, that while we try our best to
                safeguard your personal data once we receive it, no transmission
                of data over the Internet or any social media or other public
                network can be guaranteed to be 100% secure.
              </p>
              <p className="landing_typograpy_text">
                To help protect the privacy of your own personal data, including
                maintaining the confidentiality of any account information or
                access credentials, we recommend you take precautions to protect
                the security of any personal information that you transmit over
                any public or untrusted network by using encryption and other
                techniques to prevent unauthorized persons from intercepting or
                receiving any of your personal data. You are responsible for the
                security of your information when using unencrypted, open
                access, public or otherwise unsecured networks.
              </p>
              <h1 className="terms_typograpy_text">
                Use And Disclosure of Personal Data
              </h1>
              <p className="landing_typograpy_text">
                marqeting does not sell, rent or lease its customer lists to
                third parties.
              </p>
              <h1 className="terms_typograpy_text">Data Retention</h1>
              <p className="landing_typograpy_text">
                We retain personal data we collect from you for as long as you
                use our Services or, if longer, as necessary to fulfil the
                legitimate business need for which it was collected. For
                example, we retain information in order to provide you with the
                Services, to comply with applicable legal, tax, or accounting
                requirements, and to enforce our agreements, prevent fraud,
                assist with investigations, resolve disputes, analyse or
                troubleshoot programs, enforce our terms of use, or take other
                actions permitted by law. We may also retain information to
                prevent re-registration in the event that your account has been
                suspended or terminated or to create an archival copy of your
                information, which might be used by us for record keeping and
                internal purposes.
              </p>
              <p className="landing_typograpy_text">
                We do not discriminate against you if you decide to exercise
                your rights. It means that we will not (i) deny any goods and
                services, (ii) charge you different prices, (iii) deny any
                discounts or benefits, (iv) impose penalties, or (v) provide you
                with lower quality services.
              </p>
              <p className="landing_typograpy_text">
                • If we receive your express (“opt-in”) consent to receive
                marketing messages; o
              </p>
              <p className="landing_typograpy_text">
                • If you voluntarily subscribe for our newsletter; o
              </p>
              <p className="landing_typograpy_text">
                • If we decide to send you information closely related to
                Services already used by you
              </p>
              <p className="landing_typograpy_text">
                The newsletters sent by us may contain tracking pixels that
                allow us to conduct analysis of our marketing campaigns.
                Tracking pixels allow us to see whether you opened the
                newsletter and what links you have clicked on. We use such
                information to conduct analytics and pursue our legitimate
                business interests.
              </p>
              <p className="landing_typograpy_text">
                If necessary, we will send you important informational messages,
                such as confirmation receipts, order updates, payment
                information, technical emails, and other administrative updates.
                Please note that such messages are sent on an “if-needed” basis
                and they do not fall within the scope of commercial
                communication that may require your prior consent. You cannot
                opt-out from service-related notices.
              </p>

              <h1 className="terms_typograpy_text">
                Tracking Technologies & Advertising & Analytics Services
              </h1>
              <p className="landing_typograpy_text">
                We and our third-party partners and vendors may use tracking
                technologies, such as cookies, pixels, and web beacons and
                collect and store information about you when you use or interact
                with our Services, marketing communications, advertising content
                and/or third-party websites, in order to provide you with
                targeted advertising based on your browsing activities and
                interests. These third parties automatically receive your IP
                address or mobile device identifier when you access the Services
                or third-party websites. They may also use tracking technologies
                to measure the effectiveness of advertisements, to personalize
                the advertising content, and to serve you advertisements that
                are relevant to your regional location. We may also use
                information collected using third-party cookies and beacons on
                the Services and in our emails to deliver advertising about our
                Services when you visit third-party websites.
              </p>
              <h1 className="terms_typograpy_text">
                Changes To This Privacy Policy
              </h1>
              <p className="landing_typograpy_text">
                From time to time, marqeting may update its Privacy Policy.
                Where these changes are material in nature, we may notify you
                either by prominently posting a notice of such changes prior to
                implementing them, by directly sending you a notification, or by
                posting the most recent version of the Privacy Policy on the
                Website with the “Last Updated” date posted at the top. You
                should check the Website frequently for updates. We encourage
                you to review this Privacy Policy frequently to be informed of
                how marqeting is collecting and protecting your personal data.
              </p>
              <h1 className="terms_typograpy_text">Contact Information</h1>
              <p className="landing_typograpy_text">
                If you have any questions, comments, or concerns, please contact
                us using the details below: AFFL MARKETING PVT. LTD, B1/628,
                Janakpuri, New Delhi - 110058 Email: info@marqeting.in
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default PrivacyComponenet;
