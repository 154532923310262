import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  Aani,
  Aashi,
  Akshitarora,
  CAseimages,
  Caseimages,
  Caseresult,
  Chalwahankhaatehai,
  Coverdkava,
  Covervideoimage,
  Foodieboy,
  Foodjunction,
  Foodsearch,
  Foodtravls,
  Gunjan,
  Hanshika,
  Hazel,
  Innsightimagedkava,
  Ishita,
  Jessmeet,
  Littleflower,
  Mayank,
  Mnyanka,
  Muskan,
  Playbutton,
  Pr003,
  Prachi,
  Sanyavig,
  Shuriti,
  Tastingglitch,
  Tinyshree,
  Vegpic,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const DkavaCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d63",
    title: "Tapping Into Lifestyle Influencers To Drive Jaw-Dropping Impact For DKava Decor",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Target Objectives for DKava’s Campaigns",
        content:
          "DKava was aware about the promising growth of the home decor market, projected at a CAGR of 17.73% according to a recent research. In response, they decided to carry out influencer collaborations to reveal to the world that DKava offers more than what meets the eye. To run their successful campaigns, we needed-",
          list1:
          "Promote their wide menu choices",
        list2:
          "Build trust and curiosity in Gen-Z’s and Gen-Y’s",
        list3:
          "Convert first-time visitors into repeated ones",
      },
      {
        heading: "Client’s Brief",
        content:
          "A house is not a home without family. People come together to complete it and another thing that completes it is the home decor. DKava has a plethora of home decor items ranging from wall paintings, candle stands, lamps, aesthetic showpieces and many more. One stand-out part is their best-in-class prices, which means customers can shop worry-free, knowing they are getting the best deals without needing to search elsewhere.",
      },
      {
        heading: "The Action Plan",
        subcontent:
          "Since immediate awareness and reaching a new target segment were the primary objectives for which DKava sought our influencer marketing services, we picked the leading social media platform- Instagram, to execute their campaigns.",
        content:
          "The idea was to reach a wide and far target audience. We leveraged the potential of our influencers to create awareness and mesmerising visual appeal in the viewers, bringing the latter nearer to the sales funnel. The messaging revolved around pitching DKava as a top-tier brand, hooking viewers’ to try out DKava’s extensive product offerings.",
      },
      {
        heading: "Execution",
        subcontent:
          "In total 1000+ influencers applied for the campaign, while only 20 best-performing influencers were approved based on their engagement rates, relevance and content niche. The team tasked them with creating innovative reels and video stories showcasing DKava’s products.",
        content:
          "We ideated our influencers to channelize their creative bones by using trendy audio tracks and personalized voiceovers, to enhance the appeal and virality of the videos.The influencers were also asked to post video stories about their reels to expand the campaign’s reach and traction. They even shared DKava’s unique discount code in the captions of the posts to attract more customers and easily boost the brand’s conversion.",
      },
      {
        heading: "Jaw-Dropping Performance for DKava",
        content:
          "DKava had to make itself a go-to brand for the decor shoppers, which was no small feat. By partnering with 20 micro-influencers, DKava hit the jackpot. Our influencer marketing services gave a head start to DKava in outreach strategies, heightened visibility, strengthening credibility and enhancing customer experience in the near future.",
        image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:
          "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      },
    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {
        url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Tastingglitch,
      },
      {
        url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Mnyanka,
      },
      {
        url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Littleflower,
      },
      {
        url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Akshitarora,
      },
    
      
    ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Coverdkava}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimagedkava}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.list1 && section.list2 && section.list3 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                          <li className="list_disc_ul">  {section.list3 && <p>{section.list3}</p>}</li>
                        </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DkavaCaseStudiesInner;
