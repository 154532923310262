import React from 'react'
import { whyWorkData } from '../../common/constant/NotifictionData'
import { Whytoworkj } from '../../components/IconsImport'

const WhyToWorkSection = () => {
  return (
    <div id="whytowork" className="container_work_fluid">
        <div className="hcontainer">
          <div className="why_work_with_us">
            <div className="why_work_with_left">
              <img src={Whytoworkj} alt="work image" />
            </div>
            <div className="why_work_with_right">
              <h1>Why To Work With Us?</h1>
              <p>Optimize your social efforts for greater impact.</p>

              {whyWorkData.map((group, index) => (
                <div key={index} className="why_flex">
                  {group.map((item, index) => (
                    <div key={index} className="why_flex_inner">
                      <img src={item.imgSrc} alt="icon" />
                      <p>{item.text}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  )
}

export default WhyToWorkSection
