import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import { Coverelemnt, Innsightimageelemnt, Kreocase, Playbutton } from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const KreoCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d68",
    title:
      "Kreo X Marqeting The Growth Story of a Consumer Electronics Brand- Kreo",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "E-COMMERCE",
    content: [
      {
        heading: "The Overview",
        subcontent:
          "Kreo, a consumer electronics brand, helps bring people together through gaming, streaming and computing. Their extensive product range focuses on how customers connect and interact with the digital world. With over 100+ hours of hands-on experience, they provide their customers with the most premium products and services.",
        content:
          "Already operating in a competitive market space, the client was facing challenges in achieving growth outside of recurring market changes. The agenda was to drive purchase intent and help Kreo increase its sales on the famous e-commerce platform, Amazon.",

        submorecontent:
          "Kreo contacted the team at Marqeting to lift their brand awareness and help them generate more sales. To execute the deal, we rolled out an effective <b>Amazon management strategy</b> for them.",
      },
      {
        heading: "The Setback",

        list1:
          "To help Kreo increase its sales on the <b>largest e-commerce platform - Amazon.</b>",
        list2:
          "Devise an Amazon strategy that plays to increasing Kreo’s awareness among its target customers- casual/professional gamers, teens and young adults.",
        list3:
          "Showcase Kreo as the leading brand dealing in the most innovative products for their customers.",
      },
      {
        heading: "The Action Plan",
        subcontent:
          "The primary objective centred around setting Kreo apart from its competitors and expanding its awareness within the target audience. We focused on driving traffic to Kreo’s product detail pages and helping them increase their Amazon sales. The execution team at Marqeting cross-promoted Kreo’s products to reach the <b>identified target audience.</b>",
        content:
          "Comprehensive research was carried out to understand the preferences of Kreo’s target consumers. A <b> promotional plan </b> was devised not only to increase their sales but also to actively manage their product reviews on Amazon. Soon after shortlisting the target buyers, we verified their Amazon profiles to check their authenticity on the e-commerce platform.",

        content3:
          "The action plan involved encouraging buyers to share their individual product experiences on Amazon, conveying their thoughts through honest reviews and product ratings.",
        submorecontent:
          "Taking its place among top competitors, Kreo’s products were moved-up on Amazon in the relevant <b> search results </b> and we could not be prouder. With their products in huge demand on Amazon, we are proud to be a part of their marketing success.",
      },

      {
        heading: "The Outcome",
        subcontent:
          "Kreo’s partnership with Marqeting in Phase 3 turned tables on their Amazon presence. Sales figures like revenue growth, sales value, and units sold all saw a significant increase, proving Kreo's growing popularity. The outcome wasn't just about more visitors at Kreo's product pages. Their <strong> visibility  </strong>among the top 10 search results for \"gaming products\" on Amazon <strong>made </strong>us or Marqeting extremely proud. ",
      },
      // {
      //   heading: "Results",
      //   content:
      //     "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },

    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Kreocase}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && (
                          <p>
                            {/* {section.subcontent} */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: section.subcontent,
                              }}
                            />
                          </p>
                        )}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>
                          {/* {section.content} */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: section.content,
                            }}
                          />
                        </p>
                        {section.content3 && (
                          <p>
                            {/* {section.content3} */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: section.content3,
                              }}
                            />
                          </p>
                        )}
                        {section.list1 && section.list2 && section.list3 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && (
                                <p>
                                  {/* {section.list1} */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: section.list1,
                                    }}
                                  />
                                </p>
                              )}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list3 && <p>{section.list3}</p>}
                            </li>
                          </ul>
                        )}
                        {section.submorecontent && (
                          <p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: section.submorecontent,
                              }}
                            />
                            {section.content4 && (
                              <p>
                                {/* {section.content4} */}
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: section.content4,
                                  }}
                                />
                              </p>
                            )}
                            {/* dangerouslySetInnerHTML={{
                            __html: section.submorecontent,
                          }}{section.submorecontent} */}
                          </p>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KreoCaseStudiesInner;
