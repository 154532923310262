import React from "react";
import { Influencer1,Influencer2,Influencer3,Influencer4,Influencer5,Influencer6 } from "../../components/IconsImport";

const MyinfluencerCard = () => {
  const brandsData = [
    {
      icon: Influencer1,
      title: "Audience-Centric Collaborations",
      description:
        "Why depend on expensive agents or wait for brands to approach you? We offer collaboration opportunities with brands centred around content your audience loves to watch.",
    },
    {
      icon: Influencer2,
      title: "Transparent Compensation",
      description:
        "Earn money by filming reels and experiences, basically what you love. Experience a transparent system where you are duly compensated for your efforts. We prioritise clear and efficient financial transactions.",
    },
    {
      icon: Influencer3,
      title: "Work with Freedom",
      description:
        "It’s just you and your creative bones. We deal with contract negotiations, allowing you to focus solely on content creation and no logistics.",
    },
    {
      icon: Influencer4,
      title: "Recognition Beyond Instagram",
      description:
        "Gain recognition for your creativity and impact through our platform. Stand out from the crowd, and let your influence speak volumes as we feature influencers on our various channels.",
    },
    {
      icon: Influencer5,
      title: "Professional Guidance",
      description:
        "Influencers registered with us are guided professionally to ensure their content aligns well with the brand’s values. Through our regular feedback, influencers achieve heightened success in their content campaigns.",
    },
    {
      icon: Influencer6,
      title: "Access to Free Collabs",
      description:
        "We understand how much effort influencers make to create content. Thus we don’t charge any subscription fee from our creators to gain access to brand collaborations. We help you find the best opportunities catering to your professional growth.",
    },
   
  ];

  return (
    <>
      {brandsData.map((brand, index) => (
        <div
          className={`brands_container ${index % 2 === 0 ? "even" : "odd"}`}
          key={index}
        >
          <div className={`brands_icon ${index % 2 === 0 ? "even" : "odd"}`}>
            <img src={brand.icon} alt="brand icon" />
          </div>
          <h1 >{brand.title}</h1>
          <p>{brand.description}</p>
        </div>
      ))}
    </>
  );
};

export default MyinfluencerCard;
