import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  Aani,
  Aashi,
  Akshitarora,
  CAseimages,
  Caseimages,
  Caseresult,
  Chalwahankhaatehai,
  Coverelemnt,
  Covervideoimage,
  Foodieboy,
  Foodjunction,
  Foodsearch,
  Foodtravls,
  Gunjan,
  Hanshika,
  Hazel,
  Innsightimagedkava,
  Innsightimageelemnt,
  Ishita,
  Jessmeet,
  Littleflower,
  Mayank,
  Mnyanka,
  Muskan,
  Playbutton,
  Pr003,
  Prachi,
  Sanyavig,
  Shuriti,
  Tastingglitch,
  Tinyshree,
  Vegpic,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const SerekoCaseStudiesInnerPhase2 = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d67",
    title: "Phase 2: Sereko’s E-Commerce Growth Through Multi-Product Integration",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "E-COMMERCE",
    content: [
      {
        heading: "Campaign Recap",
        subcontent:
          "In Phase 2 of Sereko’s campaign, we continued our mission to lift brand awareness and build connections with their target audience. Moreover, we aimed to improve Sereko's visibility in the competitive skincare market. Post our execution in Phase 1, we actioned our initiatives to stabilize Sereko for e-commerce growth opportunities.",
    
    
          // submorecontent:
          // "The client was looking for a professional organisation who could understand his e-commerce business and help him in strengthening Elemnt’s awareness and sales conversion.",
      },
      {
        heading: "Phase 2 Objectives",
        content:
        "With brands rat racing behind sales without any prior goals, our focus was to gain our audience’s attention and differentiate Sereko from the crowd.",
        list1:
        "Incorporate Sereko’s products which were not promoted in Phase 1.",
      list2:
        "Increase the number of orders for both new and existing products.",
      list3:
        "To expand Sereko’s market presence by pitching an even wider selection of Sereko’s products.",
      },
      {
        heading: "Solution",
        subcontent:
          "The execution team created a strategy to promote Sereko’s skincare products on social media platforms. The outreach method we employed involved cold calling and messaging our target audience directly. This way, we introduced them to Sereko's skincare products and got them talking about the brand.",
        content:
          "Additionally, this phase was focused on encouraging customers to share positive reviews and feedback while staying compliant with Amazon review policy. In the phase 2 of the campaign, the brand experienced an upsurge in its Amazon search ranking. Since our promotional strategy brought in more clicks and new customers, Sereko’s seller rank increased, and brand image was lifted.",

          content3:
          "What's more, some ecstatic customers went above and beyond, sharing their experiences through video stories on their Instagram handles. These user-generated videos not only added a personal touch but also amplified Sereko's visibility and credibility.",
          
      },
    
      {
        heading: "Results",
        subcontent:
          "The focus on gaining audience attention and differentiating Sereko from competitors was successful. Our marketing strategy led to an upsurge in Sereko’s Amazon search ranking, improving its seller rank and increasing visibility to potential buyers. By promoting the products effectively through social media and direct outreach to potential buyers, Sereko managed to catch the attention of a wider audience interested in skincare.",
   
        
      
      },
      // {
      //   heading: "Results",
      //   content:
      //     "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },
    
      
    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Coverelemnt}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}
                      

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && section.list3 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                          <li className="list_disc_ul">  {section.list3 && <p>{section.list3}</p>}</li>
                        </ul>
                        )}
                        {section.submorecontent && <p>{section.submorecontent}</p>}

                      </div>
                    ))}
                    
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SerekoCaseStudiesInnerPhase2;
