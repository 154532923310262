import React, { useState, useEffect } from "react";
import {
  Amp,
  Auravedic,
  Awearbeauty,
  Beautilicious,
  Beauty,
  Bioderma,
  Bmore,
  Boletobdapav,
  Brandfuzz,
  Cambaytiger,
  Carefreak,
  Choks,
  Cupji,
  Dailyscoop,
  Delizia,
  Dennison,
  Denver,
  Dkava,
  Dvija,
  Elemntt,
  Ember,
  Estelar,
  Feelmetown,
  Fiore,
  Fixderma,
  Foreverlivingbrand,
  Fytika,
  Globlenow,
  Glowrico,
  Gopappe,
  Gree,
  Gujaralandsons,
  Gxn,
  Healther,
  Homearrow,
  InaraBrand,
  Indeekoss,
  Kapiva,
  Karrot,
  Kewah,
  Kreo,
  Lakme,
  Leaf,
  Mallufarms,
  Mars,
  Momomia,
  N4NBrand,
  nATURENECTAR,
  Niyama,
  Nuvibliss,
  Otrix,
  Plantbro,
  Prezerve,
  r,
  Section1,
  Section21,
  Serekoonew,
  Setu,
  Sheikchang,
  Superbottom,
  Tacbrand,
  Tajhhair,
  Totem,
  Touche,
  Unspoiled,
  Vaidrishi,
  Wellvart,
  Yellowball,
  Yplayz,
  Zinklondon,
} from "../../components/IconsImport";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import OurClientsThink from "../../common/Campaigncards/OurClientsThink";
import InfluencerCards from "../../common/Campaigncards/InfluencerCards";
import WhyToWorkSection from "./WhyToWorkSection";
import WeHaveOffer from "./WeHaveOffer";
import InfluencersSection from "./InfluencersSection";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Slider from "react-slick";

const Home = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const navigateToContacts = () => {
    navigate("/contactus");
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    // dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 0,
    speed: 4000,
    autoplay: true,

    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderSettingsLtl = {
    dots: false,
    arrows: false,
    // dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 100,
    speed: 4000,
    autoplay: true,
    rtl: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const logoImages = [
    { src: Totem, alt: "images" },
    { src: nATURENECTAR, alt: "images" },
    { src: Lakme, alt: "images" },
    { src: Beauty, alt: "images" },
    { src: InaraBrand, alt: "images" },
    { src: Indeekoss, alt: "images" },
    { src: N4NBrand, alt: "images" },
    { src: Cambaytiger, alt: "images" },
    { src: Tajhhair, alt: "images" },
    { src: Dennison, alt: "images" },
    { src: Denver, alt: "images" },
    { src: Fytika, alt: "images" },
    { src: Amp, alt: "images" },
    { src: Kewah, alt: "images" },
    { src: Niyama, alt: "images" },
    { src: Prezerve, alt: "images" },
    { src: Setu, alt: "images" },
    { src: Touche, alt: "images" },
    { src: Vaidrishi, alt: "images" },
    { src: Zinklondon, alt: "images" },
    { src: Dkava, alt: "images" },
    { src: Healther, alt: "images" },
    { src: Bioderma, alt: "images" },
  ];
  const logoImagesLtl = [
    { src: Plantbro, alt: "images" },
    { src: Auravedic, alt: "images" },
    { src: Brandfuzz, alt: "images" },
    { src: Carefreak, alt: "images" },
    { src: Delizia, alt: "images" },
    { src: Gxn, alt: "images" },
    { src: Elemntt, alt: "images" },
    { src: Momomia, alt: "images" },
    { src: Otrix, alt: "images" },
    { src: Gopappe, alt: "images" },
    { src: Mars, alt: "images" },
    { src: Serekoonew, alt: "images" },
    { src: Estelar, alt: "images" },
    { src: Tacbrand, alt: "images" },
    { src: Kreo, alt: "images" },
    { src: Nuvibliss, alt: "images" },
    { src: Foreverlivingbrand, alt: "images" },
    { src: Dailyscoop, alt: "images" },
    { src: Fiore, alt: "images" },
    { src: Ember, alt: "images" },
    { src: Yplayz, alt: "images" },
  ];
  const logoImagesRtl = [
    { src: Wellvart, alt: "images" },
    { src: Kapiva, alt: "images" },
    { src: Karrot, alt: "images" },
    { src: Awearbeauty, alt: "images" },
    { src: Fixderma, alt: "images" },
    { src: Bmore, alt: "images" },
    { src: Boletobdapav, alt: "images" },
    { src: Dvija, alt: "images" },
    { src: Feelmetown, alt: "images" },
    { src: Globlenow, alt: "images" },
    { src: Glowrico, alt: "images" },
    { src: Leaf, alt: "images" },
    { src: Superbottom, alt: "images" },
    { src: Mallufarms, alt: "images" },
    { src: Gujaralandsons, alt: "images" },
    { src: Sheikchang, alt: "images" },
    { src: Yellowball, alt: "images" },
    { src: Gree, alt: "images" },
    { src: Unspoiled, alt: "images" },
    { src: Choks, alt: "images" },
    { src: Cupji, alt: "images" },
  ];

  return (
    <>
      <Helmet>
        <title>Marqeting - Connecting Brands with Trusted Influencers</title>

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="robots" content="index,follow" />

        <meta
          name="og:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="og:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta name="og:url" content="https://www.marqeting.in/" />

        <meta name="og:site_name" content="Marqeting" />

        <meta
          name="og:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="og:keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="twitter:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="twitter:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="description"
          content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
        />
      </Helmet>
      <div className="container_homefluid">
        <div className="hcontainer">
          <div className="home_section_container">
            <div className="home_section_inner_content">
              <h1>Connecting Brands With Trusted Voices</h1>
              <p>
                Our Tech-enabled marketing platform connects you with the
                industry’s most trusted influencers, maximising brand’s
                visibility and return on investment. By aligning your brand with
                influential voices, we drive genuine engagement and shape your
                brand's identity.
              </p>
              <div className="coonect_button">
                {/* <button
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() =>
                    window.open(
                      "https://forms.microsoft.com/r/XEvCVPJ3Mm",
                      "_blank"
                    )
                  }
                  className={isHovered ? "brandbutton hover" : "brandbutton"}
                >
                  I am a Brand
                </button>
                <button
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={
                    isHovered
                      ? "connect_button_inner influencer_border hover"
                      : "connect_button_inner influencer_border"
                  }
                  onClick={() =>
                    window.open(
                      "https://forms.microsoft.com/r/R0MDNd7mwM",
                      "_blank"
                    )
                  }
                >
                  I am an Influencer
                </button> */}
                <button
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={isHovered ? "brandbutton hover" : "brandbutton"}
                  onClick={() => navigate("/brands-enquiry")}
                >
                  I am a Brand
                </button>

                <button
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={
                    isHovered
                      ? "connect_button_inner influencer_border hover"
                      : "connect_button_inner influencer_border"
                  }
                  onClick={() => navigate("/influencers-onboarding")}
                >
                  I am an Influencer
                </button>
              </div>
            </div>
            <div className="home_section_right">
              <img src={Section1} alt="home image" />
            </div>
          </div>
        </div>
      </div>

      {/*   
  <div className="brand_collebcontainer">
    <div className="hcontainer">
      <Slider {...sliderSettings}>
        {logoImages.map((image, index) => (
          <div key={index} className="brand_images">
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </Slider>
    </div>
  </div> */}
      <div className="achievements_container">
        <div className="hcontainer">
          <h1 className="achievement_heading">
            Empowering Brands through Influencer-Led Strategies
          </h1>
          <div className="achievements_inner">
            <div className="achievements_inner_content">
              <h1>25k+</h1>
              <p>Influencers</p>
            </div>
            <div className="achievements_inner_content">
              <h1>25M+</h1>
              <p>Audience</p>
            </div>
            <div className="achievements_inner_content">
              <h1>257M+</h1>
              <p>Reach</p>
            </div>
          </div>
        </div>
      </div>

      <div className="brand_collebcontainer">
        <div className="hcontainer">
          <h1 className="top_brandcollebr_heading">Some Of Our Clients</h1>
          <Slider {...sliderSettings}>
            {logoImages.map((image, index) => (
              <div key={index} className="brand_images">
                <img src={image.src} alt={image.alt} />
              </div>
            ))}
          </Slider>
          <div style={{ marginTop: "20px" }}>
            <Slider {...sliderSettingsLtl}>
              {logoImagesLtl.map((image, index) => (
                <div key={index} className="brand_images">
                  <img src={image.src} alt={image.alt} />
                </div>
              ))}
            </Slider>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Slider {...sliderSettings}>
              {logoImagesRtl.map((image, index) => (
                <div key={index} className="brand_images">
                  <img src={image.src} alt={image.alt} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div id="whytowork" className="container_work_fluid">
        <div className="hcontainer">
          <WhyToWorkSection />
        </div>
      </div>
      <div id="whatweoffer" className="container_hfluid">
        <div className="hcontainer">
          <WeHaveOffer />
        </div>
      </div>

      <div id="influencers" className="our_top_influencers_container">
        <div className="hcontainer">
          <InfluencersSection />
        </div>
      </div>

      <div className="container_hfluid">
        <div className="hcontainer">
          <div className="our_client_think">
            <h1>What Our Clients Think</h1>
            <div className="our_client_think_flex">
              <OurClientsThink />
            </div>
          </div>
        </div>
      </div>
      <div className="work_together_container">
        <div className="hcontainer">
          <div className="work_together_inner">
            <h1>Lets Work Together</h1>
            <p>Focus on what you do best and let us experts do the rest</p>
            <div className="coonect_button">
              <button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={navigateToContacts}
                style={{ border: "2px solid #ffffff", marginRight: "20px" }}
                className={isHovered ? "brandbutton hover" : "brandbutton"}
              >
                Contact Us
              </button>

              <a
                href="https://chat.whatsapp.com/CB48qb8tadhC8rCH1KuHYq"
                target="_blank"
              >
                <button
                  style={{ border: "2px solid #ffffff" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className={
                    isHovered
                      ? "connect_button_inner influencer_border hover"
                      : "connect_button_inner influencer_border"
                  }
                >
                  Join Our Community
                </button>
              </a>
            </div>
            {/* <div className="work_together_buuton">
              <button onClick={navigateToContacts}>Contact Us</button>
              <a
                href="https://chat.whatsapp.com/LV3U5rHez7a8hMZYzKvOZY"
                target="_blank"
              >
                <button className="join_button">Join Our Community</button>
              </a>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className={`top_icon_scroll ${isHovered ? "hover" : ""}`}
        onClick={scrollToTop}
      >
        <ArrowUpwardIcon
          sx={{
            background: "#163e6e",
            color: "#FFFFFF",
            width: "45px",
            height: "45px",
            borderRadius: "50px",
          }}
        />
        {/* <img src={Homearrow} alt="icon" className="arrow-icon" /> */}
      </div>
    </>
  );
};

export default Home;
