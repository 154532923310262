import React from "react";
import styled from "@emotion/styled";
import { OutlinedInput } from "@mui/material";

const InputLabel = styled.label`
  color: #163e6e;
  font-size: 16px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
`;

const CustomInputLabel = ({ children }) => (
  <InputLabel>{children}</InputLabel>
);

export default CustomInputLabel;

export   const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #F2F8FD",
        height: "48px",
        borderRadius: "4px",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #DDD",
      },
    },
  }));