import React, { useState } from 'react'
import BrandsCard from '../../common/Campaigncards/brandsCard';
import MyinfluencerCard from '../../common/Campaigncards/MyinfluencerCard';

const WeHaveOffer = () => {
    const [selected, setSelected] = useState("brands");
  return (
    <div className="container_hfluid">
    <div className="hcontainer">
      <div className="we_have_offer_container">
        <h1 className="have_offer_heading">What We Have To Offer</h1>
        <div className="we_have_offer_button">
          <button
            className={`brands_button brand_border ${
              selected === "brands" ? "selected" : ""
            }`}
            onClick={() => setSelected("brands")}
          >
            {selected === "influencers" ? "For Brands" : "For Brands"}
          </button>
          <button
            className={`brands_button influencer_border ${
              selected === "influencers" ? "selected" : ""
            }`}
            onClick={() => setSelected("influencers")}
          >
            {selected === "brands" ? "For Influencers" : "For Influencers"}
          </button>
        </div>
        <div className="we_have_offer_inner">
          {selected === "brands" ? <BrandsCard /> : <MyinfluencerCard />}
        </div>
      </div>
    </div>
  </div>
  )
}

export default WeHaveOffer
