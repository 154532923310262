import React from "react";
import {  Achievement, Brand1,Brand2,Brand3,Brand4,Brand5,Brand6, Holiday, Link, Wallet, WorkingTime} from "../../components/IconsImport";

const CareersCards = () => {
  const brandsData = [
    {
      icon: WorkingTime,
      title: "Flexible work arrangements",
      description:
        "While our office operates in an on-site setup, our employees have the option to work remotely one or two days a month.",
    },
    {
      icon: Wallet,
      title: "On-Time Salary Credits",
      description:
        "We make sure that salaries are paid punctually and reliably. You can count on receiving your paycheck on time, every time!",
    },
    {
      icon: Achievement,
      title: "Employee Recognition",
      description:
"If your achievements stand out, you could receive vouchers, certifications, appreciation awards, or even bonuses. This initiative is our way of rewarding your hard work and excellence. After all, who doesn’t love crashing their LinkedIn in a good way!"    },
    {
      icon: Holiday,
      title: "Paid Leave Allowance",
      description:
        "Inclusion of up to 18 days of paid leaves in a year. You can complete your other tasks and manage personal commitments with these extra paid leaves in hand.",
    },
    {
      icon: Brand5,
      title: "Work on high-end client projects",
      description:
        "Our existing employees get a chance to work for our renowned B2B and D2C brands. A chance to skill up and build a portfolio ft. contributions and expertise on these projects.",
    },
    {
      icon: Link,
      title: "Team-Building Activities",
      description:
        "We organize fun games and activities, usually on weekends. This helps our employees come back to work on Mondays feeling refreshed and free from the Monday blues.",
    },
  ];

  return (
    <>
      {brandsData.map((brand, index) => (
        <div
          className={`careers_container ${index % 2 === 0 ? "even" : "odd"}`}
          key={index}
        >
          <div className={`careers_icon ${index % 2 === 0 ? "even" : "odd"}`}>
            <img src={brand.icon} alt="brand icon" />
          </div>
          <div className="careers_cards">
          <h1 >{brand.title}</h1>
          <p>{brand.description}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default CareersCards;
