import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import { Coverelemnt, Playbutton, Innsightimageelemnt, Wellvartcase } from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const WellvartCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d70",
    title:
      "Driving Dental Consumers’ Loyalty and Brand Engagement for Wellvart",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "E-COMMERCE",
    content: [
      {
        headingContent: "A flosser a day keeps the dentist away!",
      },
      {
        heading: "Campaign Highlights",
        subcontent:
          "When Wellvart Enterprises, a trusted brand in personal care, approached us to raise awareness for their dental flosses on e-commerce platforms, we were raring to go at the drop of a hat.",

        content:
          "Dental floss should be easy to use, gentle on gums, and effective at removing food particles and plaque between teeth. Wellvart never compromises on the quality and effectiveness of its dental flosses. The brand provides its customers with the best dental care solutions to keep them feeling refreshed.",
        submorecontent:
          "Now more than ever before, consumers are becoming increasingly aware of the importance of safe oral and dental practices. Wellvart understands this shift and is dedicated to providing dental floss that meets the highest standards of quality and safety, helping customers maintain their dental health with confidence.",
      },
      {
        heading: "Campaign Objectives",
        list1:
        "Get Wellvart's products showing up higher in search results when people are shopping online for dental floss.",
      list2:
        "Make their product listings grab buyers’ attention on trending e-commerce sites like Shopsy and Flipkart.",
   
      },
  
      {
        heading: "Execution and Methodology",
        subcontent:
          "Marqeting identified Shopsy and Flipkart Marketing as a performance medium to help Wellvart increase brand discoverability and customer engagement.",
        content:
          "To kick start Wellvart’s campaign, we decided to focus on organic customer engagement and building loyal brand advocates. Our team conducted a thorough keyword research to locate the most relevant and high-volume search terms used by potential customers.",

        content3:
          "To add on, we verified the eligibility of customers' accounts by ensuring they met the platform's criteria for leaving ratings on Shopsy or Flipkart. This typically involved confirming that the accounts were genuine, active, and compliant with the platforms' terms of service. We checked factors such as account creation date, purchase history, and activity level to make sure they met the necessary requirements for leaving feedback.",
        submorecontent:
          "Testimonials from valued customers, including those with dental issues helped to push sales and increase Wellvart’s brand engagement. This was achieved through post-purchase social media outreach.",
    
      },

      {
        heading: "Results",
        subcontent:
          "Marqeting's approach to improve their client’s brand engagement and loyalty in the dental care market led to impressive outcomes. By using popular e-commerce platforms likeShopsy and Flipkart, Wellvart gained more attention from customers and met its campaign goals effectively. We focused on getting Wellvart's products to show up higher in online searches and stand out on these platforms. As a result, Wellvart's brand got more recognition, sales went up, and people trusted it more for their dental needs.",
      },
      // {
      //   heading: "Results",
      //   content:
      //     "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },

    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Wellvartcase}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.headingContent && (
                          <h1
                            style={{ textAlign: "center", marginTop: "10px" }}
                          >
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && <p>{section.list1}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                          
                          </ul>
                        )}
                        {section.submorecontent && (
                          <p>{section.submorecontent}</p>
                        )}
                        {section.content4 && <p>{section.content4}</p>}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellvartCaseStudiesInner;
