import React, { useState } from "react";
import MyStepperInfluncers from "../../components/steper/SteperInfluncers";

const InfluencersForm = () => {
  
  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
        <div className="we_have_solution_container">

       <MyStepperInfluncers/>
        </div>
      </div>
      </div>
    </>
  );
};

export default InfluencersForm;
