import React,{useState} from "react";
import { Ashianand,Naveen,Anita, Ayushsmita,Nehanirwal,Charu, Harsh, Instagram, Kajal, Muskangupta, Nilakshi, Payal, Piyanshi, Rajrupsaha, Riya, Samarthahirrao, Shristi, Shrutisharma, Sreyabiswas, Sushma, Tamzinakhan, Spana ,Mrmrsdeb,Mira,Debanshimadhurima, Mhimakavi, Muskanghambir, SuniTee, Fagunimadhiani, Ishabansall, Harsh_I, AkshatI, JavedI, Cheshta, Kajal_I, DivyaI, VaishaliI, PranjaliI, ShivaliI, ArjunI, HemantI, Saba, Laviyadav, PriyankaI, SatarupalI, FlorenceI} from "../../components/IconsImport";
import Slider from "react-slick";


const InfluencerCards = () => {
    // const [showAll, setShowAll] = useState(false);

  const influencersData = [

    {
      name: "Divya Gupta",
      profession: "Entertainment Influencer",
      image: DivyaI,
      platforms: [
        {
          platform: Instagram,
          followers: "445k+",
          profileUrl: "https://www.instagram.com/divyagupta7811/"

        },
      
      ]
    },
 
    {
      name: "Harsh Singh",
      profession: "Fitness Influencer",
      image: Harsh_I,
      platforms: [
        {
          platform: Instagram,
          followers: "117k+",
          profileUrl: "https://www.instagram.com/unstoppable_harshsgh?igsh=ZnQyamU1ZmtvNTU2"

        },
      
      ]
    },
    {
      name: "Shivali Rijhwani",
      profession: "Lifestyle Influencer",
      image: ShivaliI,
      platforms: [
        {
          platform: Instagram,
          followers: "602k+",
          profileUrl: "https://www.instagram.com/shivali96/"

        },
      
      ]
    },
    {
      name: "Akshat Mathur",
      profession: "Fitness Influencer",
      image: AkshatI,
      platforms: [
        {
          platform: Instagram,
          followers: "178k+",
          profileUrl: "https://www.instagram.com/akshat_xesthetix/"

        },
      
      ]
    },
    {
      name: "Shamsz Sayed",
      profession: "Fitness Influencer",
      image: JavedI,
      platforms: [
        {
          platform: Instagram,
          followers: "199k+",
          profileUrl: "https://www.instagram.com/shamszfit/?igshid=p8ztruyj45s4"

        },
      
      ]
    },
    {
      name: "Cheshta Kapoor",
      profession: "Fashion Influencer",
      image: Cheshta,
      platforms: [
        {
          platform: Instagram,
          followers: "129k+",
          profileUrl: "https://www.instagram.com/cheshtakapoor"

        },
      
      ]
    },
 
    {
      name: "Kajal Arora",
      profession: "Skincare Influencer",
      image: Kajal_I,
      platforms: [
        {
          platform: Instagram,
          followers: "129k+",
          profileUrl: "https://www.instagram.com/hookedtoskincare/"

        },
      
      ]
    },

 
    {
      name: "Vaishali Attri",
      profession: "Lifestyle Influencer",
      image: VaishaliI,
      platforms: [
        {
          platform: Instagram,
          followers: "173k+",
          profileUrl: "https://www.instagram.com/vaishaliattri/"

        },
      
      ]
    },
 
    {
      name: "Pranjali",
      profession: "Lifestyle Influencer",
      image: PranjaliI,
      platforms: [
        {
          platform: Instagram,
          followers: "61.6k+",
          profileUrl: "https://www.instagram.com/pranjalii23/?igshid=YmMyMTA2M2Y%3D"

        },
      
      ]
    },
  
    {
      name: "Arjun Chadha",
      profession: "Fitness Influencer",
      image: ArjunI,
      platforms: [
        {
          platform: Instagram,
          followers: "94.2K+",
          profileUrl: "https://www.instagram.com/arjunchadha7/#"

        },
      
      ]
    },
    {
      name: "Hemant Kaur",
      profession: "Lifestyle Influencer",
      image: HemantI,
      platforms: [
        {
          platform: Instagram,
          followers: "110k+",
          profileUrl: "https://www.instagram.com/grooming_guruji/?igshid=YmMyMTA2M2Y%3D"

        },
      
      ]
    },
    {
      name: "Saba Tabassum",
      profession: "Lifestyle Influencer",
      image: Saba,
      platforms: [
        {
          platform: Instagram,
          followers: "195k+",
          profileUrl: "https://www.instagram.com/saba_ki_suno/reels/"

        },
      
      ]
    },
 
    {
      name: "Lavi Yadav",
      profession: "Skincare Influencer",
      image: Laviyadav,
      platforms: [
        {
          platform: Instagram,
          followers: "100k+",
          profileUrl: "https://www.instagram.com/thatgirlin_town/"

        },
      
      ]
    },
    {
      name: "Priyanka Tiwari",
      profession: "Skincare Influencer",
      image: PriyankaI,
      platforms: [
        {
          platform: Instagram,
          followers: "30.8k+",
          profileUrl: "https://www.instagram.com/iam_priyankatiwari_/"

        },
      
      ]
    },
    {
      name: "Satarupa Paul",
      profession: "Fashion & Beauty Influencer",
      image: SatarupalI,
      platforms: [
        {
          platform: Instagram,
          followers: "40k+",
          profileUrl: "https://www.instagram.com/thecrazychique/"

        },
      
      ]
    },
    {
      name: "Florence",
      profession: "Beauty Influencer",
      image: FlorenceI,
      platforms: [
        {
          platform: Instagram,
          followers: "57.2k+",
          profileUrl: "https://www.instagram.com/justflow110/"

        },
      
      ]
    },
    {
      name: "ISHA BANSAL",
      profession: "Lifestyle Influencer",
      image: Ishabansall,
      platforms: [
        {
          platform: Instagram,
          followers: "112k+",
          profileUrl: "https://www.instagram.com/theglamisha/"

        },
      
      ]
    },
    {
      name: "Sunitee Siddiqui",
      profession: "Lifestyle Influencer",
      image: SuniTee,
      platforms: [
        {
          platform: Instagram,
          followers: "82k+",
          profileUrl: "https://www.instagram.com/_hotmess_mama/"

        },
      
      ]
    },
    {
      name: "Faguni Madhiani",
      profession: "Lifestyle Influencer",
      image: Fagunimadhiani,
      platforms: [
        {
          platform: Instagram,
          followers: "60.8k+",
          profileUrl: "https://www.instagram.com/beautysecretsbyfaguni/"

        },
      
      ]
    },
    // {
    //   name: "Muskan Gambhir",
    //   profession: "MAKEUP & BEAUTY",
    //   image: Muskanghambir,
    //   platforms: [
    //     {
    //       platform: Instagram,
    //       followers: "25.8k+",
    //       profileUrl: "https://www.instagram.com/mus.kayyy/"

    //     },
      
    //   ]
    // },
    {
      name: "Mahima & Kavi",
      profession: "Food Influencer",
      image: Mhimakavi,
      platforms: [
        {
          platform: Instagram,
          followers: "22.5k+",
          profileUrl: "https://www.instagram.com/lifeofpaetoo/"

        },
      
      ]
    },
    {
      name: "Anita Singh",
      profession: "Lifestyle Influencer",
      image: Anita,
      platforms: [
        {
          platform: Instagram,
          followers: "612k+",
          profileUrl: "https://www.instagram.com/anitapratapsingh/"

        },
      
      ]
    },
    {
      name: "Neha Nirwal",
      profession: "Fashion Influencer",
      image: Nehanirwal,
      platforms: [
        {
          platform: Instagram,
          followers: "351k+",
          profileUrl: "https://www.instagram.com/thenehanirwal/"

        },
      
      ]
    },
    {
      name: "Naveen Sharma",
      profession: "Lifestyle Influencer",
      image: Naveen,
      platforms: [
        {
          platform: Instagram,
          followers: "278k+",
          profileUrl: "https://www.instagram.com/thisizznaveen/"

        },
      
      ]
    },
    {
      name: "Riya Sharma",
      profession: "Fashion Influencer",
      image: Riya,
      platforms: [
        {
          platform: Instagram,
          followers: "230k+",
          profileUrl: "https://instagram.com/x.riyaaa?igshid=YTQwZjQ0NmI0OA=="

        },
      
      ]
    },
    {
      name: "Mira Begum",
      profession: "Lifestyle Influencer",
      image: Mira,
      platforms: [
        {
          platform: Instagram,
          followers: "201k+",
          profileUrl: "https://www.instagram.com/miruu_sheikh/"

        },
      
      ]
    },
    {
      name: "Mr. & Mrs. Deb",
      profession: "Comedy Influencer",
      image: Mrmrsdeb,
      platforms: [
        {
          platform: Instagram,
          followers: "206k+",
          profileUrl: "https://www.instagram.com/comedy_couple007/"

        },
      
      ]
    },
    {
      name: "Sreya Biswas",
      profession: "Lifestyle Influencer",
      image: Sreyabiswas,
      platforms: [
        {
          platform: Instagram,
          followers: "170k+",
          profileUrl: "https://instagram.com/_sreyabiswas_?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Sushma",
      profession: "Fashion Influencer",
      image: Sushma,
      platforms: [
        {
          platform: Instagram,
          followers: "156k+",
          profileUrl: "https://instagram.com/_itssushma?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Sapna Rathore",
      profession: "lifestyle Influencer",
      image: Spana,
      platforms: [
        {
          platform: Instagram,
          followers: "147k+",
          profileUrl: "https://www.instagram.com/sapna_rathore_/"

        },
      
      ]
    },
    {
      name: "Charu Choudhary",
      profession: "lifestyle Influencer",
      image: Charu,
      platforms: [
        {
          platform: Instagram,
          followers: "147k+",
          profileUrl: "https://www.instagram.com/charuchoudhary__/?img_index=1"

        },
      
      ]
    },
    {
      name: "Ayushsmita Santra",
      profession: "Lifestyle Influencer",
      image: Ayushsmita,
      platforms: [
        {
          platform: Instagram,
          followers: "131k+",
          profileUrl: "https://instagram.com/_clouds.of.monsoon_?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Ashi Anand",
      profession: "Lifestyle Influencer",
      image: Ashianand,
      platforms: [
        {
          platform: Instagram,
          followers: "146k+",
          profileUrl: "https://instagram.com/_ashianand13?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Debanshi Madhurima",
      profession: "Fashion Influencer",
      image: Debanshimadhurima,
      platforms: [
        {
          platform: Instagram,
          followers: "126k+",
          profileUrl: "https://www.instagram.com/debanshimadhurima/"
        },
      
      ]
    },
    {
      name: "Samarth Ahirrao",
      profession: "Lifestyle Influencer",
      image: Samarthahirrao,
      platforms: [
        {
          platform: Instagram,
          followers: "119k+",
           profileUrl: "https://instagram.com/samarthahirrao?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Kajal Madaan",
      profession: "Lifestyle Influencer",
      image: Kajal,
      platforms: [
        {
          platform: Instagram,
          followers: "117k+", profileUrl: "https://instagram.com/kajalvsrajput?igshid=YTQwZjQ0NmI0OA=="
        },
     
      ]
    },
  
   
    {
      name: "Priyanshi Goel",
      profession: "Food Influencer",
      image: Piyanshi,
      platforms: [
        {
          platform: Instagram,
          followers: "107k+",
          profileUrl: "https://instagram.com/foodiesincechildhood?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Harsh",
      profession: "Food Influencer",
      image: Harsh,
      platforms: [
        {
          platform: Instagram,
          followers: "109k",
          profileUrl: "https://instagram.com/foodie_by_mind?igshid=YTQwZjQ0NmI0OA=="
        },
     
      ]
    },
    {
      name: "Tamzina Khan",
      profession: "Beauty Influencer",
      image: Tamzinakhan,
      platforms: [
        {
          platform: Instagram,
          followers: "113k+",
          profileUrl: "https://instagram.com/tamzina_khan?igshid=YTQwZjQ0NmI0OA=="
        },
     
      ]
    },
   
    {
      name: "Shruti Sharma",
      profession: "Lifestyle Influencer",
      image: Shrutisharma,
      platforms: [
        {
          platform: Instagram,
          followers: "105k+",
          profileUrl: "https://instagram.com/shrutiilecious?igshid=YTQwZjQ0NmI0OA=="
        },
     
      ]
    },
   
    {
      name: "Rajrup Saha",
      profession: "Lifestyle Influencer",
      image: Rajrupsaha,
      platforms: [
        {
          platform: Instagram,
          followers: "103k+",
          profileUrl: "https://instagram.com/kajalvsrajput?igshid=YTQwZjQ0NmI0OA=="
        },
      
      ]
    },
    {
      name: "Muskan Gupta",
      profession: "Food Influencer",
      image: Muskangupta,
      platforms: [
        {
          platform: Instagram,
          followers: "108k+",
          profileUrl: "https://www.instagram.com/chatpatidaawat/?hl=en"
        },
     
      ]
    },
  
    // {
    //   name: "Nilakshi",
    //   profession: "Beauty Influencer",
    //   image: Nilakshi,
    //   platforms: [
    //     {
    //       platform: Instagram,
    //       followers: "55.2k+",
    //       profileUrl: "https://www.instagram.com/nilaksheesarma/"
    //     },
      
    //   ]
    // },
    // {
    //   name: "Shristi",
    //   profession: "Fashion Influencer",
    //   image: Shristi,
    //   platforms: [
    //     {
    //       platform: Instagram,
    //       followers: "18.7k+",
    //       profileUrl: "https://www.instagram.com/_shristi.kumawat_/"
    //     },
      
    //   ]
    // },
  ];
  // const displayedData = showAll ? influencersData : influencersData.slice(0, 12);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 5000,
    speed: 500,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
  return (
    <>
         <Slider {...settings}>

      {influencersData.map((influencer, index) => (
        <a href={influencer.platforms[0].profileUrl} target="_blank" rel="noopener noreferrer" key={index}>

        <div className="influencers_card_container">
          <div className="payal_images">
            <img src={influencer.image} alt="image" />
          </div>
          <div className="influencers_card_details">
          <a href={influencer.platforms[0].profileUrl} target="_blank" rel="noopener noreferrer">
        <h1>{influencer.name}</h1>
      </a>
            <p>{influencer.profession}</p>

            <div className="influencers_card_details_subinner_container">
              {influencer.platforms.map((platform, index) => (
                <div className="influencers_card_details_inner" key={index}>
                <a href={platform.profileUrl} target="_blank" rel="noopener noreferrer">
                      {" "}
                      <img src={platform.platform} alt="image" />
                    </a>
                  <div className="influencers_card_details_subinner">
                    <h1>{platform.followers}</h1>
                    <p>Followers</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>  </a>
      ))}
      </Slider>
      {/* {influencersData.length > 12 && (
        <div className="top_influencers_button">
          {showAll ? (
            <button onClick={() => setShowAll(false)}>Show Less of our influencers</button>
          ) : (
            <button onClick={() => setShowAll(true)}>See all of our influencers</button>
          )}
        </div>
      )}
    */}

    </>
  );
};

export default InfluencerCards;
