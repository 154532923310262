import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Carefrea,
  Carefreakresults,
  Caseaanknsha,
  Casedarsthi,
  Casedishii,
  Caseimages,
  Caseresult,
  Casesoniasah,
  Cashpurvising,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const CareFreakCaseStudiesInner = () => {
  const data =  {
    _id: "65aa4badd2fe5c4992081d73",
    title: "Inspiring Locked-Down Skin Lovers to Shop from Care Freak Online",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },

    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Campaign Brief",
        subcontent:
          "Care Freak is committed to being the most innovative and trusted brand in natural skincare. Customers are drawn to Care Freak because they use Tremella Mushroom in all their products, making it their special ingredient that sets them apart from other skincare brands.",
        content:
          "What makes Tremella Mushroom so special is its capacity to deeply hydrate and rejuvenate the skin. Care Freak understands this, and they've created a whole range of skincare products infused with Tremella Mushroom extract. From serums to cleansers, every product in their lineup is infused with this natural wonder to give customers healthy, glowing skin.",
        content2:
          "Customers trust Care Freak because they know they're getting products that are not only good for their skin but also backed by the power of natural ingredients.",
        content3:
          "We could talk on and on, but the truth is, once you try their skincare products, you'll find it hard to stop using them."
        },
      {
        heading: "Campaign Approach",
    
        subcontent:
        "With so many skincare brands in the market, Care Freak needed to find a way to grab the attention of its target customers.",
      content:
        "So, it decided to execute a targeted influencer marketing campaign on Instagram. The focus was to find creators in specific niches related to skincare, beauty and lifestyle.",
      content2:
        "The idea was to collect evergreen content from influencers and integrate it into the brand’s social channels. Moreover, the purpose of the campaign was to create awareness and brand engagement for Care Freak.",
      },
      {
        heading: "Execution",
        
        subHeadingnewe: "Together with Marqeting, Care Freak initiated a collaboration with Instagram influencers. The focus for this campaign was for the profiles to express how Care Freak infused Tremella Mushroom into their products and addressed skincare concerns like dehydration, dryness, inflammation, anti-aging, hyperpigmentation, among others.",
        content:
          "Care Freak made sure to be clear and teach people about Tremella Mushroom. The creative strategy was executed when influencers shared info about the science behind it and why it's good for healthy skincare.",
        content2:
          "Our campaign successfully featured Care Freak’s product creatively to different audiences. And they all came to agree on one common thing which was what their favorite skincare brand is!",
      },
      {
        heading: "The Outcome",
        subcontent:
          "The success of any campaign depends on how relatable viewers find it. We kept it authentic and genuine for the audience to engage with it.",
        content:
          "Before making a purchase, consumers look for what makes a brand unique compared to others. We did the same for Care Freak by having our influencers highlight the benefit of the brand’s products - it was the infusion of Tremella Mushroom in this approach. This clicked well with the targeted viewers, and they all agreed to count on Care Freak for every skincare purchase.",

          image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          imageUrl:
            "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          
      },

    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {
        url: "https://www.instagram.com/p/C5Qm6qTrikO/",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Caseaanknsha,
      },
      {
        url: "https://www.instagram.com/p/C5nl2iGh5uT/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Casedarsthi,
      },
      {
        url: "https://www.instagram.com/p/C5IQukjpni0/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Casedishii,
      },
      {
        url: "https://www.instagram.com/p/C4-jZmJJI_F/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Casesoniasah,
      },
      {
        url: "https://www.instagram.com/p/C5KoX-qojxW/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Cashpurvising,
      },
    
    ]
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Carefrea}
                  alt={data.title}
                />

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}

                        { section.subHeadingnewe && (
                      
                            <p className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </p>
                        )}
                  
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Carefreakresults}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                        </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareFreakCaseStudiesInner;
