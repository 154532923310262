import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import { Coverelemnt, Playbutton, Innsightimageelemnt, Nuviblisscase } from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const NuviblissCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d71",
    title:
      "Amazon Marketing Campaign Skyrockets Brand Visibility for Nuvibliss",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "E-COMMERCE",
    content: [
    
      {
        heading: "Campaign Overview",
        subcontent:
          "Nuvibliss, a multivitamin supplement brand, partnered with Marqeting to drive awareness for its products. The target audience included men and women across all age groups. The primary objective was to encourage product purchase on the largest e-commerce marketplace - Amazon.",

        content:
          "Nuvibliss, a brand with a growing reputation, eases the process of getting essential vitamins and nutrients. They offer multivitamin formulations for men and women to complement their dietary intake and reduce stress.",
   
      },
      {
        heading: "Challenges",
        list1:
        "To drive brand awareness and expand e-commerce market presence for Nuvibliss.",
      list2:
        "Improve search visibility of the brand’s multivitamins on Amazon.",
   
      },
  
      {
        heading: "Campaign Solution",
        subcontent:
          "Nuvibliss rolled out several campaigns in the past but was interested in employing a marketing approach that would increase their brand’s visibility on Amazon. So, when the client contacted the team at Marqeting to increase customer interest in purchasing their products, we rolled out a personalized e-commerce strategy for them.",
     
    
      },
  
      {
        heading: "Research and Target Market Analysis",
        subcontent:
          "The team collected qualitative and quantitative research data featuring Nuvibliss' target market audience.",
        content:
          "We cross-promoted the products of Nuvibliss among the target customers which included both men and women. The execution team worked on understanding the different product categories of Nuvibliss and gained insights about their unique selling proposition.",

        content3:
          "We managed the entire process of screening, outreaching, communicating and making payments directly with potential buyers. The buyers made the purchase only after being fully informed about the products that Marqeting tried to promote for Nuvibliss during the campaign.",
        submorecontent:
          "Because of its long-standing presence in the health and wellness space, the brand knew that buyers interested in buying their multivitamins would provide valuable feedback.",

          content4:
          "Using genuine WhiteHat Review strategies, Nuviblliss gathered honest and valuable reviews from trusted buyers.",
    
      },

      {
        heading: "Results",
        subcontent:
          "The idea of branding and marketing worked well and clicked with the customers. By focusing on a specific target audience with low immunity levels and using an authentic product reviewing approach, Nuvibliss succeeded in leveling up its brand visibility. This approach also helped in increasing customers’ interest in purchasing products on Amazon.",
      },
      // {
      //   heading: "Results",
      //   content:
      //     "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },

    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Nuviblisscase}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.headingContent && (
                          <h1
                            style={{ textAlign: "center", marginTop: "10px" }}
                          >
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && <p>{section.list1}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                          
                          </ul>
                        )}
                        {section.submorecontent && (
                          <p>{section.submorecontent}</p>
                        )}
                        {section.content4 && <p>{section.content4}</p>}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NuviblissCaseStudiesInner;
