// import React from 'react';


// const BlogCards = ({image, title, content, onClick, link}) => {
//   return (
//     <div className='blogcards_container' onClick={onClick}>
//       <ul>
//         <li><img src={image} alt='blog_img'/></li>
//         <li>{title}</li>
//         <li>{content}</li>
//         <li className='read-more'>
//           <a href={link} target="_blank" rel="noopener noreferrer">
//             Read more <span className='arrow'>→</span>
//           </a>
//         </li>
//       </ul>
//     </div>
//   );
// }

// export default BlogCards;
import React from 'react';

const BlogCards = ({ image, title, content, link,onClick }) => {
  const handleCardClick = () => {
    window.open(link, '_blank');
    if (onClick) {
      onClick(link);
    }
  };
  return (
    <div className='blogcards_container' onClick={handleCardClick}>
      <ul>
        <li><img src={image} alt='blog_img' /></li>
        <li>{title}</li>
        <li>{content}</li>
     
      </ul>
    </div>
  );
}

export default BlogCards;
