import React from "react";
import companyNameServices from "../../common/constant/NotifictionData";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";

const Terms = () => {
  const combinedData = {
    companyNameServices: {
      companyName: "Definition",
      defination: "For the purposes of the Terms and condition",
      services: [
        {
          content:
            "“Agreement” shall mean the Terms, the Privacy Policy, Cancellation and Refund Policy, Pricing Policy and any other policies as may be framed by us from time to time",
        },

        {
          content:
            "“Marqeting”, “we”, “our”, “us”, “Platform” and “Company” shall mean AFFL Marketing Private Limited having its registered office at New Delhi, India.",
        },
        {
          content:
            "“Platform” shall mean a collective reference to the Services provided through the website at www.marqeting.in or through the Mobile application installed by you on your mobile device, tablet, computer, etc.",
        },
        {
          content:
            "Website” shall mean the website www.marqeting.in of the company.",
        },

        {
          content:
            "“You”, “Your”, “your” “yourself”, “user” and “you” shall mean the end user, who uses the platform and mobile application.",
        },
      ],
    },
    CustomerResponsibility: {
      customerResponsibility: "User Accounts",
      defination:
        "To access the full functionality of the website/Mobile application, you are requested to register an account (the “User Account”). By registering the User Account, You agree to:",

      Responsibility: [
        {
          content:
            "provide and keep current, accurate and complete information about Yourself and Your company (“Information”)",
        },
        {
          content:
            "maintain the confidentiality of your User Account and log-in credentials, if applicable; and",
        },
        {
          content:
            " restrict access to the User Account to all others. You agree to accept responsibility for all activities that occur under the User Account. If We have reasonable grounds to suspect that the Information You provide is inaccurate, incomplete or impersonates another person, we reserve the right to suspend or terminate your User Account. All Information will be stored and used in accordance with our Privacy Policy.",
        },
        {
          content:
            "Marqeting will not be liable for any loss or damage caused by any unauthorized use of Your Account",
        },
        {
          content:
            "By creating the User Account and providing details of a business entity, you confirm that You are an authorised employee, contractor, or representative of the business entity. Marqeting shall not be responsible in any manner and bear no liability for Your activities carried out without such authorisation.",
        },
      ],
    },
    useWebsiteMobile: {
      useWebsiteMobile: "Use of the Website/Mobile Application",
      defination:
        "By accessing and using the website/Mobile application, you acknowledge that you are responsible for your actions and for all content that you post on the Website/Mobile application",
      subDefination:
        "You represent and warrant, to the best of your knowledge that",

      Responsibility: [
        {
          content:
            "You will, when posting or providing User Content on the Website, adhere to Our guidelines and bear all liability related to your submission of such User Content through the Website;",
        },
        {
          content:
            "You will not post or otherwise provide User Content that is unlawful, defamatory, infringing, libelous, abusive, disparaging, pornographic, invasive of another's privacy, promotes illegal activities/conduct or violates applicable laws or regulations;",
        },
        {
          content:
            "You will not post or otherwise provide User Content that You do not have the right to make available under any law or contractual or fiduciary relationship (such as insider information, proprietary and confidential information, learned or disclosed as part of employment relationships, or information protected under nondisclosure agreements);",
        },
        {
          content:
            " You will not post or otherwise provide User Content that contains a software virus or any other code files or programs with the ability to interrupt, destroy, compromise, or otherwise limit the functionality of any computer software or hardware or telecommunications equipment",
        },
        {
          content:
            "You will not forge headers or otherwise manipulate identifiers for the purpose of disguising the origin of any User Content;",
        },
        {
          content:
            "You will not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information of the Website or Our vendors without Our express written consent;",
        },
        {
          content:
            "You will not avoid, bypass, reverse engineer, interfere with, deactivate, impair, descramble or otherwise circumvent any technical measure implemented by Us to administer and protect the Website;",
        },
        {
          content:
            "You will not use any automated means or form of data scraping or data extraction to access, query, download or otherwise collect any information or content from the Website (except as expressly permitted by Us) or otherwise without authorization use or upload our content; or create new links, reposts, or referrals through the use of any engine, software, tool, agent, device or mechanism (including automated scripts, spiders, robots, crawlers and data mining tools)",
        },
        {
          content:
            "You will not duplicate, download, publish, modify or otherwise distribute Our Content for any purpose other than for your own individual use, except with Our explicit consent hereto;",
        },
        {
          content:
            'You will not collect or "harvest" from the Website the personal information of other Users without their consent for the purpose of transmitting unsolicited commercial mass mailings, "spamming” or any other unlawful purpose; and',
        },

        {
          content:
            "You will not access the Website by any means other than through interfaces expressly authorized by Us and these TERMS.",
        },

        {
          content:
            "In the event of any actual or suspected breach, your Use of the Platform will be terminated immediately.",
        },
      ],
    },
  };

  return (
    <>
       <Helmet>
       <title>Marqeting - Terms of Use</title>

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="robots" content="index,follow" />

        <meta
          name="og:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="og:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta name="og:url" content="https://www.marqeting.in/terms" />

        <meta name="og:site_name" content="Marqeting" />

        <meta
          name="og:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="og:keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="twitter:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="twitter:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="description"
          content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
        />
      </Helmet>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="faq_container_innar">
            <div className="faq_container_right">
              <div className="terms_container">
                <div>
                  <h1 className="terms_typograpy_text_heading">
                    Terms and Conditions
                  </h1>
                </div>
                <span className="terms-Of_service_heading">
                  Effective from : December 1, 2023
                </span>
                <p className="landing_typograpy_text">
                  These terms and conditions govern your relationship with the
                  “Marqeting” (referred to as the “Platform”), a division of
                  AFFL Marketing Private Limited in terms of its provision of
                  services
                </p>
                <p className="landing_typograpy_text">
                  The services (defined hereinafter) offered on or through the
                  use of our website, app or any related applications (including
                  mobile phone applications) or use of any other media format
                  for the purposes of promoting the Platform, you (defined
                  hereinafter) accept, agree to comply with and be bound by this
                  Agreement (defined hereinafter) which forms a binding
                  Agreement between you and Platform. By using the Services, you
                  represent and warrant that you are of legal age to form a
                  binding contract with us.
                </p>
                <p className="landing_typograpy_text">
                  INSTALLING, SIGNING UP, ACCESSING, BROWSING OR OTHERWISE USING
                  THE WEBSITE AND/ OR AN APPLICATION INDICATES YOUR AGREEMENT TO
                  ALL THE TERMS AND CONDITIONS HEREIN, YOU ARE REQUESTED TO READ
                  THE TERMS CAREFULLY BEFORE PROCEEDING. If you do not want to
                  be bound by these Terms, you must not retain, access, or use
                  the Application and /or the Website in any manner whatsoever.
                  Anything done, caused to be done, whether expressly or
                  impliedly in contravention of these Terms may render you
                  liable for legal action.
                </p>
                <p className="landing_typograpy_text">
                  If you do not want to be bound by these Terms, you must not
                  retain, access, or use the Application and /or the Website in
                  any manner whatsoever. Anything done, caused to be done,
                  whether expressly or impliedly in contravention of these Terms
                  may render you liable for legal action.
                </p>

                <div>
                  <h1 className="terms_of_use_heading">
                    {combinedData.companyNameServices.companyName}
                  </h1>
                </div>
                <span className="terms_Of_service_heading_li">
                  {combinedData.companyNameServices.defination}
                </span>
                {combinedData.companyNameServices.services.map(
                  (service, index) => (
                    <li className="terms_Of_service_heading_li" key={index}>
                      {service.content}
                    </li>
                  )
                )}

                <h1 className="terms_typograpy_text">Right to Use</h1>

                <p className="landing_typograpy_text">
                  Your right to use the Services is subject to the limitations,
                  conditions, and restrictions established by us from time to
                  time, at our sole discretion. We may alter, suspend, or
                  discontinue the Services at any time, without any prior
                  intimation to the user. We may also impose limits on certain
                  features and/ or aspects of the Services and/ or restrict your
                  access to certain parts of the Services and/ or all of the
                  Services without any prior intimation and any liability on us.
                </p>
                <h1 className="terms_typograpy_text">
                  Intellectual Property Right
                </h1>

                <p className="landing_typograpy_text">
                  We solely own rights in the name and mark of Marqeting and the
                  logo that appears on the platform (the “Logo”). You are not
                  permitted to, and you agree not to, use these marks in any
                  manner (including as part of any other trademark, Company name
                  or domain name), in connection with any product or service.
                </p>
                <p className="landing_typograpy_text">
                  We own and have the authorized license of all intellectual
                  property rights (including copyright) in and to the Platform
                  including intellectual property rights in the content hosted,
                  published, displayed, uploaded on the Platform by us.
                  Copyright laws and treaties throughout the world protect these
                  works and all rights in and to them are reserved by us. No
                  information, content or material from us may be copied,
                  reproduced, republished, uploaded, posted, transmitted or
                  distributed in any way without our express written permission.
                </p>

                <h1 className="terms_of_use_heading">
                  {combinedData.CustomerResponsibility.customerResponsibility}
                </h1>
                <span className="terms_Of_service_heading_li">
                  {combinedData.CustomerResponsibility.defination}
                </span>
                {combinedData.CustomerResponsibility.Responsibility.map(
                  (responsibility, index) => (
                    <li className="terms_Of_service_heading_li" key={index}>
                      <span className="li_heading_terms">
                        {responsibility.heading}
                      </span>{" "}
                      {responsibility.content}
                    </li>
                  )
                )}

                <h1 className="terms_of_use_heading">
                  {combinedData.useWebsiteMobile.useWebsiteMobile}
                </h1>
                <span className="terms_Of_service_heading_li">
                  {combinedData.useWebsiteMobile.defination}
                </span>
                <span className="terms_Of_service_heading_li">
                  {combinedData.useWebsiteMobile.subDefination}
                </span>
                {combinedData.useWebsiteMobile.Responsibility.map(
                  (responsibility, index) => (
                    <li className="terms_Of_service_heading_li" key={index}>
                      <span className="li_heading_terms">
                        {responsibility.heading}
                      </span>{" "}
                      {responsibility.content}
                    </li>
                  )
                )}
                <h1 className="terms_typograpy_text">
                  Fees, Payment and Refund Policy
                </h1>
                <p className="landing_typograpy_text">
                  <h5 className="terms_typograpy_text_sub">Fees</h5>
                  As consideration for any purchase, you make on the Website,
                  you shall pay all applicable fees and taxes (collectively, the
                  “Fees”). We (or our third-party payment processor) shall
                  authorize your credit card, bank account, or other approved
                  payment facility that you provided during the registration
                  process for the full payment of the Fees, and you hereby
                  consent to the same. You take full responsibility for all
                  taxes and fees of any nature associated with the Services. You
                  agree to pay platform fee charge by Marqeting for using the
                  Site or Mobile application to create campaign and fulfilling
                  the process. The Fees are subject to change without a prior
                  notice. Any changes to the Fees will be made available on the
                  Website
                </p>
                <p className="landing_typograpy_text">
                  <h5 className="terms_typograpy_text_sub">Payments</h5>
                  All payments are processed by our third-party payment
                  processors (the “Payment Processors”). When you make a
                  payment, the Payment Processors collect some personal data
                  from you which allows them to make the payments requested by
                  you (e.g., your credit card number, expiration date, billing
                  address, and security codes). The Payment Processors handle
                  all steps in the payment process through their systems,
                  including data collection and data processing. Marqeting does
                  not have direct access to your full payment information. You
                  are responsible for ensuring that all payment information is
                  correct and the funds necessary for payment are available. You
                  agree not to hold Us liable for payments that do not reach Us
                  because You have quoted incorrect payment information or the
                  Payment Processors refused the payment for any other reason
                </p>

                <p className="landing_typograpy_text">
                  <h5 className="terms_typograpy_text_sub">Refund Policy</h5>
                  At Marqeting, we are not obligated to return any of fee under
                  any circumstances.
                </p>

                <h1 className="terms_typograpy_text">Your Content</h1>
                <p className="landing_typograpy_text">
                  You must not infringe any intellectual property rights of
                  others when uploading or creating your content. Marqeting
                  reserves the right, at its sole discretion, to refuse to
                  upload, modify, delete, or remove your content, in whole or in
                  part, that violates these TERMS or may harm the reputation of
                  Marqeting.
                </p>
                <h1 className="terms_typograpy_text">Social Handlers</h1>
                <p className="landing_typograpy_text">
                  We use social handlers like Instagram, Facebook, Twitter,
                  LinkedIn, YouTube Services. All users including Advertiser and
                  Marqeter are agreeing to be bound by social handler’s Terms of
                  Service (ToS)
                  <ul>
                    <li>
                      Youtube -{" "}
                      <a
                        href="https://www.youtube.com/t/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.youtube.com/t/terms
                      </a>
                    </li>
                    <li>
                      Facebook -
                      <a
                        href="https://www.facebook.com/terms.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook - https://www.facebook.com/terms.php
                      </a>
                    </li>
                    <li>
                      Instagram -
                      <a
                        href="https://help.instagram.com/581066165581870"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        https://help.instagram.com/581066165581870
                      </a>
                    </li>
                    <li>
                      Linkedin -{" "}
                      <a
                        href="https://www.linkedin.com/legal/user-agreement"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.linkedin.com/legal/user-agreement
                      </a>
                    </li>
                    <li>
                      Twitter -{" "}
                      <a
                        href="https://twitter.com/en/tos"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://twitter.com/en/tos
                      </a>
                    </li>
                  </ul>
                </p>
                <h1 className="terms_typograpy_text">Termination By Us</h1>
                <p className="landing_typograpy_text">
                  We reserve the right, in our sole discretion, without penalty
                  and at any time without notice, to modify or discontinue
                  (temporarily or permanently) User’s access to the Website, or
                  any part thereof if We believe that User is violating these
                  TERMS. If the User uses or attempts to use the Website for any
                  purpose that contravenes these TERMS (including without
                  limitation tampering, hacking, data scraping, modifying or
                  otherwise corrupting the security or functionality of the
                  Website), the User may also be subject to civil and criminal
                  liability.
                </p>
                <h1 className="terms_of_use_heading">Questions?</h1>
                <span className="footer_text_terms">
                  Email us questions at <a>support@marqeting.in</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
