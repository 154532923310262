import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {

  Coverelemnt,
  Playbutton,

  Innsightimageelemnt,
  Totemcase,

} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import CaseVideo from "../assets/images/caseVideo.mp4";

const TotemCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d68",
    title: "How Totem Increased Its Business Growth through Planned E-commerce Solutions",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:
      "As winters arrived and festivities were around the corner, Go Pappe had to find a solution to market its restaurant with influencers who could represent the brand in the most innovative way possible.",
    caseType: "E-COMMERCE",
    content: [
      {
        heading: "Client Brief",
        subcontent:
          "“Style is a personal expression of who you are and every time you carry your device, you are reflecting a part of yourself,” said Totem, the only brand in India dealing in premier Apple accessories.",
    
          content:
          "The brand believes in offering accessories that not only protect but also improve the aesthetic and functional experience of their customers.",
          submorecontent:
          "Launched in 2021, Totem deals in a range of high-quality products including Phone cases (Graphene, Aramid, Silicone, Matte, and more), Screen and Lens protectors, Lightning cables, Apple Watch screen protectors. Your device is 360° protected with Totem’s lifetime product guarantee.",
      },
      {
        heading: "The Challenge - Unsatisfactory Business Growth",
        subcontent:
        "Despite earning recognition from industry producers, the brand’s e-commerce journey proved to be opposite than what they expected it to be. The client faced challenges in scaling their e-commerce operations and increasing market visibility.",
  
        content:
        "Despite having premium products, Totem struggled to reach a broader audience across India. The brand faced difficulties in making substantial organic sales, which is crucial for sustained growth.",
      },
      {
        heading: "Execution Strategy",
        subcontent:
          "Realising these challenges, Totem collaborated with Marqeting to improve its e-commerce presence on Amazon. The team helped the client understand how Marqeting can assist them in improving their brand visibility and social media engagement.",
        content:
          "We knew that in a highly competitive marketplace, it was not easy for Totem to make its products noticeable. The execution team was firm in the idea that smooth seas don't make skilful sailors. Hence, we were head started to achieve something big from these challenges.",

          content3:
          "The research and discovery (R&D) phase were focused on understanding Totem’s business goals, products, the intended target audience and USP’s. Following R&D, it was time to bring the brand back to life.",
          submorecontent:
          "Social proofs such as reviews and testimonials are superpowers of any brand when it comes to converting browsers into buyers. Recognizing that reviews and testimonials are pivotal in improving customer ties and driving sales, we implemented a targeted campaign focused at encouraging satisfied customers to share their genuine experiences. ",
          content4:
          "In addition to our targeted review campaign, we observed that many satisfied customers were so delighted with Totem's products that they took to their social media handles, especially Instagram, to share their joy. These organic social media posts not only amplified the reach of Totem on Amazon but also on other social channels. Well, that was an icing on the cake for sure!",
          
      },
    
      {
        heading: "Results",
        subcontent:
          "With our long-standing expertise in ecommerce solutionising we were able to increase discoverability for Totem at its best. It was an incredible turnaround in the brand’s e-commerce performance. Increased visibility, combined with social proof from satisfied customers, translated into an impressive improvement in the brand’s recognition and engagement.",
   
        
      
      },
      // {
      //   heading: "Results",
      //   content:
      //     "As a result, we strengthened Elemnt’s goodwill on both ecommerce platforms - Amazon and Flipkart within the intended time. Bottoms Up! Our client appreciated the effort we put in to execute the project with 100% accuracy, highly to their satisfaction.",
      //   image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      //   imageUrl:
      //     "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      // },
    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C2QKveOSvGf/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Tastingglitch,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MimjECaBl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mnyanka,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2MDij5vGtp/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Littleflower,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C2NACVSvzUX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA%3D%3D",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Akshitarora,
    //   },
    
      
    // ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Totemcase}
                  alt={data.title}
                />
                {/* <p>{data.description}</p> */}

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}
                        {section.subHeading && section.subHeadingnewe && (
                          <p className="subheading_new">
                            {section.subHeading}
                            <span className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </span>
                          </p>
                        )}
                        {section.subcontent && <p>{section.subcontent}</p>}
                      

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Innsightimageelemnt}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && section.list3 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                          <li className="list_disc_ul">  {section.list3 && <p>{section.list3}</p>}</li>
                        </ul>
                        )}
                        {section.submorecontent && <p>{section.submorecontent}</p>}
                        {section.content4 && <p>{section.content4}</p>}


                      </div>
                    ))}
                    
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                    className="video_player"
                    url={CaseVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotemCaseStudiesInner;
