import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Grid,
  Typography,
  StepConnector,
  FormHelperText,
  RadioGroup,
  Radio,
  Divider,
  Button,
} from "@mui/material";
import { validateInput } from "../../common/utils/validationUtils";
import { PATTERNS } from "../../common/utils/constant";
import {
  DescriptionInput,
  BootstrapInput,
} from "../styledCcomponents/StyledMenu";
import CustomInputLabel from "../styledCcomponents/styledInput";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";

import {
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  useCategary,
  useCities,
  useCountries,
  useInfluncersCategary,
  useStates,
} from "../customHooks/customHook";
import MarqetingEndpoint from "../../Services/marqetingEndpoint";
import { postJsonData } from "../../Services/AxoisInterceptorr";
import { useNavigate } from "react-router-dom";
import CheckboxComponent from "../checkbox";
import RegisterSuccessPopup from "../RegisterPopup";

export default function MyStepperInfluncers() {
  const [collaborationType, setCollaborationType] = useState("");
  const [genderType, setGenderType] = useState("");
  const [otherCategory, setOtherCategory] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const [formData, setFormData] = useState({
    firstname: {
      value: "",
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
    instagramurl: {
      value: "",
      isValid: true,
    },
    facebookurl: {
      value: "",
      isValid: true,
    },
    twitterurl: {
      value: "",
      isValid: true,
    },
    linkedinurl: {
      value: "",
      isValid: true,
    },
    youtubeurl: {
      value: "",
      isValid: true,
    },
  });
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [otp, setOtp] = useState("");
  const [otherCity, setOtherCity] = useState("");
  const [isOtherCity, setIsOtherCity] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(false);
  const [collaborationBudget, setCollaborationBudget] = useState("");
  const [categories, setCategories] = useState({});
  const [selectedButtons, setSelectedButtons] = useState({});
  const [otpVerified, setOtpVerified] = useState(false);
  const [instagramFollowers, setInstagramFollowers] = useState("");
  const [facebookFollowers, setFacebookFollowers] = useState("");
  const [twitterFollowers, setTwitterFollowers] = useState("");
  const [youtubeFollowers, setYoutubeFollowers] = useState("");
  const [linkdinFollowers, setLinkdinFollowers] = useState("");
  const [isTwitterFollowersValid, setIsTwitterFollowersValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [timer, setTimer] = useState(30);
  const countries = useCountries();
  const states = useStates(selectedCountryIso2);
  const cities = useCities(age);
  const influncercategorie = useInfluncersCategary();
  const navigate = useNavigate();
  const handleChangeFollowers = (e) => {
    const value = e.target.value;
    const isValid = !isNaN(value);
    setFormData((prevData) => ({
      ...prevData,
      followers: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const handleInstagramFollowersChange = (e) => {
    const value = e.target.value;
    const isValid = !isNaN(value);
    setIsTwitterFollowersValid(isValid);
    setInstagramFollowers(value);
  };

  const handleFacebookFollowersChange = (e) => {
    const value = e.target.value;
    const isValid = !isNaN(value);
    setIsTwitterFollowersValid(isValid);
    setFacebookFollowers(value);
  };
  const handleTwitterFollowersChange = (e) => {
    const value = e.target.value;
    const isValid = !isNaN(value);
    setIsTwitterFollowersValid(isValid);
    setTwitterFollowers(value);
  };

  const handleYoutubeFollowersChange = (e) => {
    const value = e.target.value;
    const isValid = !isNaN(value);
    setIsTwitterFollowersValid(isValid);
    setYoutubeFollowers(value);
  };
  const handleLinkidinFollowersChange = (e) => {
    const value = e.target.value;
    const isValid = !isNaN(value);
    setIsTwitterFollowersValid(isValid);
    setLinkdinFollowers(value);
  };

  const handleClick = (buttonName) => {
    setSelectedButtons((prev) => ({
      ...prev,
      [buttonName]: !prev[buttonName],
    }));
  };

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handlesetCountryChange = (event) => {
    setSelectedCountryIso2(event.target.value);
  };
  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    if (selectedCity === "Other") {
      setIsOtherCity(true);
      setCity("Other");
    } else {
      setCity(selectedCity);
      setIsOtherCity(false);
    }
  };
  const navigateToHome = () => {
    navigate("/");
    
  };
  const handleOtherCityChange = (event) => {
    setOtherCity(event.target.value);
  };

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    // const isValid = regEx ? validateInput(value, regEx) : true;
    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  useEffect(() => {}, [selectedSocialMedia]);

  const selectedCategories = Object.keys(categories).filter(
    (category) => categories[category]
  );
  const selectedCountryName = countries?.find(
    (country) => country.name === selectedCountryIso2
  )?._id;
  const selectedStateName = states?.find((state) => state.name === age)?._id;
  const handleLogin = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!isChecked) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    const isFormValid =
      !otpVerified &&
      Object.keys(selectedButtons).some(
        (platform) => selectedButtons[platform]
      ) &&
      formData.firstname.isValid &&
      formData.email.isValid &&
      formData.phone.isValid &&
      (!formData.instagramurl.value || formData.instagramurl.isValid) &&
      (!formData.facebookurl.value || formData.facebookurl.isValid) &&
      (!formData.twitterurl.value || formData.twitterurl.isValid) &&
      (!formData.linkedinurl.value || formData.linkedinurl.isValid) &&
      (!formData.youtubeurl.value || formData.youtubeurl.isValid) &&
      collaborationType.trim() !== "" && genderType.trim() !== "" &&
      (city || otherCity) &&
      Object.values(categories).some((category) => category);

    if (isFormValid) {
      const selectedSocialMediaData = Object.keys(selectedButtons)
        .filter((platform) => selectedButtons[platform])
        .map((platform) => ({
          socialPlatform: platform,
          // profileUrl: formData.instagramurl.value,
          profileUrl: formData[`${platform.toLowerCase()}url`].value,
          followersCount:
            platform === "Instagram"
              ? +instagramFollowers
              : platform === "Facebook"
              ? +facebookFollowers
              : platform === "Twitter"
              ? +twitterFollowers
              : platform === "Linkedin"
              ? +linkdinFollowers
              : platform === "Youtube"
              ? +youtubeFollowers
              : 0,
        }));
      const jsonData = {
        fullName: formData.firstname.value,
        email: formData.email.value,
        mobileNumber: formData.phone.value,
        country: selectedCountryName,
        state: selectedStateName,
        city: city === "Other" ? null : city,
        socialMedia: selectedSocialMediaData,
        contentCategory: selectedCategories,
        collaborationPreference: collaborationType,
        gender: genderType,
        otherCategory: otherCategory,
        otherCity: isOtherCity ? otherCity : undefined,
      };

      if (collaborationType === "paid" || collaborationType === "both") {
        jsonData.amount = collaborationBudget;
      }
      postJsonData(
        MarqetingEndpoint.INFLUENCER_CREATE,
        jsonData,
        () => {},
        (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            // toast.success("Influencer created successfully");
            // toast.success(res.data.message);
            setIsSuccess(true); 
            setIsPopupOpen(true)
           
          }
        },  
        (error) => {
           setIsError(true);
  setIsPopupOpen(true);
          // toast.error(error.response.data.message);
       
        }
      );
    }
  };
 
  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the pop-up modal
    setIsSuccess(false); // Reset isSuccess state
    setIsError(false); // Reset isError state
  };
  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };
  const handleRequestOtp = () => {
    if (!formData.phone.value || formData.phone.value.length < 10) {
      toast.error("Kindly enter your phone number before requesting an OTP.");
      return;
    }

    const phoneNumberWithCountryCode = "91" + formData.phone.value;
    const jsonData = {
      mobileNumber: +phoneNumberWithCountryCode,
    };

    postJsonData(
      MarqetingEndpoint.SEND_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          console.log("res", res);
          toast.success(res.data.message);
          setOtp("");
        }
      },
      (error) => {
        
        if (error.response.status === 429) {
          toast.error(
            "You have made too many requests. Please try again later."
          );
        } else {
          toast.error(error.response.data.message);
        }
      }
    );

    setShowOtpInput(true);
    setPhoneNumberDisabled(true);
  };
  const handleResendtOtp = () => {
    setTimer(30);

    const phoneNumberWithCountryCode = "91" + formData.phone.value;
    const jsonData = {
      mobileNumber: +phoneNumberWithCountryCode,
    };

    postJsonData(
      MarqetingEndpoint.RESEND_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          console.log("res", res);
          toast.success(res.data.message);
          setOtp("");
        }
      },
      (error) => {
        console.log(error.response, "loki");
        toast.error(error.response.data.message);
      }
    );

    setShowOtpInput(true);
    setPhoneNumberDisabled(true);
  };

  const handleEditPhoneNumber = () => {
    setShowOtpInput(false);
    setPhoneNumberDisabled(false);
  };
  const handleOtpSubmit = () => {
    const phoneNumberWithCountryCode = "91" + formData.phone.value;
    const jsonData = {
      mobileNumber: parseInt(phoneNumberWithCountryCode),
      otp: parseInt(otp),
    };

    postJsonData(
      MarqetingEndpoint.VERIFY_OTP,
      jsonData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setShowOtpInput(false);
          setPhoneNumberDisabled(true);
          setOtpVerified(true);
        }
      },
      (error) => {
        toast.error(error.response.data.message);
        setOtp("");
        setShowOtpInput(true);
      }
    );
  };

  const handleCollaborationChange = (event) => {
    setCollaborationType(event.target.value);
  };
  const handleGenderChange = (event) => {
    setGenderType(event.target.value);
  };

  const handleOtherCategoryChange = (event) => {
    setOtherCategory(event.target.value);
  };
  const handleCommercialSelection = (event) => {
    setCollaborationBudget(event.target.value);
  };
  const handleSubmitClick = () => {
    setSubmitClicked(true);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setShowError(false);
  };

  return (
<>    
<RegisterSuccessPopup
    open={isPopupOpen}
    navigateToHome={() => {
          navigateToHome();
          setIsPopupOpen(false); 
        }}
    isSuccess={isSuccess}
    isError={isError}
  />
    <Box sx={{ width: "100%" }}>
      <Box>
        <h1 className="brandinfluncers_forms">Marqfluencer Onboarding</h1>

        <>
          {" "}
          <form onSubmit={handleLogin}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Full Name*</CustomInputLabel>

                  <DescriptionInput
                    required
                    name="first_name"
                    type="text"
                    placeholder="Enter Full Name"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "firstname", PATTERNS.PERSONNEW_NAME);
                    }}
                  />
                  {formData.firstname.isValid || (
                    <p className="input_text_error">
                      *Please enter your full name.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Email Address*</CustomInputLabel>

                  <DescriptionInput
                    required
                    name="first_name"
                    type="text"
                    placeholder="Enter Email Address"
                    id="outlined-required"
                    label="First Name"
                    helper
                    size="small"
                    onChange={(e) => {
                      setValues(e, "email", PATTERNS.EMAIL);
                    }}
                  />
                  {formData.email.isValid || (
                    <p className="input_text_error">
                      *Please enter your valid email address.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <div className="mobile_function">
                    <CustomInputLabel>Phone Number*</CustomInputLabel>
                    {/* {showOtpInput ? (
                      <div
                        className="align_self"
                        onClick={handleEditPhoneNumber}
                      >
                        <h5>Edit Number</h5>
                      </div>
                    ) : (
                      <div className="align_self">
                        {otpVerified ? (
                          <h6>Verified</h6>
                        ) : (
                          <div onClick={handleRequestOtp}>
                            <h5>Request OTP</h5>
                          </div>
                        )}
                      </div>
                    )} */}
                  </div>

                  <DescriptionInput
                    required
                    name="Contact"
                    type="Number"
                    placeholder="Enter Phone Number"
                    id="outlined-required"
                    label="First Name"
                    helper
                    disabled={phoneNumberDisabled}
                    size="small"
                    onChange={(e) => {
                      setValues(e, "phone", PATTERNS.MOBILE);
                    }}
                  />
                  {formData.phone.isValid || (
                    <p className="input_text_error">
                      *Please enter your phone number.
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 1.5, borderBottom: "none" }} />

            {showOtpInput && (
              <>
                {" "}
                <div className="otp-container">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputStyle="otp-input"
                    renderSeparator={<span className="otp-separator">-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="submt_content">
                  <div className="resendcontainer">
                    <Button
                      sx={{
                        color: "#163e6e",
                        "&:hover": {
                          background: "none",
                        },
                      }}
                      size="small"
                      variant="text"
                      onClick={handleResendtOtp}
                      disabled={timer > 0}
                    >
                      Resend OTP
                    </Button>

                    {timer > 0 && (
                      <span className="timerresendtext">
                        Resend in {timer} seconds
                      </span>
                    )}
                  </div>
                  <div className="submi_content_button">
                    <button
                      className="otpsubmitbutton"
                      type="button"
                      onClick={handleOtpSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>Country Name*</CustomInputLabel>

                  <FormControl>
                    <Select
                      value={selectedCountryIso2}
                      onChange={handlesetCountryChange}
                      displayEmpty
                      size="small"
                      input={<BootstrapInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        color: "#000000",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value=""
                      >
                        <em>Select Country</em>
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.name}
                          value={country.name}
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                  {formSubmitted && !selectedCountryIso2 && (
                    <p className="input_text_error">
                      *Please select a country.
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>State/UT Name*</CustomInputLabel>

                  <FormControl>
                    <Select
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      input={<BootstrapInput />}
                      size="small"
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        color: "#000000",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value=""
                      >
                        <em>Select State/UT</em>
                      </MenuItem>
                      {states.map((state) => (
                        <MenuItem
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                          value={state.name}
                          key={state.name}
                        >
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                </FormControl>
                {formSubmitted && !age && (
                  <p className="input_text_error">*Please select a state/UT.</p>
                )}
              </Grid>

              <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                <FormControl sx={{ width: "100%" }}>
                  <CustomInputLabel>City Name*</CustomInputLabel>

                  <FormControl>
                    <Select
                      value={isOtherCity ? "Other" : city}
                      onChange={handleCityChange}
                      displayEmpty
                      size="small"
                      input={<BootstrapInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        color: "#000000",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value=""
                      >
                        <em>Select City</em>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                        value="Other"
                      >
                        <em>Enter Other City</em>
                      </MenuItem>
                      {cities.map((city) => (
                        <MenuItem
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                          value={city._id}
                          key={city._id}
                        >
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {isOtherCity &&
                      selectedCountryName &&
                      selectedStateName && (
                        <DescriptionInput
                          required
                          sx={{ marginTop: "10px" }}
                          value={otherCity}
                          onChange={handleOtherCityChange}
                          size="small"
                          placeholder="Enter your city"
                        />
                      )}

                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                </FormControl>
                {formSubmitted && !city && !isOtherCity && (
                  <p className="input_text_error">*Please select a city.</p>
                )}
              </Grid>
            </Grid>

            <Divider sx={{ marginY: 2, borderBottom: "none" }} />
              <CustomInputLabel>
                Please Select Your Gender*
              </CustomInputLabel>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <RadioGroup
                row
                aria-label="collaboration-type"
                name="collaboration-type"
                value={genderType}
                onChange={handleGenderChange}
              >
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="Male"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        genderType === "Male" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Male
                    </Typography>
                  }
                />

                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="Female"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        genderType === "Female" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Female
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="Other"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        genderType === "Other" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Other
                    </Typography>
                  }
                />
              </RadioGroup>
              {formSubmitted && !genderType && (
                <p className="input_text_error">
                  *Please select your gender.
                </p>
              )}

          
            <Divider sx={{ marginY: 1.5, borderBottom: "none" }} />

            <CustomInputLabel>
              Which social platform you are active on*
            </CustomInputLabel>

            <Divider sx={{ marginY: 1, borderBottom: "none" }} />
            <div className="social_button_container">
              {["Instagram", "Facebook", "Linkedin", "Twitter", "Youtube"].map(
                (buttonName) => (
                  <button
                    type="button"
                    className={`social_url_button ${
                      selectedButtons[buttonName]
                        ? "social_url_button_selected"
                        : ""
                    }`}
                    onClick={() => handleClick(buttonName)}
                  >
                    {buttonName}
                  </button>
                )
              )}
            </div>
            {formSubmitted &&
              !Object.values(selectedButtons).some((value) => value) && (
                <p className="input_text_error">
                  *Please select a social platform you are active on.
                </p>
              )}

            {selectedButtons["Instagram"] && (
              <Divider sx={{ marginY: 1.5, borderBottom: "none" }} />
            )}

            {selectedButtons["Instagram"] && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Instagram URL*</CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="text"
                      placeholder="Enter Profile URL"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "instagramurl", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.instagramurl.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid url.
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>
                      Number of Followers/Subscribers*
                    </CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="number"
                      placeholder="Enter Followers Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      value={instagramFollowers}
                      onChange={handleInstagramFollowersChange}
                    />
                    {/* {formData.instagramFollowers.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid number.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            {selectedButtons["Facebook"] && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Facebook URL*</CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="text"
                      placeholder="Enter Profile URL"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "facebookurl", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.facebookurl.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid url.
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>
                      Number of Followers/Subscribers*
                    </CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="number"
                      placeholder="Enter Followers Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      value={facebookFollowers}
                      onChange={handleFacebookFollowersChange}
                    />
                    {/* {formData.facebookFollowers.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid number.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            {selectedButtons["Linkedin"] && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Linkedin URL*</CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="text"
                      placeholder="Enter Profile URL"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "linkedinurl", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.linkedinurl.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid url.
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>
                      Number of Followers/Subscribers*
                    </CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="number"
                      placeholder="Enter Followers Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      value={linkdinFollowers}
                      onChange={handleLinkidinFollowersChange}
                    />
                    {/* {formData.linkdinFollowers.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid number.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ marginY: 1, borderBottom: "none" }} />

            {selectedButtons["Youtube"] && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Youtube URL*</CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="text"
                      placeholder="Enter Profile URL"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "youtubeurl", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.youtubeurl.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid url.
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>
                      Number of Followers/Subscribers*
                    </CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="number"
                      placeholder="Enter Followers Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      value={youtubeFollowers}
                      onChange={handleYoutubeFollowersChange}
                    />
                    {/* {formData.youtubeFollowers.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid number.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ marginY: 1.5, borderBottom: "none" }} />
            {selectedButtons["Twitter"] && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>Twitter URL*</CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="text"
                      placeholder="Enter Profile URL"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "twitterurl", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.twitterurl.isValid || (
                      <p className="input_text_error">
                        *Please enter a valid url.
                      </p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                  <FormControl sx={{ width: "100%" }}>
                    <CustomInputLabel>
                      Number of Followers/Subscribers*
                    </CustomInputLabel>

                    <DescriptionInput
                      required
                      name="first_name"
                      type="number"
                      placeholder="Enter Followers Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      value={twitterFollowers}
                      onChange={handleTwitterFollowersChange}
                    />
                    {!isTwitterFollowersValid && (
                      <p className="input_text_error">
                        *Please enter a valid number.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Divider sx={{ marginY: 1.5, borderBottom: "none" }} />

            <FormControl component="fieldset">
              <CustomInputLabel>
                Which Category you create content on?*
              </CustomInputLabel>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <FormGroup>
                <Grid container spacing={3}>
                  {influncercategorie.map((category, index) => {
                    const checked = categories[category._id];
                    return (
                      <Grid item xs={12} sm={2.4} key={index}>
                        <div
                          className={`checkbox_upper_back ${
                            checked ? "checked_background" : ""
                          }`}
                          onClick={() => handleCategoryChange(category._id)}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={() =>
                                  handleCategoryChange(category._id)
                                }
                                style={{
                                  color: checked ? "#FFFFFF" : "",
                                  display: "none",
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  color: checked ? "#ffffff" : "#000000",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  fontFamily: '"Montserrat", sans-serif',
                                }}
                              >
                                {category.influencerCategory}
                              </span>
                            }
                          />
                        </div>
                      </Grid>
                    );
                  })}

                  <Grid item xs={12} sm={3}>
                    <FormControl sx={{ width: "100%" }}>
                      <BootstrapInput
                        name="other_category"
                        type="text"
                        placeholder="Enter Other Category"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        value={otherCategory}
                        onChange={handleOtherCategoryChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {formSubmitted &&
                  !(
                    Object.values(categories).some((category) => category) ||
                    otherCategory
                  ) && (
                    <p className="input_text_error">
                      *Please select at least one category.
                    </p>
                  )}
              </FormGroup>
              <Divider sx={{ marginY: 2, borderBottom: "none" }} />
              <CustomInputLabel>
                Which Collaboration you prefer?*
              </CustomInputLabel>
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              <RadioGroup
                row
                aria-label="collaboration-type"
                name="collaboration-type"
                value={collaborationType}
                onChange={handleCollaborationChange}
              >
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="barter"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationType === "barter" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Barter
                    </Typography>
                  }
                />

                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="paid"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationType === "paid" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Paid
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{ marginLeft: "6px", marginBottom: "10px" }}
                  value="both"
                  control={<Radio style={{ display: "none" }} />}
                  label={
                    <Typography
                      className={`radio-label ${
                        collaborationType === "both" ? "selected" : ""
                      }`}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      Both
                    </Typography>
                  }
                />
              </RadioGroup>
              {formSubmitted && !collaborationType && (
                <p className="input_text_error">
                  *Please select a collaborationType.
                </p>
              )}
              <Divider sx={{ marginY: 1, borderBottom: "none" }} />

              {collaborationType === "paid" || collaborationType === "both" ? (
                <FormControl component="fieldset">
                  <CustomInputLabel>
                    Paid Commercials(in Rs.) ?*
                  </CustomInputLabel>
                  <Divider sx={{ marginY: 1, borderBottom: "none" }} />

                  <RadioGroup
                    row
                    aria-label="collaboration-budget"
                    name="collaboration-budget"
                    value={collaborationBudget}
                    onChange={handleCommercialSelection}
                  >
                    <FormControlLabel
                      sx={{ marginLeft: "6px", marginBottom: "10px" }}
                      value="Below 2,000"
                      control={<Radio style={{ display: "none" }} />}
                      label={
                        <Typography
                          className={`radio-label ${
                            collaborationBudget === "Below 2,000"
                              ? "selected"
                              : ""
                          }`}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          Below 2,000
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginLeft: "6px", marginBottom: "10px" }}
                      value="2,000 - 5,000"
                      control={<Radio style={{ display: "none" }} />}
                      label={
                        <Typography
                          className={`radio-label ${
                            collaborationBudget === "2,000 - 5,000"
                              ? "selected"
                              : ""
                          }`}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          2,000 - 5,000
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginLeft: "6px", marginBottom: "10px" }}
                      value="5,000 - 10,000"
                      control={<Radio style={{ display: "none" }} />}
                      label={
                        <Typography
                          className={`radio-label ${
                            collaborationBudget === "5,000 - 10,000"
                              ? "selected"
                              : ""
                          }`}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          5,000 - 10,000
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginLeft: "6px", marginBottom: "10px" }}
                      value="10,000 - 25,000"
                      control={<Radio style={{ display: "none" }} />}
                      label={
                        <Typography
                          className={`radio-label ${
                            collaborationBudget === "10,000 - 25,000"
                              ? "selected"
                              : ""
                          }`}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          10,000 - 25,000
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginLeft: "6px", marginBottom: "10px" }}
                      value="25,000 - 50,000"
                      control={<Radio style={{ display: "none" }} />}
                      label={
                        <Typography
                          className={`radio-label ${
                            collaborationBudget === "25,000 - 50,000"
                              ? "selected"
                              : ""
                          }`}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          25,000 - 50,000
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginLeft: "6px", marginBottom: "10px" }}
                      value="above-500000"
                      control={<Radio style={{ display: "none" }} />}
                      label={
                        <Typography
                          className={`radio-label ${
                            collaborationBudget === "above-500000"
                              ? "selected"
                              : ""
                          }`}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: '"Montserrat", sans-serif',
                          }}
                        >
                          Above 50,000
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  {formSubmitted && !collaborationBudget && (
                    <p className="input_text_error">
                      *Please select a Paid Commercials.
                    </p>
                  )}

                  <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                </FormControl>
              ) : null}
              <Divider sx={{ marginY: 2, borderBottom: "none" }} />
            </FormControl>

            {/* {submitClicked && !otpVerified && (
              <p className="input_text_error" style={{textAlign:"center"}}>
                Please verify your number first.
              </p>
            )} */}

            <div className="signup_checkbox">
              <CheckboxComponent
                label=""
                value="termsPrivacy"
                name="termsPrivacy"
                onChange={handleCheckboxChange}
                checked={isChecked}
                id="termsPrivacyCheckbox"
              />
              <p>I agree to the </p>
              <span>
                <a target="_blank" href="https://www.marqeting.in/terms">
                  Terms & Conditions
                </a>
              </span>
            </div>
            {showError && (
              <p className="input_text_error" style={{ textAlign: "center" }}>
                *Please agree to the Terms & Privacy before signing up.
              </p>
            )}
            {formSubmitted &&
              (!Object.values(selectedButtons).some((value) => value) ||
                !(
                  Object.values(categories).some((category) => category) ||
                  otherCategory
                ) ||
                !selectedCountryIso2 ||
                !age ||
                (!city && !isOtherCity)) && (
                <p className="input_text_error" style={{ textAlign: "center" }}>
                  There are some errors, please check!
                </p>
              )}

            <div className="submit_container">
              <button
                type="submit"
                className="contact_submit"
                // onClick={handleSubmitClick}
              >
                Submit
              </button>
            </div>
          </form>
        </>
      </Box>
    </Box>
    </>

  );
}
