import {
  Proven,
  Tailored,
  Quality,
  Data,
  Cost,
  Client,
  Campaign,
  Innovative,
  Niche,
  Enhanced,
  // WhiteNotifiction,
} from "../../components/IconsImport";

export const durationOptions = [
  { value: "just-exploring", label: "Just Exploring" },
  { value: "less-than-15-days", label: "<15 days" },
  { value: "15-to-30-days", label: "15 to 30 days" },
  { value: "after-30-days", label: "After 30 days" },
];



export const goalOptions = [
  {
    value: "Increase Brand Awareness",
    description:
      "Our solution assists businesses create awareness about their products or services to get brand recognition and loyalty. Our approach isn't about flashy ads but about telling your brand's story in a way that people connect with. It's creating awareness that goes beyond just knowing your brand; it's about understanding and trusting it.",
  },
  {
    value: "Content Marketing",
    description:
      "Our services help to create compelling and valuable content like Blog posts, videos, emails, social media posts that boosts brand’s online presence and SEO ranking. We help create content that not only intrigues your audience but also aligns with search engine algorithms, ensuring your brand success on social media",
  },
  {
    value: "Product Review/ Unboxing",
    description:
      "Our marketing solutions help to create positive product reviews to spread your brand’s message and reach a wider, more engaged audience. Our goal is not just to talk about your product but to tell its story through the eyes of those who've experienced it.",
  },
  {
    value: "Online Reputation Management",
    description:
      "With our Online Reputation Management, specifically through Google Reviews, we ensure that your brand’s identity is maintained. Our commitment is to actively oversee and manage positive reviews on platforms like Google to maintain your brand's image.",
  },
  {
    value: "Mobile App Installation",
    description:
      "Our innovative marketing solutions are designed to simplify your connection with mobile-first audiences, leading to more brand app downloads. Our talented team ensures that your app becomes a familiar presence within the wider mobile audience. By strategically engaging with mobile users, we aim to transform casual interest into active downloads.",
  },
  {
    value: "Generate More Traffic",
    description:
      "Our marketing solutions are designed to boost traffic on social media, websites, and applications. A steady increase in traffic contributes to building a brand's presence. As more people visit and engage with your content, your brand gains credibility and is perceived as a reliable source within your niche.",
  },
  {
    value: "Collect Feedback Survey",
    description:
      "Our solution helps to gain firsthand data from a target market in market research, or a target population in general research. In market research, we walk down into your target market, collecting real, firsthand insights.",
  },
  {
    value: "Increase Content Engagement",
    description:
      "Our solution helps to Increase engagement on brand’s content through likes, comments, shares, clicks to a landing page and social pages. The goal is to create content so compelling that your audience feels compelled to share it with their own networks, expanding your reach organically.",
  },
  {
    value: "UGC (User-Generated Content)",
    description:
      "Once influencers create content for your brand, we repurpose it strategically as part of your marketing goals. Your audience is diverse, and so are their experiences. Creating user-generated content allows you to showcase this diversity, making your brand relatable to a broader audience. It reflects real experiences through real, trustworthy people.",
  },
];

export const whyWorkData = [
  [
    {
      imgSrc: Proven,
      text: "Proven Expertise",
    },
    {
      imgSrc: Tailored,
      text: "Tailored Strategies",
    },
  ],
  [
    {
      imgSrc: Quality,
      text: "Quality Influencer Partnerships",
    },
    {
      imgSrc: Data,
      text: "Data-Driven Results",
    },
  ],
  [
    {
      imgSrc: Cost,
      text: "Cost-Effective Plans",
    },
    {
      imgSrc: Client,
      text: "Client-Centric Collaboration",
    },
  ],
  [
    {
      imgSrc: Campaign,
      text: "Campaign Implementation & Oversight",
    },
    {
      imgSrc: Enhanced,
      text: "Enhanced Brand visibility",
    },
  ],
  [
    {
      imgSrc: Niche,
      text: "Niche-specific Influencers",
    },
    {
      imgSrc: Innovative,
      text: "Innovative Campaign Solutions",
    },
  ],
];
