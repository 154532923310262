import React from "react";
import {  Brand1,Brand2,Brand3,Brand4,Brand5,Brand6} from "../../components/IconsImport";

const BrandsCard = () => {
  const brandsData = [
    {
      icon: Brand1,
      title: "Targeted Audience Reach",
      description:
        "We analyse your target audience on the basis of gender, age and market segment. Our approach extends to customising influencer marketing campaigns that reach your target audience. Engagement and brand’s visibility is our priority.",
    },
    {
      icon: Brand2,
      title: "Niche-Specific Influencers",
      description:
        "Our platform matches your brand’s target audience with niche-specific influencers. Clients get access to influencers' demographics, allowing them to gain valuable insights on ideal influencers for their campaigns.",
    },
    {
      icon: Brand3,
      title: "Strategic Campaign Planning",
      description:
        "With a keen focus on strategic planning, we expand the brand's reach through engaging videos and stories created in collaboration with influencers.",
    },
    {
      icon: Brand4,
      title: "Influencer Contracts",
      description:
        "Contracting influencers involves outlining clear expectations and legal compliance. Our influencer contracts specify expectations, deliverables and compensation. Streamline your contracting process in managing influencer collaborations.",
    },
    {
      icon: Brand5,
      title: "Measurable Results and ROI Tracking",
      description:
        "As we focus on key performance indicators specific to your goals, we ensure that you can easily track the conversion rates from leads to sales. You’ll receive comprehensive collaboration reports detailing the number of users reached and engaged.",
    },
    {
      icon: Brand6,
      title: "Increased Brand Reach",
      description:
        "We devise plans to help your brand reach new heights by expanding its presence at a wider level and generating hype among your intended primary audience.",
    },
  ];

  return (
    <>
      {brandsData.map((brand, index) => (
        <div
          className={`brands_container ${index % 2 === 0 ? "even" : "odd"}`}
          key={index}
        >
          <div className={`brands_icon ${index % 2 === 0 ? "even" : "odd"}`}>
            <img src={brand.icon} alt="brand icon" />
          </div>
          <h1 >{brand.title}</h1>
          <p>{brand.description}</p>
        </div>
      ))}
    </>
  );
};

export default BrandsCard;
