import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Caseimages,
  Caseresult,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Greecover,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const GreeeCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d79",
    title:
      "Cool is in the Air: A Thoughtful Influencer Marketing Campaign for GreeAircon",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:"The campaign, curated by Marqeting, swayed the power of influencers within the home decor and lifestyle domains, to highlight GREEAIRCON’s USPs",
    caseType: "BEAUTY & PERSONAL CARE",

    content: [
      {
        heading: "Campaign Objectives",
        list1:
          "Increase brand awareness and visibility for GREEAIRCON among homeowners and lifestyle-conscious consumers.",
        list2:
          "Debunk the myth that air conditioners worsen indoor air quality by showcasing the advanced filtration systems of GREEAIRCON's products.",
   
      },

      {
        heading: "Strategy and Execution",

        subcontent:
          "We not just collaborated with home decor and lifestyle influencers but also tapped into their rich audience base. Our execution team evaluated influencers based on their engagement rates, audience demographics, content quality, and overall brand fit.",
        content:
          "We had the shortlisted influencers curate reels centered around showcasing GREEAIRCON’s core mission: to offer a wide range of high-quality air conditioning units and accessories at competitive prices, while providing exceptional customer service.",
   

     
      },
      {
        heading: "Campaign Theme: Cool Home, Cool Life",

        subcontent:
          "GREEAIRCON in collaboration with Marqeting came up with a campaign series Cool Home, Cool Life. In this content series influencers showcased how GREEAIRCON's air conditioning units not only keep homes cool but also lend an eye-pleasing living environment.",
        content:
          "Through this campaign, GREEAIRCON wanted to make people understand that AC units aren’t an eyesore in modern living spaces. They wanted to counter the perception that air conditioning units are large, bulky, and take up significant space in rooms, disrupting the flow of the living area.",
        content2:
          "To homeowners thinking that AC units create a cluttered appearance, GREEAIRCON's Cool Home, Cool Life campaign communicated that while traditional AC units may have been perceived as unattractive and intrusive, their innovative designs and advanced technologies are a go-to solution for modern living spaces.",
    
   

     
      },
      {
        heading: "Campaign Results",

        subcontent:
          "And the code was cracked! The \"Cool Home, Cool Life\" campaign turned out to be a huge success. The campaign not only dispelled the myth that air conditioners worsen air quality, but also showcased how GREEAIRCON's sleek and stylish units can give a comforting experience to all the households out there.",
        content:
          "As one influencer aptly stated, \"Who says you can't have both comfort and style? My GREEAIRCON keeps my home cool and goes with my decor perfectly - it's a win-win!\" This relatable quote, voiced by many creators, likely went well with the viewers, leading to a hitch-up in brand favorability and sales",

          image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          imageUrl:
            "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",

      },

    
    ],
  
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Greecover}
                  alt={data.title}
                />
                {data.description && <p>{data.description}</p>}
                {/* <p>{data.description}</p> */}
                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.headingContent && (
                          <h1 style={{ marginTop: "10px" }}>
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}

                        {section.subHeadingnewe && (
                          <p className="subHeadingnewe">
                            {section.subHeadingnewe}
                          </p>
                        )}

                        {section.subcontent && <p>{section.subcontent}</p>}

                        {/* {section.image && (
                          <img
                            className="case_inner_image"
                            src={Momocase}
                            alt="Caseimages"
                          />
                        )} */}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && <p>{section.list1}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                          </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GreeeCaseStudiesInner;
