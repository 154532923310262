import React from "react";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import { SmallButton } from "../common/SmallButton";
import CloseIcon from "@mui/icons-material/Close";
import { Whatsappnew } from "./IconsImport";

const RegisterSuccessPopup = ({ open, navigateToHome, isSuccess }) => {
  return (
    <Modal open={open}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: "80%",
          maxWidth: 400,
          background: "#FFFFFF",
          padding: "50px",
          borderRadius: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <CloseIcon
            onClick={navigateToHome}
            sx={{
              color: "red",
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
          />
        </div>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
            <h1 className="registerpopup_head">
              {isSuccess
                ? "Registration Successful"
                : "Registration Unsuccessful"}
            </h1>
            <p className="registerpopup_subhead">
              {isSuccess
                ? "Thank You! Your details are submitted."
                : "This profile is already registered with us! For any queries, please write at support@marqeting.in"}
            </p>
            {/* {isSuccess && ( */}
            {/* <> */}
            <p className="registerpopup_join">
              Join our Community for daily updates
            </p>
            <div className="new_join_ehatsapp">
              <a
                href="https://chat.whatsapp.com/CB48qb8tadhC8rCH1KuHYq"
                target="_blank"
              >
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={Whatsappnew}
                  alt="whatsapp icon"
                />
              </a>
            </div>
            {/* </>    )} */}
            <SmallButton
              onClick={navigateToHome}
              label="Go to Home"
              width="100%"
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default RegisterSuccessPopup;
