import React from 'react'

const Dashboard = () => {
  return (
<>
    <div className='dashboard_new_container'>
    <div className="hcontainer">
   <div className='dashboard_new_content'>
   <div className='dashboard_new_content_inner'>
<p>Hello Lokendra.</p>
<h1>Good Morning</h1></div>
   </div>
   <div className='dashboard_button'><button>Add Employee</button></div>

    </div>
      
    </div>
    <div className="container_insidefluid">
    <div className="hcontainer">
    </div>
    </div>
    </>

  )
}

export default Dashboard;

