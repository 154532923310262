import React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Grid } from "@mui/material";
import {
  CustomTypography,
  CustomAccordion,
  CustomAccordionSummary,
  CustomAddIcon,
  CustomRemoveIcon,
} from "../../../components/styledCcomponents/Styledcomponent";

const AccordionItem = ({ heading, children, isOpen, onClick }) => {
  return (
    <CustomAccordion expanded={isOpen} onChange={onClick}>
      <CustomAccordionSummary
        expandIcon={isOpen ? <CustomRemoveIcon /> : <CustomAddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <CustomTypography
          sx={{ fontFamily: "Outfit, sans-serif" }}
          className="Accordion_heading"
        >
          {heading}
        </CustomTypography>
      </CustomAccordionSummary>
      <AccordionDetails
        sx={{
          padding: {
            xs: "10px",
          }
        }}
      >
        <Grid
          sx={{
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
            flexDirection:"column"
          }}
        >
          {children}
        </Grid>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default AccordionItem;
