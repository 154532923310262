import React, { useEffect } from "react";
import {
  AboutusStatic,
  AdvertiserAbout,
  MarqeterAbout,
  ProfileCallIcon,
} from "../../components/IconsImport";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Marqeting – About Us</title>

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="robots" content="index,follow" />

        <meta
          name="og:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="og:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta name="og:url" content="https://www.marqeting.in/aboutus" />

        <meta name="og:site_name" content="Marqeting" />

        <meta
          name="og:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="og:keywords"
          content="Influencer marketing Agency, Influencer Marketing agency in India, Find Instagram Influencer, Influencer Marketing platform, Influencer marketing, Social media influencers, Influencer collaboration, Instagram influencers, YouTube influencer marketing, TikTok influencer campaigns, Linkedin Influencer Marketing, Instagram Influencer Marketing, Instagram Influencers, Influencer partnerships, Micro-influencers, Brand ambassador programs, Influencer outreach, Influencer engagement, Sponsored content influencers, Influencer advertising, Influencer ROI, Influencer management tools, Influencer marketing agency, Influencer marketing strategy, Influencer marketing trends, Influencer marketing case studies, Nano Influencers, Macro Influencers"
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Marqeting - Connecting Brands with Trusted Influencers"
        />

        <meta
          name="twitter:description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="twitter:image"
          content="https://www.marqeting.in/static/media/whytoworkj.1196efb3f59cc1b5c8df.jpg"
        />

        <meta
          name="description"
          content="The terms of use at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="The privacy policy at Marqeting commits and implies that data gathered on the website www.marqeting.in is with the informed consent of the inquirer. The privacy policy strictly does not apply to any other information or website. For all the other websites, the inquirer is responsible and shall share."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industryâ€™s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting helps marketers and brands connect with industry’s best trusted social media influencers. As you do away with the challenges of outreaching, Marqeting simplifies each step of your campaign execution from connecting with influencers to maximising your ROI. With an emphasis on automation and backed by tailored strategies, we pave the way for influencer marketing to be the most efficient and sought-after path for brands.Grow your business through People Power with Higher Return on Investment."
        />

        <meta
          name="description"
          content="Marqeting brings you the latest updates on Tech, Digital Marketing, Startups, Business, Innovations, Sports & many more."
        />
      </Helmet>

      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="faq_container_innar">
            <div className="faq_container_right">
              <h1 className="terms_typograpy_text_heading">About Us</h1>
              <div className="aboutus_container_right_contant">
                <p className="landing_typograpy_text">
                  <h2 className="landing_typograpy_about">
                    Connecting Brands with Trusted Voice
                  </h2>
                  Marqeting helps marketers and brands connect with industry’s
                  best trusted social media influencers. As you do away with the
                  challenges of outreaching, Marqeting simplifies each step of
                  your campaign execution from connecting with influencers to
                  maximising your ROI.
                  <p
                    style={{ paddingTop: "20px" }}
                    className="landing_typograpy_text"
                  >
                    With an emphasis on automation and backed by tailored
                    strategies, we pave the way for influencer marketing to be
                    the most efficient and sought-after path for brands.
                  </p>
                </p>
                <img
                  src={AdvertiserAbout}
                  alt="image"
                  className="Aboutus_images"
                />
              </div>

              <div
                className="aboutus_container_right_contant"
                style={{ paddingTop: "40px" }}
              >
                <img
                  src={AboutusStatic}
                  alt="image"
                  className="Aboutus_images"
                />

                <p className="landing_typograpy_text">
                  <h2 className="landing_typograpy_about">
                    Grow Your Business Through PEOPLE POWER With Higher Return
                    on Investment (ROI)
                  </h2>
                  Marqeting allow brands and businesses to better reach their
                  target audience.
                  <p className="landing_typograpy_text">
                    We share a common goal; to accentuate the social standing of
                    your brand. We seek to deliver remarkable ROI for brands by
                    utilising the blend of influencers’ creativity and
                    technological expertise. Our approach involves creating and
                    executing innovative influencer marketing strategies
                    designed to amplify your brand’s narrative on social media,
                    getting it connected with millions of people.
                  </p>
                  <p
                    className="landing_typograpy_text"
                    style={{ paddingTop: "10px" }}
                  >
                    The massive reach, authenticity and credibility offered by
                    our talented pool of influencers ensures that your brand
                    archives the results it expects in least time.
                  </p>
                  <p
                    className="landing_typograpy_text"
                    style={{ paddingTop: "20px" }}
                  >
                    Confused? We can help you plan the perfect campaign! Reach
                    out to us by filling this form{" "}
                    <span className="signup_micro">
                      <Link to="/brands-enquiry">
                        (https://www.marqeting.in/brands-enquiry)
                      </Link>
                      {/* <a
                        href="https://www.marqeting.in/brands-enquiry"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        (https://www.marqeting.in/brands-enquiry)
                      </a> */}
                    </span>
                  </p>
                </p>
              </div>

              <div
                className="aboutus_container_right_contant"
                style={{ paddingTop: "40px" }}
              >
                <p className="landing_typograpy_text">
                  <h2 className="landing_typograpy_about">
                    Enabling Brands and Influencers to achieve their ‘WOW’
                    moment
                  </h2>
                  Before starting off each campaign, we establish clear,
                  goal-oriented, and data-driven Key Performance Indicators
                  (KPIs), ensuring a toast-worthy success for both brands and
                  influencers.
                  <p className="landing_typograpy_text">
                    Our commitment to transparency means we consistently track
                    progress, providing you with precise and tangible insights.
                    From the start to a successful conclusion, Marqeting takes
                    charge of your campaign. We are action-oriented, always here
                    for you, ready to offer support and guidance you can rely
                    on.
                  </p>
                  {/* <p className="landing_typograpy_text">
                    It's free, no subscription fee.
                  </p> */}
                </p>
                <img
                  src={MarqeterAbout}
                  alt="image"
                  className="Aboutus_images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
