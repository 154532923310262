import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  AuravedicCover,
  Auravedicphase1result,
  CAseimages,
  Caseimages,
  Caseishabansal,
  Casemusskyy,
  Caseresult,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const AuravedicCaseStudiesInner = () => {
  const data = {
    _id: "65aa4badd2fe5c4992081d72",
    title:
      "How Auravedic Deployed a Laser-Focused Instagram Marketing Strategy with Marqeting",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },

    caseType: "BEAUTY & PERSONAL CARE",
    content: [
      {
        heading: "Campaign Brief",
        subcontent:
          "Founded in 2012, Auravedic is well-known and trusted by thousands for its premium quality skincare and hair range products. The brand draws inspiration from nature and that’s why they source ingredients from the great valleys of Kashmir to Uttarakhand and exotic South India.",
        content:
          "Auravedic joined forces with Marqeting to spearhead a campaign focused on building brand visibility and awareness.",
        content2:
          "The idea was to leverage creators’ influencing power and build a community of loyal, repeating customers.",
      },
      {
        heading: "Campaign Objectives",

        list1:
          "To increase awareness about Auravedic’s product range along with their key benefits.",
        list2:
          "Increase top-of-mind awareness for Auravedic and their commitment to using natural, ayurvedic ingredients.",
      },
      {
        heading: "Campaign Approach and Execution",

        subHeadingnewe:
          "The brand made use of Instagram content creators to endorse their unique product line. The team at Marqeting screened the top performing profiles of Instagrammers from the beauty and skin care domain.",
        content:
          "A campaign brief highlighting the promotion of Auravedic’s products was shared among all the content creators. For some of the influencers, our professional content strategist took a creative route, penning down scripts and captions that provided creators with a solid foundation of creating relatable reels.",
      },
      {
        heading: "Personalized Product Storytelling by Influencers",
        subcontent:
          "The shortlisted creators curated exceptional reels and video stories featuring the application and USPs of Auravedic’s products in their respective styles. Although the overarching content theme remained consistent across all the videos, these pro level creators depicted their unique personalities and creative styles in the content.",
        content:
          "The content circling around Auravedic’s Kumkumadi range was a groundbreaking hit, leading to an appreciable increase in crucial key performance indicators. Drumrolls please!",
      },
      {
        heading: "Results",
        content:
          "Auravedic's campaign success can be attributed significantly to the dedication of our team, who laser-focused on specific objectives and went above and beyond to accomplish them.",
        image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl:
          "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
      },
    ],
    imageHeading: "Campaign Content",
    imageUrl: [
      {
        url: "https://www.instagram.com/p/C4ZyLs5piDF/",
        image: "1705659309244-399371952-w1.jpg",
        imageUrl: Casemusskyy,
      },
      {
        url: "https://www.instagram.com/p/C4p97O_vLMP/",
        image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
        imageUrl: Caseishabansal,
      },
    ],
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={AuravedicCover}
                  alt={data.title}
                />

                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                        {section.heading && <h1>{section.heading}</h1>}

                        {section.subHeadingnewe && (
                          <p className="subHeadingnewe">
                            {section.subHeadingnewe}
                          </p>
                        )}

                        {section.subcontent && <p>{section.subcontent}</p>}
                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Auravedicphase1result}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.list1 && section.list2 && (
                          <ul>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list1 && <p>{section.list1}</p>}
                            </li>
                            <li className="list_disc_ul">
                              {" "}
                              {section.list2 && <p>{section.list2}</p>}
                            </li>
                          </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuravedicCaseStudiesInner;
