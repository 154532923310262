import React from "react";
import CaseStudiesCards from "../common/Campaigncards/caseStudiesCards";
import {
  CAseimages,
  Caseimages,
  Caseresult,
  Covervideoimage,
  Disthi,
  Expertview,
  Foodntales,
  Gourav,
  Gxnphase1,
  Kavita,
  Mahi,
  Momocase,
  Momocover,
  Mybutter,
  Playbutton,
  Poonam,
  Zxn,
} from "./IconsImport";
import { Grid } from "@mui/material";
import { useCaseStudyId } from "./customHooks/customHook";
import { useParams } from "react-router-dom";
// import ReactPlayer from "react-player";
// import MomoVideo from "../assets/images/momoVideo.mp4";

const GxnCaseStudiesInner = () => {
  const data =  {
    _id: "65aa4badd2fe5c4992081d74",
    title: "Utilizing YouTube Creators to Solidify GXN’s Position as the Leading Fitness Nutrition Brand",
    coverImage: {
      image: "1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
      imageUrl:
        "http://localhost:8000/api/v1/uploads/1705659309229-268845077-casefirst.dc9fa0a2e1bc30b0c353.png",
    },
    description:"Greenex Nutrition sought YouTube influencer expertise, tasking the team with maximizing their brand recognition and engagement all over social media",
    caseType: "BEAUTY & PERSONAL CARE",
    
    content: [
      {
        heading: "Client Brief and Objectives",
        subcontent:
          "GXN, or Greenex Nutrition, is a leading name in sports nutrition and fitness supplements in India. Founded with a commitment to quality and innovation, GXN has grown steadily by dealing with the dietary needs of fitness enthusiasts and athletes.",
        content:
          "Their product range includes high-quality whey protein in multiple flavors, muscle mass gainers, and specialized pre-workout formulations made to enhance energy and performance.",
        content2:
          "GXN primarily targets fitness enthusiasts, bodybuilders, athletes, and individuals focused on improving their nutrition and fitness.",
        content3:
          "Aware of the high market competition, GXN decided to build hype among its intended target audience and increase its brand engagement. This was decided to increase sales of their product range.",
     
        },
        {
          headingContent: "Strategy and Execution",
        },
      {
        heading: "Greenex Nutrition partnered with YouTube Influencers",
    
        subcontent:
        "Instead of running a traditional blitz campaign with multiple influencers, GXN partnered with fitness influencers on YouTube, all with the help of Marqeting. The YouTube Shorts from the campaign featured the influencers giving an overview on GXN’s Whey Protein supplement.",
      content:
     "In addition to that, the influencers documented their experience while working out in their respective gyms. Making their voices matter with live projecting you know! They talked about GXN's Whey Protein, and integrated discussions about the same. The videos continue to deliver impressions beyond the date they all went live on the platform.",
  
      },
   
      {
        heading: "The Tangible Results",
    

        subcontent:
        "The YouTube influencer campaign was a smashing success as we had already expected. Proud to say, that hyped up our client’s product range and got people talking about it.",
      content:
     "The campaign’s success wasn’t only calculated in numbers but in the genuine intrigue it generated for the brand’s Whey Protein supplement. GXN re-positioned itself as a brand that not only knows the pulse of its customers but also knows how to deliver the best solutions to them.",
          image: "1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          imageUrl:
            "http://localhost:8000/api/v1/uploads/1705659309213-614354899-caeresult.f652c09fd4b75e9c8f97.webp",
          
      },

    ],
    // imageHeading: "Campaign Content",
    // imageUrl: [
    //   {
    //     url: "https://www.instagram.com/reel/C1RLQkWvE46/",
    //     image: "1705659309244-399371952-w1.jpg",
    //     imageUrl: Disthi,
    //   },
    //   {
    //     url: "https://www.instagram.com/p/C1PAiOrLnav/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Expertview,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1RD5K8vpix/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Kavita,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1CgMi8PTOK/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mahi,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1CO2R6JRt2/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Poonam,
    //   },
    //   {
    //     url: "https://www.instagram.com/p/C03WbYzJULQ/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Gourav,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C08vlIKL8Yr/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Mybutter,
    //   },
    //   {
    //     url: "https://www.instagram.com/reel/C1HufEqxsmk/",
    //     image: "1705659309302-221471441-caeresult.f652c09fd4b75e9c8f97.webp",
    //     imageUrl: Foodntales,
    //   },
    // ]
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className="aboutus_container">
        <div className="hcontainer">
          <div className="we_have_solution_container case_inner_container">
            <div>
              <h1 className="have_solution_heading caseInner_head">
                {data.title}
              </h1>
              <div className="case_studies_inner_container">
                <img
                  className="case_inner_image"
                  src={Zxn}
                  alt={data.title}
                />
         <p>{data.description}</p>
                <div className="case_studies_inner_container">
                  {data.content &&
                    data.content.map((section, i) => (
                      <div className="case_inner_text" key={i}>
                      {section.headingContent && (
                          <h1
                            style={{ marginTop: "10px" }}
                          >
                            {section.headingContent}
                          </h1>
                        )}
                        {section.heading && <h1>{section.heading}</h1>}

                        { section.subHeadingnewe && (
                      
                            <p className="subHeadingnewe">
                              {section.subHeadingnewe}
                            </p>
                        )}
                  
                        {section.subcontent && <p>{section.subcontent}</p>}

                        {section.image && (
                          <img
                            className="case_inner_image"
                            src={Gxnphase1}
                            alt="Caseimages"
                          />
                        )}
                        <p>{section.content}</p>
                        {section.content2 && <p>{section.content2}</p>}
                        {section.content3 && <p>{section.content3}</p>}
                        {section.list1 && section.list2 && (
                        <ul >
                          <li className="list_disc_ul"> {section.list1 && <p>{section.list1}</p>}</li>
                          <li className="list_disc_ul">     {section.list2 && <p>{section.list2}</p>}</li>
                        </ul>
                        )}
                      </div>
                    ))}
                </div>

                {/* <div className="video-container">
                  <ReactPlayer
                  className="video_player"
                    url={MomoVideo}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div> */}
                <div className="case_studies_inner_container">
                  {data.imageHeading && <h1>{data.imageHeading}</h1>}
                  <Grid container spacing={2}>
                    {data.imageUrl &&
                      data.imageUrl.map((video, j) => (
                        <Grid item xs={12} sm={3} key={j}>
                          <div className="iphone-frame">
                            <img
                              className="cover_images"
                              src={video.imageUrl}
                              alt="Covervideoimage"
                            />
                            <div className="iphone_mid_content">
                              <a target="_blank" href={video.url}>
                                <img src={Playbutton} alt="Playbutton" />
                                {/* <h2>Watch video</h2> */}
                              </a>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GxnCaseStudiesInner;
