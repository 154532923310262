import React, { useState } from "react";
import CareersCards from "../../common/Campaigncards/CareersCards";
import AccordionItem from "../../common/Accordion/AccordionItems/AccordionItem";
import CheckIcon from "@mui/icons-material/Check";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ApplyJobPopUp from "./ApplyJobPopUp";
const CareersPageInner = () => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [openReviewNote, setopenReviewNote] = useState(false);

  const AccordionData = [
   
    {
      heading: "Creative Graphic Designer",
      exp: "Freshers or candidates with proven experience as Graphic Designer",
      skill: [
        "Up to date with latest graphic software technologies (In Design, Illustrator, After Effects, Photoshop etc)",
        "Develop company assets like Brochures, Infographics, Advertisements, PowerPoint Presentation etc",
        "Work closely with content and marketing teams to create creative designs for social media and our company's profile",
        "Knowledge of current designs, templates, layouts, grids and other display formats",
        "Open to feedback and suggestions- you must be curious and oriented to improve.",
      ],
    },
    {
      heading: "Video Editor",
      exp: "A minimum of 2-3 years of experience in this domain is a must.",
      skill: [
        "Edit raw videos and bring the drafts to final completion",
        "Insert graphics, special effects, music, and sound to upgrade the final video",
        "Assist in planning social media content across different platforms",
        "Familiarity with social media platforms and their video requirements",
        "Knowledge of After Effects, Photoshop, and any other graphic design software",
        "Basic knowledge of animation and motion graphics",
        "Review edits to make sure they meet our high-quality standards and expectations",
      ],
    },
 
    {
      heading: "Quality Assurance Specialist",
      exp: "Strong interest and experience in QA within a creative marketing or advertising team.",

      skill: [
        "Ensure that all projects and deliverables are completed on time, with brand approvals",
        "Strong understanding of influencer marketing and social media platforms",
        "Ability to perceive a project from a strategic and visionary perspective",
        "Evaluate the effectiveness of influencer campaigns in achieving marketing objectives and KPIs",
        "Track and analyze campaign performance metrics to identify areas for improvement",
        "Provide feedback and recommendations to influencers and internal teams based on performance data",
        "Skilled in Google doc (Excel, Slides, Sheets and PowerPoint)",
        "Ability to communicate effectively and professionally with teammates",
      ],
    },
    {
      heading: "Influencer Marketing Specialist",
      exp: "Prior experience of 2-3 years in Influencer Marketing is expected from the candidate.",

      skill: [
        "Lead, screen and onboard influencers to run brand campaigns",
        "Comprehend and figure out strategies on how to maximize performance by matching the right creators with the brands",
        "One-on-one interaction with influencers to discuss product and brand integration in the content",
        "Experienced in politely negotiating pay rates and terms with content creators",
        "Monitor and analyze campaign performance by keeping a check on influencer performance",
        "Devise strategic suggestions for continuous improvement",
        "Collaborate closely with the content team to develop influencer scripts and other marketing collateral",
      ],
    },
    {
      heading: "Brand Acquisition Specialist",
      exp: "A good understanding of business marketing with a preferred track record of closing business deals",

      skill: [
        "Reach out to organizations who are looking to promote their products/projects/services",
        "Ability to make strong pitches and excellently communicate our marketing ideas",
        "Following up with clients and staying up to date about the brand's campaign execution process",
        "Managing the lead generation loop, from researching, sourcing and approaching clients",
        "Transfer sales leads to our Business Development Manager",
        "Coordinate with the team to ensure execution across all channels hits marketing targets and goals",
        "Bachelor's or Master's Degree in Marketing, Business Administration or a related field",
      ],
    },
  ];

  const handleApplyClick = (job) => {
    setSelectedJob(job);
    setopenReviewNote(true);
  };

  const handleReviewCloseModal = () => {
    setopenReviewNote(false);
  };

  return (
    <>
      {" "}
      <ApplyJobPopUp
        open={openReviewNote}
        onClose={handleReviewCloseModal}
        row={selectedJob}
        // onSuccess={refreshData}
      />
      <div>
        <div className="careers_top_containt">
          <h1>Perks of working with Marqeting</h1>
          <p>
            We Value Soldiers, Especially Our Own Our clients recognize us
            because our employees do it first!
          </p>
        </div>

        <div className="we_have_offer_inner">
          <CareersCards />
        </div>
        <div className="terms_container">
          <div className="job_section_containt">
            {/* <p className="job_heading">JOBS</p> */}
            <h1 className="job_section_heading">Current Openings</h1>
            <p className="job_heading_sub">Latest Jobs</p>
          </div>
          {AccordionData.map((item, index) => (
            <AccordionItem
              key={index}
              heading={item.heading}
              isOpen={openAccordion === index}
              onClick={() =>
                setOpenAccordion(openAccordion === index ? null : index)
              }
            >
              <div className="exp_content">
                <h1>Required experience</h1>
                <p className="job_heading_sub_ACCORIDIAN">{item.exp}</p>
              </div>
              <div className="skill_content">
                <h1 className="job_section_accoridain">
                  Skills and Responsibilities
                </h1>
                <ul className="Information_accordian">
                  {item.skill.map((skill, skillIndex) => (
                    <div className="skill_container" key={skillIndex}>
                      <CheckIcon sx={{ fontSize: 20, fontWeight: 600 }} />
                      <li className="skill_list" key={skillIndex}>
                        {skill}
                      </li>
                    </div>
                  ))}
                </ul>{" "}
              </div>
<div>
              <button
                className="apply_button"
                onClick={() => handleApplyClick(item.heading)}
              >
                Apply Now
                {/* <NavigateNextIcon sx={{ width: "40px", height: "28px" }} /> */}
              </button>
              </div>
            </AccordionItem>
          ))}
        </div>
      </div>
    </>
  );
};
export default CareersPageInner;
