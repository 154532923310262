export const BASE_URL = process.env.REACT_APP_BASE_URL;

const MarqetingEndpoint = {
  COUNTRY_NAME: "api/v1/country/all",
  STATE_NAME: "api/v1/state/statebycountry",
  CITY_NAME: "api/v1/city/citybystate",
  BRAND_CATEGORY: "api/v1/brandcategory/all",
  INFLUNCER_CATEGORY: "api/v1/influencercategory/all",
  SEND_OTP: "api/v1/otp/sendotp",
  VERIFY_OTP: "api/v1/otp/verifyotp",
  RESEND_OTP: "api/v1/otp/resendotp",
  BRAND_CREATE: "api/v1/branddetails/create",
  BRAND_LEADS: "api/v1/brandlead/create",
  INFLUENCER_CREATE: "api/v1/influencerdetails/create",
  CASE_STUDY: "api/v1/casestudy/allcard",
  CASE_STUDY_ID: "api/v1/casestudy/casestudybyid",
  CAMPAIGN_GOALS: "api/v1/campaignrequirement/all",
  CONTACTUS: "api/v1/contactform/create",
  CAREER_CREATE: "api/v1/career/create",
  
 

};

export default MarqetingEndpoint;
